import { db } from './db';

import { AxiosInstance } from 'src/configs/axios.instance';
import { apiUrl } from 'src/configs';

const replaceEmptyValuesWithNull = (obj) => {
  if (obj instanceof FormData) return obj;

  const newObj = { ...obj };

  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === '' || newObj[key] === '+') {
      newObj[key] = null;
    }
    if (newObj[key] instanceof Object && !Array.isArray(newObj[key])) {
      if ('EN' in newObj[key] && !newObj[key]['EN']) {
        delete newObj[key];
      }

      newObj[key] = replaceEmptyValuesWithNull(newObj[key]);
    }
  });

  return newObj;
};

export const query = ({ uri, auth = true, data, ...other }) =>
  new Promise((resolve, reject) => {
    let PAYLOAD = {
      url: apiUrl + uri,
      headers: {},
      data: replaceEmptyValuesWithNull(data),
      ...other
    };

    console.log({ PAYLOAD });
    if (!auth) delete PAYLOAD['headers']['Authorization'];
    else PAYLOAD['headers']['Authorization'] = 'Bearer ' + db.get('tokens')?.['access_token'];

    AxiosInstance(PAYLOAD)
      .then((response) =>
        resolve({
          ...response?.data
        })
      )
      .catch((error) => reject(error?.response));
  });
