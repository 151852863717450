import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import GoogleLogin from 'react-google-login';
import toast, { Toaster } from 'react-hot-toast';

// material
import { Stack, Button, Divider, Typography } from '@mui/material';
import { googleLoginRequest } from 'src/services/auth/googleLoginRequest.service';
import { useNavigate } from 'react-router-dom';
import { db } from 'src/utils/db';

// ----------------------------------------------------------------------
const googleClientId = '395365840836-rv6eqh098te9h8c4pg9o5fjg8i7oev5n.apps.googleusercontent.com';

export default function AuthSocial() {
  const navigate = useNavigate();

  const onGoogleLoginSuccess = ({ tokenObj }) => {
    debugger;
    const PAYLOAD = {
      id_token: tokenObj.id_token
    };
    googleLoginRequest(PAYLOAD)
      .then(({ data }) => {
        db.set('tokens', data);
        navigate('/', { replace: true });
      })
      .catch(() => {
        toast.error('email or password is incorrect', { duration: 3000 });
      });
  };

  const onGoogleLoginFailure = () => {
    toast.error('Google login failed', { duration: 3000 });
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Stack direction="row" spacing={2}>
        <GoogleLogin
          clientId={googleClientId}
          buttonText="Login"
          onSuccess={onGoogleLoginSuccess}
          onFailure={onGoogleLoginFailure}
          cookiePolicy={'single_host_origin'}
          render={(renderProps) => (
            <Button
              disabled={renderProps.disabled}
              onClick={renderProps.onClick}
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
            >
              <Icon icon={googleFill} color="#DF3E30" height={24} />
            </Button>
          )}
        />
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}
