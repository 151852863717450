import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import DateAdapter from '@mui/lab/AdapterDateFns';

// material
import { Stack, TextField, Button } from '@mui/material';
import { LoadingButton, LocalizationProvider } from '@mui/lab';
import { changePasswordRequest } from 'src/services/me/changePasswordRequest.service';
import { db } from 'src/utils/db';

const changePasswordSchame = Yup.object().shape({
  old_password: Yup.string()
    .required('Old password is required')
    .min(8, 'Ensure this value has at least 8 characters'),
  new_password: Yup.string()
    .required('New password is required')
    .min(8, 'Ensure this value has at least 8 characters'),
  confirm_new_password: Yup.string()
    .required('New password is required')
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
});

export default function ChangePasswordForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleChangePassword = (values) => {
    const PAYLOAD = {
      old_password: values.old_password,
      new_password: values.new_password
    };

    changePasswordRequest(PAYLOAD)
      .then((res) => {
        toast.success('password changed successfully', { duration: 5000 });
        db.delete('tokens');
        navigate('/login');
      })
      .catch(({ data }) => {
        toast.error(data?.message || 'something went wrong', { duration: 4000 });
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    validationSchema: changePasswordSchame,
    initialValues: {
      new_password: '',
      old_password: '',
      confirm_new_password: ''
    },
    onSubmit: handleChangePassword
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              {...getFieldProps('old_password')}
              focused={values?.old_password}
              fullWidth
              autoComplete="old_password"
              type="text"
              label="Old password"
              error={Boolean(touched.old_password && errors.old_password)}
              helperText={touched.old_password && errors.old_password}
            />
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              {...getFieldProps('new_password')}
              focused={values?.new_password}
              fullWidth
              autoComplete="new_password"
              type="text"
              label="New password"
              error={Boolean(touched.new_password && errors.new_password)}
              helperText={touched.new_password && errors.new_password}
            />
            <TextField
              {...getFieldProps('confirm_new_password')}
              focused={values?.confirm_new_password}
              fullWidth
              autoComplete="confirm_new_password"
              type="text"
              label="Repeat new password"
              error={Boolean(touched.confirm_new_password && errors.confirm_new_password)}
              helperText={touched.confirm_new_password && errors.confirm_new_password}
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
            className='save-btn'

          >
            Save
          </LoadingButton>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
}
