const isDev = process.env.NODE_ENV === 'development';

const DEVELOPMENT_BASE_URL = process.env.REACT_APP_DEV_API || 'https://chupecomapi.greatplus.com/';
const PRODUCTION_BASE_URL = process.env.REACT_APP_PRO_API || 'https://chupecomapi.greatplus.com/';
const TESTING_BASE_URL = process.env.REACT_APP_TESTING_API || 'https://chupecomapi.greatplus.com/';
const CUS_BASE_URL = process.env.REACT_APP_CUS_API || 'http://chupa-ecommerce-api.chupachups.ae/';
const UAT_BASE_URL = process.env.REACT_APP_UAT_API || 'https://uat-chupecomadmin.staqo.com/';

// const DEVELOPMENT_BASE_URL = process.env.REACT_APP_DEV_API || 'http://localhost:8000/';
// const PRODUCTION_BASE_URL = process.env.REACT_APP_PRO_API || 'http://localhost:8000/';
// const TESTING_BASE_URL = process.env.REACT_APP_TESTING_API || 'http://localhost:8000/';
// const CUS_BASE_URL = process.env.REACT_APP_CUS_API || 'http://chupa-ecommerce-api.chupachups.ae/';
// const UAT_BASE_URL = process.env.REACT_APP_UAT_API || 'https://uat-chupecomadmin.staqo.com/';

const BASE_URL_MAP = {
  'chupa-commerce-admin.calistu.site': DEVELOPMENT_BASE_URL,
  'localhost:4196': TESTING_BASE_URL,
  'localhost:3000': TESTING_BASE_URL,
  'localhost:3000': TESTING_BASE_URL,
  'localhost:4848': TESTING_BASE_URL,
  'd1dv4q36ugdbd7.cloudfront.net': PRODUCTION_BASE_URL,
  'uat-chupecomadmin.staqo.com': UAT_BASE_URL,
  'e-commerce-admin.chupachups.ae': CUS_BASE_URL
};

const getApiUrl = () => {
  const host = typeof window !== 'undefined' ? window.location.host : '';
  return host in BASE_URL_MAP ? BASE_URL_MAP[host] : PRODUCTION_BASE_URL;
};

export const apiUrl = getApiUrl();

export const tableConfigs = {
  defaultPageSize: 50,
  pageSizeOptions: [50, 100, 200, 300, 400, 500]
};
