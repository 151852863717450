import { query } from 'src/utils/query';

/** Fetch testimonials from database */
export const getTestimonialsRequest = (params) => {
  return query({
    uri: 'api/v1/admin/testimonials',
    auth: true,
    method: 'GET',
    params
  });
};
