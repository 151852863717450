import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import DateAdapter from '@mui/lab/AdapterDateFns';

// material
import { Stack, TextField, Button, MenuItem, FormLabel } from '@mui/material';
import { LoadingButton, LocalizationProvider } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { editSingleBlogRequest } from 'src/services/blogs/editSingleBlogRequest.service';
import { addBlogRequest } from 'src/services/blogs/addBlogRequest.service';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';

const initialValues = {
  title: '',
  text: '',
  category: '',
  read_time: ''
};

const AddBlogSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  text: Yup.string().required('Text is required'),
  category: Yup.string().required('Category is required'),
  read_time: Yup.number()
    .required('Reading time is required')
    .min(0, 'Reading time must be greater than 0')
});

export default function AddBlogForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  const handleAddBlog = (values) => {
    const PAYLOAD = {
      ...values,
      image_url: image
    };

    debugger;

    if (!image) {
      toast.error('Image is required');
      return;
    }

    addBlogRequest(PAYLOAD)
      .then((res) => {
        toast.success('Blog edited successfully', { duration: 4000 });
        navigate('/blogs');
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    validationSchema: AddBlogSchema,
    initialValues: initialValues,
    onSubmit: handleAddBlog
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues, setFieldValue } = formik;

  const handleUploadBlogImage = (event) => {
    const file = event.target.files[0];

    setLoading(true);
    uploadFileRequest(file)
      .then(({ data }) => {
        setImage(data.file_url);
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
            inputProps={{ maxLength:50}}
              {...getFieldProps('title')}
              focused={values?.title}
              fullWidth
              autoComplete="title"
              type="text"
              label="Title *"
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
          </Stack>
          <Stack my="1rem" direction="column" position="relative" spacing={1}>
            <FormLabel>Description</FormLabel>
            <ReactQuill
              className="quill-editor"
              toolbar={['bold', 'italic', 'underline', 'link']}
              onChange={(_, __, ___, { getHTML }) => {
                const newValue = getHTML();
                setFieldValue('text', newValue);
              }}
            />
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              fullWidth
              label="Category *"
              error={Boolean(touched.category && errors.category)}
              helperText={touched.category && errors.category}
              {...getFieldProps('category')}
              select
              size="small"
            >
              <MenuItem value="Events">Events</MenuItem>
              <MenuItem value="Make craft">Make craft</MenuItem>
              <MenuItem value="Funny moments">Funny moments</MenuItem>
            </TextField>
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
            inputProps={{ maxLength:10}}
              {...getFieldProps('read_time')}
              focused={values?.read_time}
              fullWidth
              autoComplete="off"
              type="number"
              label="Reading time (Minutes) *"
              error={Boolean(touched.read_time && errors.read_time)}
              helperText={touched.read_time && errors.read_time}
            />
          </Stack>
          <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
            <label style={{ margin: '0', width: '100%' }} htmlFor="overview_urls">
              <input
                onChange={handleUploadBlogImage}
                accept="image/*"
                style={{ display: 'none' }}
                id="overview_urls"
                name="overview_urls"
                type="file"
              />
              <Button fullWidth variant="contained" component="span">
                Upload blog image
              </Button>
            </label>
            {image && (
              <img
                style={{ margin: '1rem auto 0', objectFit: 'cover' }}
                width={200}
                src={image}
                alt="blog image"
              />
            )}
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
            className='save-btn'
          >
            Save
          </LoadingButton>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
}
