import { createContext, useContext, useState } from 'react';
import { getMyPermissionsRequest } from 'src/services/permession/getMyPermissionsRequest.service';
import { getPermissionsRequest } from 'src/services/permession/getPermissionsRequest.service';

import { getProfileRequest } from '../services/me/getProfileRequest.service';

const UserContext = createContext({
  first_name: '',
  last_name: '',
  email: '',
  avatar: '',
  getUser: () => {},
  userPermissions: [],
  allPermissions: []
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    avatar: '',
    mobile_number: ''
  });

  const [userPermissions, setUserPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);

  const getUser = () => {
    getProfileRequest().then(({ data }) => {
      setUser({
        first_name: data?.first_name ?? 'John',
        last_name: data?.last_name ?? 'Doe',
        email: data?.email ?? 'test@email.com',
        avatar: data?.avatar ?? '',
        mobile_number: data?.mobile_number ?? ''
      });
    });
    getAllPermissions();
    getMyPermissionsRequest().then(({ data: { permissions } }) => {
      setUserPermissions(permissions?.map((item) => item.entity));
    });
  };

  const getAllPermissions = () => {
    getPermissionsRequest().then(({ data: { permissions } }) => {
      setAllPermissions(Object.keys(permissions));
    });
  };

  const value = {
    ...user,
    getUser,
    userPermissions,
    allPermissions
  };

  return <UserContext.Provider value={value} children={children} />;
};

export const useUser = () => useContext(UserContext);
