import { Navigate, useRoutes } from 'react-router-dom';
// / layouts
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// /
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import NotFound from './pages/Page404';
import Customers from './pages/Customers';
import Club from './pages/Club';
import Layout from './layouts/MainLayout';
import IncomingOrders from './pages/IncomingOrders';
import Orders from './pages/Orders';
import Categories from './pages/Categories';
import Design from './pages/Design';
import Coupons from './pages/Coupons';
import Testimonials from './pages/Testimonials';
import EditProduct from './pages/EditProduct';
import AddProduct from './pages/AddProduct';
import EditCustomer from './pages/EditCustomer';
import EditCategory from './pages/EditCategory';
import AddCategory from './pages/AddCategory';
import EditDesign from './pages/EditDesign';
import AddDesign from './pages/AddDesign';
import EditCoupon from './pages/EditCoupon';
import AddCoupon from './pages/AddCoupon';
import CustomerOrders from './pages/CustomerOrder';
import AddTestimonial from './pages/AddTestimonial';
import EditTestimonial from './pages/EditTestimonial';
import News from './pages/News';
import AddNews from './pages/AddNews';
import EditNews from './pages/EditNews';
import Blogs from './pages/Blogs';
import AddBlog from './pages/AddBlog';
import EditBlog from './pages/EditBlog';
import EditProfile from './pages/EditProfile';
import OrderPacks from './pages/OrderPacks';
import AddOrderPack from './pages/AddOrderPack';
import EditOrderPack from './pages/EditOrderPack';
import Settings from './pages/Setting';
import Tags from './pages/Tags';
import AddTag from './pages/AddTag';
import Promotions from './pages/Promotions';
import AddPromotion from './pages/AddPromotion';
import EditPromotion from './pages/EditPromotion';
import ContactUs from './pages/ContactUs';
import Socials from './pages/Socials';
import AddSocial from './pages/AddSocial';
import EditSocial from './pages/EditSocial';
import Discounts from './pages/Discounts';
import AddDiscount from './pages/AddDiscount';
import SEO from './pages/SEO';
import AddSEO from './pages/AddSeo';
import EditSEO from './pages/EditSeo';
import EditDiscount from './pages/EditDiscount';
import Questions from './pages/Questions';
import AddQuestions from './pages/AddQuestions';
import EditQuestions from './pages/EditQuestions';
import ChangePassword from './pages/changePassword';
import DesignMoreMenu from './components/design/Menu';

// / ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      basename:"/",
      element: <Layout />,
      children: [
        { element: <Navigate to="/products" replace /> },
        { path: '/products', element: <Products /> },
        { path: '/customers', element: <Customers /> },
        { path: '/customers/:customer_id/orders', element: <CustomerOrders /> },
        { path: '/club', element: <Club /> },
        { path: '/orders', element: <Orders /> },
        //  { path: '/incoming-orders', element: <IncomingOrders /> },
        { path: '/news', element: <News /> },
        { path: '/categories', element: <Categories /> },
        { path: '/design', element: <Design/> },

        { path: '/coupons', element: <Coupons /> },
        // / { path: '/testimonials', element: <Testimonials /> },
        { path: '/blogs', element: <Blogs /> },
        { path: '/order-packs', element: <OrderPacks /> },
        { path: '/tags', element: <Tags /> },
        { path: '/promotions', element: <Promotions /> },
        { path: '/socials', element: <Socials /> },
        { path: '/contact-us', element: <ContactUs /> },
        { path: '/discounts', element: <Discounts /> },
        { path: '/questions', element: <Questions /> },
        { path: '/seo', element: <SEO /> }

      ]
    },
    { path: '/*', element: <Navigate to="/404" replace /> },
    { path: '//login', element: <Login /> },
    { path: '/register', element: <Register /> },

    { path: '/products/edit/:product_id', element: <EditProduct /> },
    { path: '/products/add', element: <AddProduct /> },

    { path: '/customers/edit/:customer_id', element: <EditCustomer /> },

    { path: '/categories/edit/:category_id', element: <EditCategory /> },
    { path: '/categories/add', element: <AddCategory /> },

    { path: '/design/edit/:design_id', element: <EditDesign/> },
    { path: '/design/add', element: <AddDesign/> },

    { path: '/coupons/edit/:coupon_id', element: <EditCoupon /> },
    { path: '/coupons/add', element: <AddCoupon /> },

      // / { path: '/testimonials/add', element: <AddTestimonial /> },
      // / { path: '/testimonials/edit/:testimonial_id', element: <EditTestimonial /> },

    { path: '/news/edit/:news_id', element: <EditNews /> },
    { path: '/news/add', element: <AddNews /> },

    { path: '/blogs/edit/:blog_id', element: <EditBlog /> },
    { path: '/blogs/add', element: <AddBlog /> },

    { path: '/edit-profile', element: <EditProfile /> },
    { path: '/change-password', element: <ChangePassword /> },

    { path: '/order-packs/add', element: <AddOrderPack /> },
    { path: '/order-packs/edit/:order_pack_id', element: <EditOrderPack /> },

    { path: '/settings', element: <Settings /> },

    { path: '/tags/add', element: <AddTag /> },

    { path: '/promotions', element: <Promotions /> },
    { path: '/promotions/add', element: <AddPromotion /> },
    { path: '/promotions/edit/:promotion_id', element: <EditPromotion /> },

    { path: '/socials', element: <Socials /> },
    { path: '/socials/add', element: <AddSocial /> },
    { path: '/socials/edit/:social_id', element: <EditSocial /> },

    { path: '/discounts', element: <Discounts /> },
    { path: '/discounts/add', element: <AddDiscount /> },
    { path: '/discounts/edit/:discount_id', element: <EditDiscount /> },

    { path: '/seo', element: <SEO /> },
    { path: '/seo/add', element: <AddSEO /> },
    { path: '/seo/edit/:seo_id', element: <EditSEO /> },

    { path: '/questions', element: <Questions /> },
    { path: '/questions/add', element: <AddQuestions /> },
    { path: '/questions/edit/:questions_id', element: <EditQuestions /> }
  ]);
}
