import { query } from 'src/utils/query';

export const updateConfigsRequest = (payload = {}) => {
  return query({
    uri: 'api/v1/admin/configs',
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
