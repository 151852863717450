import { debounce, filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ProductsListHead,
  ProductsListToolbar,
  ProductsMoreMenu
} from '../components/_dashboard/products';
//
import USERLIST from '../_mocks_/user';
import { getProductsRequest } from 'src/services/products/getProductsRequest.service';
import { apiUrl, tableConfigs } from 'src/configs';
import { getCustomersRequest } from 'src/services/customer/getCustomersRequest.service';
import CustomerMenu from 'src/components/customers/Menu';
import CustomersFilterSidebar from 'src/components/customers/CustomersFilterSidebar';
import SearchBar from 'src/components/_dashboard/blog/BlogPostsSearch';
import { getCustomersCSVRequest } from 'src/services/customer/getCustomersCSVRequest.service';
import { downloadCSVFile } from 'src/utils/downloadCSV';
import { LoadingButton } from '@mui/lab';
import Loader from 'src/components/loader/Loader';
import { loaderStyle } from './Products';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'avatar', label: 'Avatar', alignRight: false },
  { id: 'fullname', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'Unbanned', label: 'Unbanned', alignRight: false },
  { id: '', label: '', alignRight: false }
];

// ----------------------------------------------------------------------

export default function Products() {
  const emptyRows = 5 > 0 ? Math.max(0, (1 + 5) * 5 - USERLIST.length) : 0;

  const [customers, setCustomers] = useState({
    data: [],
    total: 0
  });

  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [params, setParams] = useState({
    limit: tableConfigs.defaultPageSize,
    search: null,
    offset: 0
  });

  const getCustomers = () => {
    setLoading(true);
    const PARAMS = { ...params };

    if (params?.user_status?.length === 0 || !params.user_status) {
      delete PARAMS.user_status;
    }

    getCustomersRequest(PARAMS).then(({ data }) => {
      setLoading(false);
      setCustomers({
        data: data.result,
        total: data.total
      });
    });
  };

  useEffect(() => {
    getCustomers();
  }, [params]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleFilterChange = debounce((newParams = params) => {
    setParams({ ...newParams });
  }, 200);

  const downloadCSVHandler = () => {
    setLoading(true);

    // delete PARAMS.user_status;

    getCustomersCSVRequest(params)
      .then(({ data }) => {
        downloadCSVFile(data.url, 'customers');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Page title="Customers">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Customers
          </Typography>
        </Stack>
        <Card>
          <Stack
            direction="row"
            flexWrap="wrap-reverse"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 3, mx: 3.3 }}
          >
            <SearchBar filters={params} onFilterChange={handleFilterChange} isLoading={loading} />

            <Stack direction="row" gap={2}>
              <LoadingButton
                loading={loading}
                onClick={downloadCSVHandler}
                disableRipple
                color="inherit"
                endIcon={<Icon icon="foundation:page-export-csv" />}
              >
                Export &nbsp;
              </LoadingButton>
              <CustomersFilterSidebar
                filters={params}
                onFilterChange={handleFilterChange}
                isOpenFilter={openFilter}
                onResetFilter={() => {
                  handleFilterChange({
                    ...params,
                    user_status: [],
                    search: null,
                    is_blocked: null
                  });
                }}
                onOpenFilter={handleOpenFilter}
                onCloseFilter={handleCloseFilter}
              />
            </Stack>
          </Stack>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductsListHead
                  order={'name'}
                  orderBy={'name'}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  // numSelected={0}
                  onRequestSort={() => {}}
                  onSelectAllClick={() => {}}
                />
                <TableBody>
                  {loading && (
                    <div style={loaderStyle}>
                      <Loader />
                    </div>
                  )}
                  {customers.data.map((row) => {
                    const { id, first_name, last_name, email, avatar, user_status, is_blocked } =
                      row;
                    // const isItemSelected = selected?.indexOf(title) !== -1;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="center" component="th" scope="row" padding="1rem">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={first_name} src={avatar} />
                          </Stack>
                        </TableCell>
                        <TableCell align="center">
                          {first_name ?? '' + ' ' + last_name ?? ''}
                        </TableCell>
                        <TableCell align="center">{email ?? ''}</TableCell>
                        <TableCell align="center">
                          <Label
                            style={{ padding: '0' }}
                            variant="ghost"
                            color={is_blocked ? 'error' : 'success'}
                          >
                            {is_blocked ? (
                              <Icon icon="fe:disabled" width={24} height={24} />
                            ) : (
                              <Icon icon="akar-icons:chat-approve" width={24} height={24} />
                            )}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          <CustomerMenu onGetCustomers={getCustomers} customerId={id} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {customers.total === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={params?.search ?? ''} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            SelectProps={{
              disabled: loading
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            rowsPerPageOptions={tableConfigs.pageSizeOptions
              .filter((item) => item <= customers.total)
              .concat(customers.total)}
            component="div"
            count={customers.total}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(_, selected) => {
              setParams({
                ...params,
                offset: selected * params.limit
              });
            }}
            onRowsPerPageChange={({ target }) => {
              const selected = target.value;
              setParams({ ...params, limit: selected });
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}
