import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardMedia,
  Tooltip,
  Typography,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import { DownloadDone, DownloadOutlined } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import { memo } from 'react';

const DownloadButton = styled.a`
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  color: black;
  background: white;
  padding: 0.1rem;
  border-radius: 1rem;
`;

const PersonalizationItem = ({ color, imageURL, title, type, text }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          {/* {item?.personalization?.type === 'TEXT' ? 'Text ' : 'Photo ' ?? 'sfwsfc'} */}
          personalization:
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {type.includes('TEXT') && (
          <>
            <Typography>
              Color: {color ?? ''}
              <div
                style={{
                  backgroundColor: color ?? ' ',
                  width: '60px',
                  height: '20px',
                  margin: '5px 0'
                }}
              ></div>
            </Typography>
            <Divider />
            <Typography>Text: {text ?? ''}</Typography>
          </>
        )}
        <Divider />

        {type.includes('PHOTO') && (
          <div style={{ position: 'relative', paddingTop: 10 }}>
            <Typography>Photo</Typography>
            <CardMedia component="img" height="120" image={imageURL} alt={title} />
            <Tooltip title="Download personalized photo" placement="top">
              <DownloadButton
                download={title?.trim() ?? 'personalized-photo' + '.png'}
                href={imageURL}
              >
                <Icon fontSize="2rem" icon="akar-icons:cloud-download" />
              </DownloadButton>
            </Tooltip>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(PersonalizationItem);
