import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { deleteCouponRequest } from 'src/services/coupons/deleteCouponRequest.service';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { deleteOrderPackRequest } from 'src/services/OrderPacks/deleteOrderPackRequest.service';

// ----------------------------------------------------------------------

export default function OrderPackMoreMenu({ packId, onGetPacks }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const deleteOrderPackHandler = () => {
    setIsOpen(false);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this order pack!',
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        const PAYLOAD = {
          ids: [packId]
        };
        deleteOrderPackRequest(PAYLOAD)
          .then(() => {
            toast.success('Poof! Your order pack has been deleted!', { duration: 4000 });
            onGetPacks?.();
          })
          .catch(() => {
            toast.error('Something went wrong', { duration: 4000 });
          });
      }
    });
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          component={RouterLink}
          to={'/order-packs/edit/' + packId}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem onClick={deleteOrderPackHandler} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon="fluent:delete-28-regular" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
