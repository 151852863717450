import { query } from 'src/utils/query';

/** Delete category from database */
export const deleteCategoryRequest = (categoryId) => {
  return query({
    uri: `api/v1/admin/categories/${categoryId}/`,
    auth: true,
    method: 'DELETE',
  });
};
