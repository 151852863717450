import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { Autocomplete, LoadingButton } from '@mui/lab';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ProductsListHead,
  ProductsListToolbar,
  ProductsMoreMenu
} from '../components/_dashboard/products';
//
import USERLIST from '../_mocks_/user';
import { getProductsRequest } from 'src/services/products/getProductsRequest.service';
import { apiUrl, tableConfigs } from 'src/configs';
import { getClubUsersRequest } from 'src/services/club/getClubUsersRequest.service';
import { downloadCSVFile } from 'src/utils/downloadCSV';
import { getClubUsersCSVRequest } from 'src/services/club/getClubUsersCSVRequest.service';
import Loader from 'src/components/loader/Loader';
import { loaderStyle } from './Products';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'full-name', label: 'Full Name', alignRight: false },
  { id: 'mobile_number', label: 'Mobile Number', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'gander', label: 'Gender', alignRight: false }
];

// ----------------------------------------------------------------------

export default function Products() {
  const emptyRows = 5 > 0 ? Math.max(0, (1 + 5) * 5 - USERLIST.length) : 0;
  const [clubUsers, setClubUsers] = useState({
    data: [],
    total: 0
  });

  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    limit: tableConfigs.defaultPageSize,
    search: null,
    offset: 0,
    status: null
  });

  // const isUserNotFound = [].length === 0;

  const getClubUsers = () => {
    setLoading(true);

    getClubUsersRequest(params).then(({ data }) => {
      setLoading(false);
      setClubUsers({
        data: data.result,
        total: data.total
      });
    });
  };

  useEffect(() => {
    getClubUsers();
  }, [params]);

  const downloadCSVHandler = () => {
    setLoading(true);
    getClubUsersCSVRequest().then(({ data }) => {
      downloadCSVFile(data.url, 'club_users');
      setLoading(false);
    });
  };

  return (
    <Page title="Chupa Club">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Chupa Club
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Products
          </Button> */}
        </Stack>

        <Card>
          <Stack sx={{ my: 3, mx: 3.3 }} justifyContent="flex-end" direction="row" gap={2}>
            <LoadingButton
              loading={loading}
              onClick={downloadCSVHandler}
              disableRipple
              color="inherit"
              endIcon={<Icon icon="foundation:page-export-csv" />}
            >
              Export &nbsp;
            </LoadingButton>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductsListHead
                  order={'name'}
                  orderBy={'name'}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  onRequestSort={() => {}}
                  onSelectAllClick={() => {}}
                />
                <TableBody>
                  {loading && (
                    <div style={loaderStyle}>
                      <Loader />
                    </div>
                  )}
                  {clubUsers?.data.map((row) => {
                    const { mobile_number, email, id, name, gender } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="center">{name ?? ''}</TableCell>
                        <TableCell align="center">{mobile_number ?? ''}</TableCell>
                        <TableCell align="center">{email ?? ''}</TableCell>
                        <TableCell align="center">{gender ?? ''}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {clubUsers.total === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={params?.search ?? ''} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            SelectProps={{
              disabled: loading
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            rowsPerPageOptions={tableConfigs.pageSizeOptions
              .filter((item) => item <= clubUsers.total)
              .concat(clubUsers.total)}
            component="div"
            count={clubUsers.total}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(_, selected) => {
              setParams({
                ...params,
                offset: selected * params.limit
              });
            }}
            onRowsPerPageChange={({ target }) => {
              const selected = target.value;
              setParams({ ...params, limit: selected });
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}
