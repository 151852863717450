import { query } from 'src/utils/query';

/**
 ```js
{
  "background_color": "string",
  "base_image_url": "string",
  "description": {
    "additionalProp1": "string",
    "additionalProp2": "string",
  },
  "file_url": "string",
  "from_datetime": "2021-12-09T13:38:52.113Z",
  "image_urls": [
    "string"
  ],
  "overview_urls": [
    "string"
  ],
  "personalization": "TEXT",
  "price": {
    "additionalProp1": 0,
    "additionalProp2": 0,
  },
  "product_type": "type1",
  "subtitle": {
    "additionalProp1": "string",
    "additionalProp2": "string",
  },
  "title": {
    "additionalProp1": "string",
    "additionalProp2": "string",
    "additionalProp3": "string"
  },
  "to_datetime": "2021-12-09T13:38:52.113Z",
  "base_file_id": "string",
  "category_id": "string",
  "dimensions": {
    "length": 0,
    "width": 0,
    "height": 0
  },
  "file_ids": [],
  "general_attributes": {},
  "is_enable": true,
  "priority": 0,
  "product_code": "string",
  "product_status": "draft",
  "unit": {
    "additionalProp1": "string",
    "additionalProp2": "string",
  },
  "weight": 0,
  "status": "draft"
}
```
 */
export const updateProductRequest = (payload, productId) => {
  return query({
    uri: 'api/v1/admin/products/' + productId + '/',
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
