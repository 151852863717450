import { useNavigate } from 'react-router-dom';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { db } from './utils/db';
import { useEffect } from 'react';
import { UserProvider, useUser } from './providers/user';
import { ConfigProvider } from './providers/config';
import 'react-quill/dist/quill.snow.css';
// ----------------------------------------------------------------------

export default function App() {
  const isLogin = db.get('tokens');

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      return navigate('/login');
    }
  }, [isLogin]);

  return (
    <UserProvider>
      <ConfigProvider>
        <ThemeConfig>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          <Router basename="/" />
        </ThemeConfig>
      </ConfigProvider>
    </UserProvider>
  );
}
