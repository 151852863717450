import { query } from 'src/utils/query';

// export const updateSingleQuestionsRequest = (payload, questionId) => {
export const updateSingleQuestionsRequest = (payload) => {
  return query({
    // uri: 'api/v1/admin/questions/' + questionId + '/',
    uri: 'api/v1/admin/questions',
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
