import * as Yup from 'yup';
import { useRef, useState } from 'react';
import Axios from 'axios';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import { ColorPicker } from 'material-ui-color';

// material
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  ImageListItem,
  ImageList,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  createFilterOptions,
  Chip,
  List,
  FormLabel
} from '@mui/material';
import { Autocomplete, LoadingButton } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { apiUrl } from 'src/configs';
import { updateProductRequest } from 'src/services/products/updateProductRequest.service';
import { createProductRequest } from 'src/services/products/createProductRequest.service';
import { useEffect, memo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import DraggableImageItem from './DraggableImageItem';
import { reorder } from 'src/utils/strings';
import JoditEditor from 'jodit-react';
import Swal from 'sweetalert2';

const filter = createFilterOptions();

const personalizationOptions = [
  { label: 'Text', value: 'TEXT' },
  { label: 'Photo', value: 'PHOTO' },
  { label: 'Design', value: 'DESIGN' }

];

// ----------------------------------------------------------------------
const formikValues = {
  title: '',
  subtitle: '',
  description: '',
  background_color: '#ff0000',
  file_url: '',
  image_urls: [],
  overview_urls: [],
  file_ids: [],
  personalization: [], // "TEXT" | "PHOTO"
  price: '',
  base_file_id: '',
  weight: '',
  category_id: '',
  // testimonials: '',
  product_code: 'SKU',
  stock_quantity: 1,
  rating:5,
  sku: '',


};

const addNewProductSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  subtitle: Yup.string().required('Subtitle is required'),
  description: Yup.string(),
  background_color: Yup.string().required('Background color is required'),
  personalization: Yup.array().required('Personalization is required').nullable(),
  price: Yup.number().required('Price is required'),
  weight: Yup.number().required('Weight is required'),
  tags: Yup.array(),
  product_code: Yup.string().required('Product Code is required'),
  stock_quantity: Yup.number().required('Stock Quantity is required'),
  related_product: Yup.array(),
  rating: Yup.number().required('Rating is required'),
  sku: Yup.string().required('SKU is required'),


});

export default memo(function AddProductForm({
  allCategories = [],
  // testimonials = [],
  tags = [],
  relatedProducts = [],
  alldesigns=[]

}) {
  const navigate = useNavigate();

  const editor = useRef(null);

  const [content, setContent] = useState('');

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    buttons: 'bold,strikethrough,underline,italic,|,superscript,subscript,ol,|undo,redo',

    toolbarAdaptive: false,
    placeholder: 'Product description',
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false
  };

  const [loading, setLoading] = useState(false);
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState('#ff0000');

  const [images, setImages] = useState({
    image_urls: [],
    overview_urls: []
  });

  const handleAddProduct = (values) => {
    const PAYLOAD = {
      ...values,
      ...images,
      category_id: values.category_id,
      background_color: selectedBackgroundColor?.css?.backgroundColor || selectedBackgroundColor,
      title: { EN: values.title },
      description: { EN: values.description },
      // testimonials: [values.testimonials],
      subtitle: { EN: values.subtitle },
      personalization: values.personalization.map((p) => p.value)
    };

    if (!images.overview_urls || !images.image_urls) {
      toast.error('Please upload all the required files', { duration: 2000 });
      return;
    }
    setLoading(true);
    createProductRequest(PAYLOAD)
      .then((res) => {
        toast.success('Product added successfully', { duration: 3000 });
        navigate('/products');
      })
      .catch(({ data, status }) => {
        debugger;
        if (status === 409) {
          Swal.fire({
            title: 'Error',
            text: data.message.replace('product_code', 'Product Code'),
            icon: 'error'
          });
        }
        // toast.error(data.message, { duration: 6000 });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: formikValues,
    validationSchema: addNewProductSchema,
    onSubmit: handleAddProduct
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  const handleUploadProductImages = (event) => {
    const files = Array.from(event.target.files);

    setLoading(true);
    const requests = files.map((file) => uploadFileRequest(file));

    Axios.all(requests)
      .then((res) => {
        const fileUls = res.map((r) => r.data.file_url);
        setImages({ ...images, image_urls: fileUls });
      })
      .catch((err) => {
        console.trace(err);
      })
      .finally(() => setLoading(false));
  };

  const handleUploadProductOverviewImages = (event) => {
    const files = Array.from(event.target.files);
    setLoading(true);
    const requests = files.map((file) => uploadFileRequest(file));

    Axios.all(requests)
      .then((res) => {
        const fileUls = res.map((r) => r.data.file_url);
        setImages({ ...images, overview_urls: fileUls });
      })
      .catch((err) => {
        console.trace(err);
      })
      .finally(() => setLoading(false));
  };

  const onDragEnd = ({ destination, source }, targetState) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(images[targetState], source.index, destination.index);

    setImages({ ...images, [targetState]: newItems });
  };

  useEffect(() => {
    setValues({ ...values, category_id: allCategories?.[0]?.id });
  }, [allCategories]);

  return (
    <FormikProvider value={formik}>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
            fullWidth
            autoComplete="title"
            type="text"
            label="Product Title *"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type="text"
            label="Subtitle *"
            {...getFieldProps('subtitle')}
            error={Boolean(touched.subtitle && errors.subtitle)}
            helperText={touched.subtitle && errors.subtitle}
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
            fullWidth
            label="Price *"
            error={Boolean(touched.price && errors.price)}
            helperText={touched.price && errors.price}
            {...getFieldProps('price')}
            type="number"
            size="small"
            onWheel={() => document.activeElement.blur()} 
          />
          {/* <FormControlLabel
            error={Boolean(touched.background_color && errors.background_color)}
            helperText={touched.background_color && errors.background_color}
            {...getFieldProps('background_color')}
            style={{ width: '100%' }}
            control={
              <ColorPicker value={selectedBackgroundColor} onChange={setSelectedBackgroundColor} />
            }
            label="Background color"
          /> */}
        </Stack>
        <Stack my="1rem" direction="column" position="relative" spacing={1}>
          <FormLabel>Description</FormLabel>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(newContent) => {}}
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
          <label style={{ margin: '0', width: '100%' }} htmlFor="image_urls">
            <input
              onChange={handleUploadProductImages}
              accept="image/*"
              style={{ display: 'none' }}
              multiple
              id="image_urls"
              name="image_urls"
              type="file"
            />
            <Button fullWidth variant="contained" component="span">
              Upload product images
            </Button>
          </label>
          {images.image_urls.length > 0 && (
            <DragDropContext onDragEnd={(dndProps) => onDragEnd(dndProps, 'image_urls')}>
              <Droppable direction="horizontal" droppableId="images-droppable">
                {(provided) => (
                  <List
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                      gap: '4px',
                      flexWrap: 'wrap'
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {images.image_urls.map((item, index) => (
                      <DraggableImageItem key={item} index={index} url={item} />
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
          <label style={{ margin: '0', width: '100%' }} htmlFor="overview_urls">
            <input
              onChange={handleUploadProductOverviewImages}
              accept="image/*"
              style={{ display: 'none' }}
              multiple
              id="overview_urls"
              name="overview_urls"
              type="file"
            />
            <Button fullWidth variant="contained" component="span">
              Upload product overview images
            </Button>
          </label>
          {images.overview_urls.length > 0 && (
            <DragDropContext onDragEnd={(dndProps) => onDragEnd(dndProps, 'overview_urls')}>
              <Droppable direction="horizontal" droppableId="overview-droppable">
                {(provided) => (
                  <List
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                      flexWrap: 'wrap',
                      gap: '4px'
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {images.overview_urls.map((item, index) => (
                      <DraggableImageItem key={item} index={index} url={item} />
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <Autocomplete
            multiple
            fullWidth
            disabled={!values?.personalization}
            id="Personalization"
            options={personalizationOptions}
            value={values?.personalization}
            getOptionLabel={(option) => option?.label}
            filterSelectedOptions
            freeSolo
            onChange={(event, newValue) => {
              setValues({ ...values, personalization: newValue });
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                error={Boolean(touched.personalization && errors.personalization)}
                helperText={touched.personalization && errors.personalization}
                {...params}
                label="Personalization"
                placeholder="Personalization"
              />
            )}
          />
          {console.log(alldesigns)}
        {values.personalization.some(item => item.value === "DESIGN") && <Autocomplete
            multiple
            fullWidth
            options={
              alldesigns?.map((t) => {
                return {
                  title: t.title,
                  id: t.id,
                  image_url: t?.image?.thumbnail_url,
                };
              }) || []
            }
            // value={values?.design ?? []}
            getOptionLabel={(option) =>
              (
                <>
                  <span title={option?.title} className="one-line">
                    {option?.title + ' '}
                  </span>
                </>
              ) ?? option?.flavor
            }
            isOptionEqualToValue={(option, value) => option.title === value.title}
            onChange={(event, newValue) => {

              const newRelateddDesigns = newValue.map((flavor) => ({
                title: flavor.title ,
                id: flavor.id ,
                image_url: flavor?.image_url,
              }));

              setValues({ ...values, design: newRelateddDesigns });
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Designs" placeholder="Designs" />
            )}
          />}
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
            fullWidth
            label="Weight *"
            error={Boolean(touched.weight && errors.weight)}
            helperText={touched.weight && errors.weight}
            {...getFieldProps('weight')}
            type="number"
            size="small"
            onWheel={() => document.activeElement.blur()} 
          />

          <TextField
            fullWidth
            label="Category"
            error={Boolean(touched.category_id && errors.category_id)}
            helperText={touched.category_id && errors.category_id}
            {...getFieldProps('category_id')}
            select
            size="small"
          >
            {allCategories?.map(({ id, title }, index) => (
              <MenuItem key={id} value={id}>
                {title}
              </MenuItem>
            ))}
          </TextField>
          {/* <TextField
            fullWidth
            label="Rating"
            error={Boolean(touched.rating && errors.rating)}
            helperText={touched.rating && errors.rating}
            {...getFieldProps('rating')}
            select
            size="small"
          >
            
               <MenuItem key={1} value={1}>
                {1}
              </MenuItem>
              <MenuItem key={2} value={2}>
                {2}
              </MenuItem>
              <MenuItem key={3} value={3}>
                {3}
              </MenuItem>
              <MenuItem key={4} value={4}>
                {4}
              </MenuItem>
              <MenuItem key={5} value={5}>
                {5}
              </MenuItem>
          </TextField> */}
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          {/* <TextField
            fullWidth
            label="Testimonial"
            error={Boolean(touched.testimonials && errors.testimonials)}
            helperText={touched.testimonials && errors.testimonials}
            {...getFieldProps('testimonials')}
            select
            size="small"
          >
            {testimonials?.map(({ id, text }) => (
              <MenuItem key={id} value={id}>
                {text}
              </MenuItem>
            ))}
          </TextField> */}
          <TextField
            fullWidth
            label="Product Code"
            error={Boolean(touched.product_code && errors.product_code)}
            helperText={touched.product_code && errors.product_code}
            {...getFieldProps('product_code')}
            type="string"
            size="small"
          />
          <TextField
            fullWidth
            label="Stock Quantity"
            error={Boolean(touched.stock_quantity && errors.stock_quantity)}
            helperText={touched.stock_quantity && errors.stock_quantity}
            {...getFieldProps('stock_quantity')}
            type="number"
            min={1}
            size="small"
            onWheel={() => document.activeElement.blur()} 
          />
            <TextField
            fullWidth
            label="SKU"
            error={Boolean(touched.sku && errors.sku)}
            helperText={touched.sku && errors.sku}
            {...getFieldProps('sku')}
            type="string"
            size="small"
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <Autocomplete
            multiple
            fullWidth
            id="tags-filled"
            options={tags?.map((t) => t) || []}
            value={values?.tags?.map((t) => t) || []}
            getOptionLabel={(option) => option?.title}
            // freeSolo
            onChange={(event, newValue) => {
              const newTags = [...new Set(newValue?.map((t) => t.title || t) || [])];
              setValues({ ...values, tags: newTags });
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <Autocomplete
            multiple
            fullWidth
            id="tags-outlined"
            options={relatedProducts?.map((t) => t) || []}
            value={values?.related_product?.map((t) => t) || []}
            getOptionLabel={(option) => option?.flavor || option?.title}
            isOptionEqualToValue={(option, value) => option.flavor === value.flavor}
            onChange={(event, newValue) => {
              const newRelatedProducts = newValue.map((flavor) => ({
                flavor: flavor.title || flavor.flavor,
                product_id: flavor.id || flavor.product_id
              }));
              setValues({ ...values, related_product: newRelatedProducts });
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Related products" placeholder="Products" />
            )}
          />
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={loading}
          loading={loading}
          className='save-btn'
        >
          Add Product
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
});
