import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { fDateTimeSuffix, fDateTime, fDate, fToNow } from '../utils/formatTime';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ProductsListHead,
  ProductsListToolbar,
  ProductsMoreMenu
} from '../components/_dashboard/products';
//
import USERLIST from '../_mocks_/user';
import { getProductsRequest } from 'src/services/products/getProductsRequest.service';
import { apiUrl, tableConfigs } from 'src/configs';
import { getTestimonialsRequest } from 'src/services/testimonials/getTestimonialsRequest.service';
import { getCouponsRequest } from 'src/services/coupons/getCouponsRequst.service';
import CouponsMoreMenu from 'src/components/Coupons/Menu';
import { getCategoriesRequest } from 'src/services/categories/getCategoriesRequest.service';
import { getOrdersRequest } from 'src/services/orders/getOrdersRequest.service';
import OrdersMoreMenu from 'src/components/orders/Menu';
import Loader from 'src/components/loader/Loader';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'order-code', label: 'Order Code', alignRight: false },
  { id: 'total-price', label: 'Total Price', alignRight: false },
  { id: 'total-price', label: 'Total Price', alignRight: false },
  { id: 'status', label: 'Order Status', alignRight: false },
  { id: 'order-datetime', label: 'Order date', alignRight: false }
];

// ----------------------------------------------------------------------

export default function CustomerOrders() {
  const emptyRows = 5 > 0 ? Math.max(0, (1 + 5) * 5 - USERLIST.length) : 0;
  const { customer_id: customerId } = useParams();
  const [selected, setSelected] = useState([]);
  const [orders, setOrders] = useState({
    data: [],
    total: 0
  });
  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    user_id: customerId,
    limit: tableConfigs.defaultPageSize,
    offset: 0
  });

  // const isUserNotFound = [].length === 0;

  const getOrders = () => {
    setLoading(true);

    getOrdersRequest(params).then(({ data }) => {
      setLoading(false);
      setOrders({
        data: data.result,
        total: data.total
      });
    });
  };

  useEffect(() => {
    getOrders();
  }, [params]);

  return (
    <Page title="Customer Orders">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Customer Orders
          </Typography>
        </Stack>

        <Card>
          <ProductsListToolbar
            numSelected={selected.length}
            filterName=""
            onFilterName={() => {}}
            showFilter
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductsListHead
                  order={'name'}
                  orderBy={'name'}
                  headLabel={TABLE_HEAD}
                  rowCount={params.offset}
                  onRequestSort={() => {}}
                  onSelectAllClick={() => {}}
                />
                <TableBody>
                  {loading && <Loader />}
                  {orders.data.map((row) => {
                    const {
                      id,
                      total_price,
                      total_items,
                      order_code,
                      create_datetime,
                      order_status
                    } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="center">{order_code ?? ''}</TableCell>
                        <TableCell align="center">{total_price ?? ''}</TableCell>
                        <TableCell align="center">{total_items ?? ''}</TableCell>
                        <TableCell align="center">{order_status ?? ''}</TableCell>
                        <TableCell align="center">{fDate(create_datetime)}</TableCell>
                        <TableCell align="right">
                          <OrdersMoreMenu onGetOrders={getOrders} order={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {orders.total === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={params?.search ?? ''} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            SelectProps={{
              disabled: loading
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            rowsPerPageOptions={tableConfigs.pageSizeOptions
              .filter((item) => item <= orders.total)
              .concat(orders.total)}
            component="div"
            count={orders.total}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(_, selected) => {
              setParams({
                ...params,
                offset: selected * params.limit
              });
            }}
            onRowsPerPageChange={({ target }) => {
              const selected = target.value;
              setParams({ ...params, limit: selected });
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}
