import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { updatePromotionRequest } from 'src/services/promotions/updatePromotionRequest.service';
import { deletePromotionRequest } from 'src/services/promotions/deletePromotionRequest.service';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

export default function PromotionsMoreMenu({ promotionId, onGetPromotions, setLoading }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const changeStatusHandler = (status) => {
    const PAYLOAD = {
      is_enable: status === 'enable'
    };

    setIsOpen(false);

    updatePromotionRequest(PAYLOAD, promotionId)
      .then(() => {
        setLoading(true);
        setTimeout(() => {
          onGetPromotions();
          toast.success('Promotion status changed successfully', { duration: 3000 });
        }, 3500);
      })
      .catch(() => {
        toast.error('Something went wrong', { duration: 3000 });
      });
  };

  const deletePromotionHandler = () => {
    setIsOpen(false);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this promotion!',
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        deletePromotionRequest(promotionId)
          .then(() => {
            toast.success('Poof! Your promotion has been deleted!', { duration: 4000 });
            onGetPromotions();
          })
          .catch(() => {
            toast.error('Something went wrong', { duration: 4000 });
          });
      }
    });
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={deletePromotionHandler} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon="fluent:delete-28-regular" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to={'/promotions/edit/' + promotionId}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          onClick={changeStatusHandler.bind(this, 'disable')}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon="fe:disabled" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Disable" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          onClick={changeStatusHandler.bind(this, 'enable')}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon="akar-icons:chat-approve" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Enable" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
