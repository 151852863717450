import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import DateAdapter from '@mui/lab/AdapterDateFns';

// material
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  ImageListItem,
  ImageList,
  MenuItem,
  Checkbox,
  Autocomplete
} from '@mui/material';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { apiUrl } from 'src/configs';
import { useEffect } from 'react';
import { updateCategoryRequest } from 'src/services/categories/updateCategoryRequest.service';
import { updateCouponRequest } from 'src/services/coupons/updateCouponRequest.service';
import { editTestimonialRequest } from 'src/services/testimonials/editTestimonialRequest.service';
import { addTestimonialRequest } from 'src/services/testimonials/addTestimonialRequest.service';

// ----------------------------------------------------------------------
// {
//   "name": "string",
//   "text": "string",
//   "image_url": "string",
//   "is_celebrity": true,
//   "social_media": "string",
//   "social_username": "string",
//   "tag": "string",
//   "entity_id": "string",
//   "rtl": true,
//   "user_id": "string"
// }

const initialValues = {
  name: '',
  text: '',
  image_url: '',
  is_celebrity: false,
  social_media: '',
  social_username: ''
};

const addTestimonialSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  text: Yup.string().required('Text is required'),
  social_username: Yup.string().required('Social username is required'),
  social_media: Yup.string().required('Social media is required'),
  is_celebrity: Yup.boolean()
});

export default function EditTestimonialForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  const handleAddTestimonial = (values) => {
    const PAYLOAD = {
      ...values,
      image_url: image
    };
    debugger;
    addTestimonialRequest(PAYLOAD)
      .then((res) => {
        toast.success('Testimonial added successfully', { duration: 4000 });
        navigate('/testimonials');
      })
      .catch((err) => {
        toast.error(err?.message || 'something went wrong', { duration: 4000 });
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    validationSchema: addTestimonialSchema,
    initialValues: initialValues,
    onSubmit: handleAddTestimonial
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  const handleUploadTestimonialImage = (event) => {
    const file = event.target.files[0];

    setLoading(true);
    uploadFileRequest(file)
      .then(({ data }) => {
        setImage(data.file_url);
      })
      .catch((err) => {
        console.trace(err);
      })
      .finally(() => setLoading(false));
  };
  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              {...getFieldProps('name')}
              focused={values?.name}
              fullWidth
              autoComplete="title"
              type="text"
              label="Name"
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              focused={values?.text}
              autoComplete="text"
              type="text"
              label="Text"
              {...getFieldProps('text')}
              error={Boolean(touched.text && errors.text)}
              helperText={touched.text && errors.text}
            />
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <FormControlLabel
              style={{ width: '100%' }}
              checked={values?.is_celebrity}
              control={
                <Checkbox {...getFieldProps('is_celebrity')} checked={!!values?.is_celebrity} />
              }
              label="Is Celebrity"
            />
            <TextField
              fullWidth
              focused={values?.social_username}
              autoComplete="social_username"
              type="text"
              label="Social username"
              {...getFieldProps('social_username')}
              error={Boolean(touched.social_username && errors.social_username)}
              helperText={touched.social_username && errors.social_username}
            />
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <Autocomplete
              fullWidth
              id="tags-outlined"
              options={['instagram', 'tiktok', 'youtube', 'twitter', 'facebook']}
              getOptionLabel={(option) => option}
              value={values?.social_media}
              onChange={(event, newValue) => {
                setValues({ ...values, social_media: newValue });
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="Social media" placeholder="Social media" />
              )}
            />
          </Stack>
          <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
            <label style={{ margin: '0', width: '100%' }} htmlFor="overview_urls">
              <input
                onChange={handleUploadTestimonialImage}
                accept="image/*"
                style={{ display: 'none' }}
                multiple
                id="overview_urls"
                name="overview_urls"
                type="file"
              />
              <Button fullWidth variant="contained" component="span">
                Upload testimonial image
              </Button>
            </label>
            {image && (
              <img
                style={{ margin: '1rem auto 0' }}
                width={60}
                src={image}
                alt="testimonial image"
              />
            )}
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
          >
            Edit Coupon
          </LoadingButton>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
}
