export const mapServerErrorsToUIErrors = (errors, setInputErrors) => {
  const errorKeys = errors?.map((error) => error.loc[1]);

  setInputErrors(
    errorKeys.reduce((prev, curr) => {
      prev[curr] = errors.find((error) => error.loc[1] === curr)?.msg;
      return prev;
    }, {})
  );
};
