import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link, Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import DateAdapter from '@mui/lab/AdapterDateFns';

// material
import { Stack, TextField, Button, IconButton, Tooltip, Typography, Divider } from '@mui/material';
import { LoadingButton, LocalizationProvider } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { editTestimonialRequest } from 'src/services/testimonials/editTestimonialRequest.service';
import { addNewsRequest } from 'src/services/news/addNewsRequest.service';
import { editSingleNewsRequest } from 'src/services/news/editSingleNewsRequest.service';
import { editProfileRequest } from 'src/services/me/editProfileRequest.service';
import Loader from '../loader/Loader';
import { updateConfigsRequest } from 'src/services/configs/updateConfigsRequest.service';
import { Icon } from '@iconify/react';
import Swal from 'sweetalert2';

const settingsSchema = Yup.object().shape({
  products_page_title: Yup.string().required('products page title is required'),
  product_detail_page_flavor_text: Yup.string().required(
    'product detail page flavor text is required'
  ),
  default_email_recipients: Yup.string()
    .email('shop assistant email is invalid')
    .required('shop assistant email is required')
});

export default function SettingsForm({ settings }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleEditSettings = (values) => {
    const PAYLOAD = {
      other_configs: {
        products_page_title: values.products_page_title,
        product_detail_page_flavor_text: values.product_detail_page_flavor_text
      },
      default_email_recipients: [values.default_email_recipients]
    };

    setLoading(true);

    updateConfigsRequest(PAYLOAD)
      .then((res) => {
        toast.success('website edited successfully', { duration: 4000 });
        navigate('/');
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    validationSchema: settingsSchema,
    initialValues: null,
    onSubmit: handleEditSettings
  });

  useEffect(() => {
    if (settings) {
      setValues({
        products_page_title: settings?.other_configs?.products_page_title ?? '',
        product_detail_page_flavor_text:
          settings?.other_configs?.product_detail_page_flavor_text ?? '',
        default_email_recipients: settings?.default_email_recipients?.[0] ?? ''
      });
    }
  }, [settings]);

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form
          autoComplete="off"
          noValidate
          style={{ position: 'relative' }}
          onSubmit={handleSubmit}
        >
          <Divider />
          <Typography fontSize="1.3rem" mt={1.1}>
            Products page:
          </Typography>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            {!Boolean(settings) && <Loader />}
            <TextField
              {...getFieldProps('products_page_title')}
              focused={values?.products_page_title}
              fullWidth
              autoComplete="products_page_title"
              type="text"
              label="Title"
              error={Boolean(touched.products_page_title && errors.products_page_title)}
              helperText={touched.products_page_title && errors.products_page_title}
              // InputProps={{
              //   endAdornment: (
              //     <Tooltip title="Not sure what part will change ?">
              //       <IconButton>
              //         <Icon icon="emojione-monotone:exclamation-question-mark" hFlip={true} />
              //       </IconButton>
              //     </Tooltip>
              //   )
              // }}
            />
          </Stack>
          <Divider />
          <Typography fontSize="1.3rem" mt={1.1}>
            Product detail page:
          </Typography>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            {!Boolean(settings) && <Loader />}
            <TextField
              {...getFieldProps('product_detail_page_flavor_text')}
              focused={values?.product_detail_page_flavor_text}
              fullWidth
              autoComplete="product_detail_page_flavor_text"
              type="text"
              label="Related products text"
              error={Boolean(
                touched.product_detail_page_flavor_text && errors.product_detail_page_flavor_text
              )}
              helperText={
                touched.product_detail_page_flavor_text && errors.product_detail_page_flavor_text
              }
              // InputProps={{
              //   endAdornment: (
              //     <Tooltip title="Not sure what part will change ?">
              //       <IconButton>
              //         <Icon icon="emojione-monotone:exclamation-question-mark" hFlip={true} />
              //       </IconButton>
              //     </Tooltip>
              //   )
              // }}
            />
          </Stack>
          <Divider />
          <Typography fontSize="1.3rem" mt={1.1}>
            Shop assistant:
          </Typography>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            {!Boolean(settings) && <Loader />}
            <TextField
              {...getFieldProps('default_email_recipients')}
              focused={values?.default_email_recipients}
              fullWidth
              autoComplete=""
              type="text"
              label="Shop assistant email"
              error={Boolean(touched.default_email_recipients && errors.default_email_recipients)}
              helperText={touched.default_email_recipients && errors.default_email_recipients}
              // InputProps={{
              //   endAdornment: (
              //     <Tooltip title="Not sure what part will change ?">
              //       <IconButton>
              //         <Icon icon="emojione-monotone:exclamation-question-mark" hFlip={true} />
              //       </IconButton>
              //     </Tooltip>
              //   )
              // }}
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
            className='save-btn'

          >
            Save
          </LoadingButton>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
}
