import { query } from 'src/utils/query';

export const updateCustomerRequest = (payload, customerId) => {
  return query({
    uri: 'api/v1/admin/users/customers/' + customerId + '/',
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
