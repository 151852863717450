import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { debounce, filter, range } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ProductsListHead,
  ProductsListToolbar,
  ProductsMoreMenu
} from '../components/_dashboard/products';
//
import USERLIST from '../_mocks_/user';
import { getProductsRequest } from 'src/services/products/getProductsRequest.service';
import { apiUrl, tableConfigs } from 'src/configs';
import ProductsFilterSidebar from 'src/components/Products/ProductsFilterSidebar';
import SearchBar from 'src/components/_dashboard/blog/BlogPostsSearch';
import { getProductsCSVRequest } from 'src/services/products/getProductsCSVRequest.service';
import { bulkDeleteProductsRequest } from 'src/services/products/bulkDeleteProductsRequest.service';
import { downloadCSVFile } from 'src/utils/downloadCSV';
import { LoadingButton } from '@mui/lab';
import Loader from 'src/components/loader/Loader';
import { useConfig } from 'src/providers/config';
import { getAllCategoriesAttributesRequest } from 'src/services/products/getAllCategoriesAttributesRequest';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'image', label: 'Image', alignRight: false, sortable: false },
  { id: 'title', label: 'Title', alignRight: false, sortable: true },
  {
    id: 'product_status',
    label: 'Status',
    alignRight: false,
    sortable: true
  },
  { id: 'uom', label: 'UOM', alignRight: false, sortable: true },
  { id: 'product_code', label: 'UPC', alignRight: false, sortable: true },
  { id: 'units', label: 'Units', alignRight: false, sortable: true },
  {
    id: 'price',
    label: 'Price',
    alignRight: false,
    sortable: true
  },
  { id: 'is_enable', label: 'Enable', alignRight: false, sortable: true },
  { id: '', label: '', alignRight: false, sortable: false }
];

// ----------------------------------------------------------------------

export const loaderStyle = {
  position: 'fixed',
  top: '50%',
  left: '60%',
  transform: 'translate(-50%, -50%)'
};

export default function Products() {
  const emptyRows = 5 > 0 ? Math.max(0, (1 + 5) * 5 - USERLIST.length) : 0;

  const { config, getConfig } = useConfig();

  const [selectedItems, setSelectedItems] = useState([]);
  const [products, setProducts] = useState({
    data: [],
    total: 0
  });

  const [categoryAttributes, setCategoryAttributes] = useState();

  const [loading, setLoading] = useState(true);

  const [openFilter, setOpenFilter] = useState(false);

  const [params, setParams] = useState({
    limit: tableConfigs.defaultPageSize,
    search: null,
    offset: 0,
    price_gt: null,
    price_lt: null,
    status: [],
    cat_ids: [],
    carousel_tags: [],
    sort_by: 'desc(product_status)',
    is_enable: null
  });

  // const isUserNotFound = [].length === 0;

  const getCategoryDetail = () => {
    setLoading(true);
    getAllCategoriesAttributesRequest()
      .then(({ data }) => setCategoryAttributes(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getProducts = () => {
    setLoading(true);
    getProductsRequest(params)
      .then(({ data }) => {
        setProducts({
          data: data.result,
          total: data.total
        });
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getProducts();
  }, [params]);

  useEffect(() => {
    getConfig();
    getCategoryDetail();
  }, []);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleFilterChange = debounce((newParams = params) => {
    setParams({ ...newParams });
  }, 200);

  const downloadCSVHandler = () => {
    setLoading(true);
    getProductsCSVRequest().then(({ data }) => {
      downloadCSVFile(data.url, 'products');
      setLoading(false);
    });
  };

  const handleSelectItem = (event, peoductId) => {
    const { checked } = event.target;
    setSelectedItems(
      checked ? [...selectedItems, peoductId] : selectedItems.filter((item) => item !== peoductId)
    );
  };

  const handleSelectAllItems = (event) => {
    if (event.target.checked) {
      const allItems = products.data.map((p) => p.id);
      setSelectedItems(allItems);
      return;
    }
    setSelectedItems([]);
  };

  const deleteProductsHandler = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Once deleted, you will not be able to recover ${
        selectedItems.length === 1 ? 'this product' : 'these products'
      }!`,
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: `${selectedItems.length === 1 ? 'Yes, delete it!' : 'Yes, delete them!'}`
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        const PAYLOAD = {
          ids: selectedItems
        };
        bulkDeleteProductsRequest(PAYLOAD)
          .then(() => {
            setSelectedItems([]);
            toast.success(
              `Poof! Your ${
                selectedItems.length === 1 ? 'product has' : 'products have'
              } been deleted!`,
              { duration: 4000 }
            );
            getProducts();
          })
          .catch(() => {
            toast.error('Something went wrong', { duration: 4000 });
          });
      }
    });
  };

  return (
    <Page title="Products">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/products/add"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Product
          </Button>
        </Stack>

        <Card>
          {selectedItems.length === 0 && (
            <Stack
              direction="row"
              flexWrap="wrap-reverse"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 3, mx: 3.3 }}
            >
              <SearchBar filters={params} onFilterChange={handleFilterChange} isLoading={loading} />
              <Stack direction="row" gap={2}>
                <LoadingButton
                  loading={loading}
                  onClick={downloadCSVHandler}
                  disableRipple
                  color="inherit"
                  variant="contained"
                  endIcon={<Icon icon="foundation:page-export-csv" />}
                >
                  Export &nbsp;
                </LoadingButton>
                <ProductsFilterSidebar
                  filters={params}
                  onFilterChange={handleFilterChange}
                  isOpenFilter={openFilter}
                  onResetFilter={() => {
                    handleFilterChange({
                      ...params,
                      status:[],
                      search: null,
                      price_gt: null,
                      price_lt: null
                    });
                  }}
                  onOpenFilter={handleOpenFilter}
                  onCloseFilter={handleCloseFilter}
                  categoryAttributes={categoryAttributes}
                />
              </Stack>
            </Stack>
          )}

          {selectedItems.length > 0 && (
            <ProductsListToolbar
              filterName=""
              onFilterName={() => {}}
              numSelected={selectedItems.length}
              deleteSelectedItems={deleteProductsHandler}
            />
          )}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, marginTop: 1 }}>
              <Table>
                <ProductsListHead
                  order={'name'}
                  params={params}
                  orderBy={'name'}
                  headLabel={TABLE_HEAD}
                  rowCount={products.data.length}
                  numSelected={selectedItems.length}
                  onRequestSort={(sortParams) => {
                    handleFilterChange({
                      ...params,
                      ...sortParams
                    });
                  }}
                  onSelectAllClick={handleSelectAllItems}
                />
                <TableBody>
                  {loading && (
                    <div style={loaderStyle}>
                      <Loader />
                    </div>
                  )}
                  {products?.data?.map((row) => {
                    const {
                      id,
                      title,
                      image_urls,
                      product_status,
                      product_code: upc,
                      price,
                      is_enable,
                      units,
                      uom
                    } = row;
                    const isItemSelected = selectedItems.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                      >
                        <TableCell align="left">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(e) => handleSelectItem?.(e, id)}
                          />
                        </TableCell>
                        <TableCell align="center" component="th" scope="row" padding="1rem">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={title ?? ''} src={image_urls?.[0]} />
                          </Stack>
                        </TableCell>
                        <TableCell align="center">{title ?? ''}</TableCell>
                        <TableCell align="center">
                          <Label
                            variant="ghost"
                            color={product_status ?? '' === 'published' ? 'success' : 'default'}
                          >
                            {sentenceCase(product_status ?? '')}
                          </Label>
                        </TableCell>
                        <TableCell align="center">{uom ?? 0}</TableCell>
                        <TableCell align="center">{upc ?? 0}</TableCell>
                        <TableCell align="center">{units ?? 0}</TableCell>
                        <TableCell align="center">
                          <span style={{ fontSize: '0.6rem' }}>AED </span>
                          {price ?? 0}
                        </TableCell>
                        <TableCell align="center">
                          <Label
                            style={{ padding: '0' }}
                            variant="ghost"
                            color={!!is_enable ? 'success' : 'error'}
                          >
                            {!!is_enable ? (
                              <Icon icon="akar-icons:chat-approve" width={24} height={24} />
                            ) : (
                              <Icon icon="fe:disabled" width={24} height={24} />
                            )}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <ProductsMoreMenu
                            onGetProducts={getProducts}
                            productId={id}
                            setLoading={setLoading}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {products?.total === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={params?.search ?? ''} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            SelectProps={{
              disabled: loading
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            rowsPerPageOptions={tableConfigs.pageSizeOptions
              .filter((item) => item <= products.total)
              .concat(products.total)}
            component="div"
            count={products.total}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(_, selected) => {
              setParams({
                ...params,
                offset: selected * params.limit
              });
            }}
            onRowsPerPageChange={({ target }) => {
              const selected = target.value;
              setParams({ ...params, limit: selected });
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}
