import { Box, ImageListItem, ListItem } from '@mui/material';

import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const DraggableImageItem = ({ url, index }) => {
  return (
    <Draggable key={url} draggableId={url} index={index}>
      {(provided, snapshot) => (
        <>
          <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <img
              style={{ objectFit: 'cover', height: '7rem', width: '7rem' }}
              src={url}
              alt="product image"
            />
          </li>
          {provided.placeholder}
        </>
      )}
    </Draggable>
  );
};

export default DraggableImageItem;
