import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextField,
  Slider
} from '@mui/material';
//
import Scrollbar from '../Scrollbar';
import ColorManyPicker from '../ColorManyPicker';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { memo, useState } from 'react';
import { debounce } from 'lodash';
import { replaceUnderscoreWithSpace } from 'src/utils/strings';

// ----------------------------------------------------------------------

export const FILTER_STATUS_OPTIONS = [
  'pending',
  'just_joined',
  'joined_club',
  'confirmed',
  'rejected'
];

// ----------------------------------------------------------------------

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;

const CustomersFilterSidebar = memo(
  ({ isOpenFilter, onResetFilter, onOpenFilter, onCloseFilter, filters, onFilterChange }) => {
    return (
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Button
          disableRipple
          color="inherit"
          endIcon={<Icon icon="ic:round-filter-list" />}
          onClick={onOpenFilter}
        >
          Filters&nbsp;
        </Button>

        <Drawer
          anchor="right"
          open={isOpenFilter}
          onClose={onCloseFilter}
          PaperProps={{
            sx: { width: 280, border: 'none', overflow: 'hidden' }
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 1, py: 2 }}
          >
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              Filters
            </Typography>
            <IconButton onClick={onCloseFilter}>
              <Icon icon="eva:close-fill" width={20} height={20} />
            </IconButton>
          </Stack>

          <Divider />

          <Scrollbar>
            <Stack spacing={3} sx={{ p: 3 }}>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Customers status
                </Typography>
                {FILTER_STATUS_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        checked={filters?.user_status?.includes(item)??false}
                        onChange={({ target }) => {
                          const { checked } = target;
                          if (checked) {
                            onFilterChange({
                              ...filters,
                              user_status: [...(filters?.user_status ?? []), item]
                            });
                          } else {
                            onFilterChange({
                              ...filters,
                              user_status: filters?.user_status?.filter((status) => status !== item)
                            });
                          }
                        }}
                        value={item}
                      />
                    }
                    label={replaceUnderscoreWithSpace(item)}
                  />
                ))}
              </div>
            </Stack>
          </Scrollbar>

          <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={onResetFilter}
              startIcon={<Icon icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box>
        </Drawer>
      </LocalizationProvider>
    );
  }
);

export default CustomersFilterSidebar;

CustomersFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object
};
