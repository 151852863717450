import { query } from 'src/utils/query';

/** update user information */
export const editProfileRequest = (payload) => {
  return query({
    uri: 'api/v1/admin/me',
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
