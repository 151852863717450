import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import { ColorPicker } from 'material-ui-color';

// material
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  ImageListItem,
  ImageList,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  createFilterOptions,
  Chip,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  Divider
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { memo } from 'react';
import { AddCircleOutlined, HdrPlusRounded, PlusOneRounded } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import { addOrderPackRequest } from 'src/services/OrderPacks/AddOrderPackRequest.service';
import { editOrderPackRequest } from 'src/services/OrderPacks/editOrderPackRequest.service';
import Loader from '../loader/Loader';
import Swal from 'sweetalert2';

const filter = createFilterOptions();

// ----------------------------------------------------------------------
const formikValues = {
  title: ''
};

const editOrderPackSchema = Yup.object().shape({
  title: Yup.string().required('Title is required')
});

export default memo(function EditOrderPackForm({ packId, pack }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState([]);
  const [optionToBeEdited, setOptionToBeEdited] = useState(null);
  const [option, setOption] = useState({
    name: '',
    price: '',
    id: ''
  });

  const [image, setImage] = useState({ id: '', url: '' });

  const formik = useFormik({
    initialValues: formikValues,
    validationSchema: editOrderPackSchema,
    onSubmit: (values) => {
      const PAYLOAD = {
        ...values,
        image_url: image.url,
        options: options.map((option) => ({
          name: option.name,
          price: option.price
        })),
        ids: [packId]
      };
      if (!image.url) {
        toast.error('Image is required');
        return;
      }
      setLoading(true);

      editOrderPackRequest(PAYLOAD)
        .then(() => {
          navigate('/order-packs');
        })
        .catch(({ data }) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: data?.message || 'something went wrong'
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  const handleUploadPackImage = (event) => {
    const file = event.target.files[0];

    setLoading(true);
    uploadFileRequest(file)
      .then(({ data }) => {
        setImage({ id: data.id, url: data.file_url });
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  const handleAddOrEditOption = () => {
    if (optionToBeEdited) {
      setOptions(options.map((o) => (option.id === o.id ? { ...option } : o)));
      setOptionToBeEdited(null);
      setOption({
        name: '',
        price: '',
        id: ''
      });
    } else {
      setOptions([...options, { ...option, id: Date.now() }]);
      setOption({
        name: '',
        price: '',
        id: ''
      });
    }
  };

  useEffect(() => {
    if (pack) {
      setValues({
        title: pack.title ?? ''
      });
      setImage({ url: pack.image_url });
      console.log(pack);
      setOptions(pack.options);
    }
  }, [pack]);

  return (
    <FormikProvider value={formik}>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Form autoComplete="off" style={{ position: 'relative' }} noValidate onSubmit={handleSubmit}>
        {!pack?.title && <Loader />}
        <Stack position="relative" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          {!pack?.title && <Loader />}
          <TextField
            fullWidth
            autoComplete="Pack title"
            type="text"
            label="Pack Title"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
        </Stack>

        <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
          <label style={{ margin: '0', width: '100%' }} htmlFor="image_urls">
            <input
              onChange={handleUploadPackImage}
              accept="image/*"
              style={{ display: 'none' }}
              id="image_urls"
              name="image_urls"
              type="file"
            />
            <Button fullWidth variant="contained" component="span">
              Upload pack image
            </Button>
          </label>
          {image.url && (
            <img
              style={{ margin: '1rem auto 0', width: '300px', objectFit: 'cover' }}
              src={image.url}
              alt="pack image"
            />
          )}
        </Stack>
        <Divider style={{ margin: '2rem auto' }} variant="fullWidth">
          <Chip label="Options" color="primary" />
        </Divider>
        <Stack my="1rem" direction={{ xs: 'row' }} spacing={3}>
          <TextField
            fullWidth
            autoComplete="Option title"
            type="text"
            label="Option Title"
            value={option.name}
            onChange={(e) => setOption({ ...option, name: e.target.value })}
          />
          <TextField
            autoComplete="Option price"
            type="number"
            label="Option Price"
            value={option.price}
            onChange={(e) => setOption({ ...option, price: e.target.value })}
          />
          <Button
            variant="contained"
            onClick={handleAddOrEditOption}
            disabled={!option.name || !option.price}
          >
            {optionToBeEdited ? 'Edit' : 'Add'}
          </Button>
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
          {options?.length !== 0 && (
            <Table>
              <TableHead>
                <TableCell align="left">Actions</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Title</TableCell>
              </TableHead>
              <TableBody>
                {options?.map((option) => (
                  <TableRow key={option.id}>
                    <TableCell align="left">
                      <Button
                        style={{ margin: '0 3px' }}
                        variant="contained"
                        onClick={() => {
                          setOptions(options.filter((o) => o.id !== option.id));
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        style={{ margin: '0 3px' }}
                        variant="contained"
                        onClick={() => {
                          setOptionToBeEdited(option);
                          setOption(option);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell align="center">{option.price}</TableCell>
                    <TableCell align="center">{option.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={loading}
          loading={loading}
          className='save-btn'

        >
          Save
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
});
