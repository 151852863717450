import { query } from 'src/utils/query';

export const googleLoginRequest = (payload) => {
  return query({
    uri: 'api/v1/admin/auth/login/google_token',
    auth: false,
    method: 'GET',
    params: {
      id_token: payload.id_token
    }
  });
};
