import { query } from 'src/utils/query';

import qs from 'qs';

export const getPromotionsRequest = (params) => {
  return query({
    uri: 'api/v1/admin/promotions',
    auth: true,
    method: 'GET',
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
  });
};
