import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import DateAdapter from '@mui/lab/AdapterDateFns';

// material
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  ImageListItem,
  ImageList,
  MenuItem,
  Autocomplete,
  Checkbox,
  createFilterOptions
} from '@mui/material';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { apiUrl } from 'src/configs';
import { useEffect } from 'react';
import { createNewDiscountRequest } from 'src/services/discounts/createNewDiscountRequest.service';
import { countDecimals } from 'src/utils/decimalCounts';
import Swal from 'sweetalert2';
import Label from '../Label';

// ----------------------------------------------------------------------

const initialValues = {
  name: '',
  discount_percentage: '',
  start_datetime: '',
  end_datetime: '',
  products: [],
  categories: [],
  targetType: '',
  banner: 'No',
  banner_text: ''


};

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.title + option.product_code
});

const addDiscountSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  discount_percentage: Yup.number()
    .integer('Discount percentage must be an integer')
    .required('Discount percentage is required')
    .min(1, 'It cannot be less than 1')
    .max(100, 'It cannot be more than 100'),
  start_datetime: Yup.date().required('Start date is required'),
  end_datetime: Yup.date()
    .when(
      'start_datetime',
      (start_datetime, schema) =>
        start_datetime && schema.min(start_datetime, 'End date must be greater than start date')
    )
    .required('End date is required'),
  targetType: Yup.string().oneOf(['product', 'category']).required('Target is required'),
  products: Yup.array().required('Product is required'),
  // categories: Yup.array()
});

export default function AddDiscountForm({ products = [], categories = [] }) {
  const navigate = useNavigate();
  const [entityIds, setEntityIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDateTime, setFromDateTime] = useState();

  const handleAddDiscount = (values) => {
    console.log(values);
    const PAYLOAD = {
      name: values.name,
      discount: parseFloat(values.discount_percentage),
      start_datetime: values.start_datetime,
      end_datetime: values.end_datetime,
      target: {
        type: values.targetType,
        entity_ids: entityIds
      },
      status: 'active',
      banner:values.banner,
      banner_text: values.banner_text



    };

    setLoading(true);
    createNewDiscountRequest(PAYLOAD)
      .then((res) => {
        toast.success('Discounts added successfully', { duration: 3000 });
        navigate('/discounts');
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: addDiscountSchema,
    onSubmit: handleAddDiscount
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              inputProps={{ maxLength:50}}
              {...getFieldProps('name')}
              focused={values?.name}
              fullWidth
              autoComplete="title"
              type="text"
              label="Discount Name *"
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              {...getFieldProps('discount_percentage')}
              fullWidth
              number
              autoComplete="discount_percentage"
              type="number"
              inputProps={{
                max: 100,
                min: 1
              }}
              label="Discount Percentage *"
              error={Boolean(touched.discount_percentage && errors.discount_percentage)}
              helperText={touched.discount_percentage && errors.discount_percentage}
            />
            <TextField
              fullWidth
              label="Target"
              focused={values.targetType}
              error={Boolean(touched?.targetType && errors?.targetType)}
              helperText={touched?.targetType && errors?.targetType}
              {...getFieldProps('targetType')}
              defaultValue={values?.targetType}
              select
            >
              <MenuItem value="category">Category</MenuItem>
              <MenuItem value="product">Product</MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="Banner"
              focused={values.banner}
              error={Boolean(touched?.banner && errors?.banner)}
              helperText={touched?.banner && errors?.banner}
              {...getFieldProps('banner')}
              defaultValue={values?.banner}
              select
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
          </Stack>
          {values.targetType === 'category' ? (
            <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
              <Autocomplete
                multiple
                fullWidth
                options={categories}
                getOptionLabel={(option) => {
                  return option.title;
                }}
                value={categories?.filter((item) => entityIds.includes(item?.id)) ?? []}
                onChange={(event, newValue) => {
                  const newCategoriesIds = newValue.map((category) => category.id);
                  setEntityIds(newCategoriesIds);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label="Categories" placeholder="Categories" error={Boolean(touched?.categories && errors?.categories)}
                  helperText={touched?.categories && errors?.categories} />
                )}
              />
            </Stack>
          ) : (
            <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
              <Autocomplete
                multiple
                fullWidth
                filterOptions={filterOptions}
                options={products || []}
                getOptionLabel={(option) => (
                  <>
                    <span title={option?.title} className="one-line-w-90">
                      {option?.title + ' '}
                    </span>
                    <Label children={option.product_code} />
                  </>
                )}
                value={products?.filter((item) => entityIds.includes(item?.id)) ?? []}
                onChange={(event, newValue) => {
                  debugger;
                  const newProductsIds = newValue.map((product) => product.id);
                  setEntityIds(newProductsIds);
                  setValues({ ...values, products: newValue });
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label="Products" placeholder="Products" error={Boolean(touched?.products && errors?.products)}
                  helperText={touched?.products && errors?.products} />
                )}
              />
            </Stack>
          )}
           {values.banner==="Yes" &&  <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
              {...getFieldProps('banner_text')}
              focused={values?.banner_text}
              fullWidth
              autoComplete="title"
              type="text"
              label="Banner Text *"
              // error={Boolean(touched.name && errors.name)}
              // helperText={touched.name && errors.name}
            />
           </Stack>}
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <DesktopDatePicker
              label="Start date"
              inputFormat="MM/dd/yyyy"
              minDate={new Date()}
              maxDate={
                values?.end_datetime
                  ? new Date(values?.end_datetime)
                  : new Date(new Date().getFullYear(), 11, 31)
              }
              value={fromDateTime || null}
              onChange={(date) => {
                setFromDateTime(date);
                const fromDate = new Date(new Date(date).setUTCHours(0, 0, 1, 0)).toISOString();

                setValues({
                  ...values,
                  start_datetime: fromDate
                });
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  fullWidth
                  {...params}
                  error={Boolean(touched?.start_datetime && errors?.start_datetime)}
              helperText={touched?.start_datetime && errors?.start_datetime}
                />
              )}
            />
            <DesktopDatePicker
              label="End date"
              minDate={fromDateTime || new Date()}
              maxDate={new Date(new Date().getFullYear(), 11, 31)}
              inputFormat="MM/dd/yyyy"
              value={values?.end_datetime || null}
              onChange={(date) => {
                setValues({
                  ...values,
                  end_datetime: new Date(date.setUTCHours(23, 59, 59, 0)).toISOString()
                });
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  fullWidth
                  {...params}
                  error={Boolean(touched?.end_datetime && errors?.end_datetime)}
              helperText={touched?.end_datetime && errors?.end_datetime}
                />
              )}
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
            className='save-btn'

          >
            Save
          </LoadingButton>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
}
