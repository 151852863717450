import { query } from 'src/utils/query';

/** add a single coupon from database */
export const addCouponRequest = (payload) => {
  return query({
    uri: 'api/v1/admin/coupons',
    auth: true,
    method: 'POST',
    data: payload
  });
};
