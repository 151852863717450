import { memo, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextField,
  Slider
} from '@mui/material';
//
import Scrollbar from '../Scrollbar';
import ColorManyPicker from '../ColorManyPicker';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { debounce } from 'lodash';
import { getCategoriesRequest } from 'src/services/categories/getCategoriesRequest.service';

// ----------------------------------------------------------------------

export const CAROUSEL_TAGS = [
  { value: 'New product', label: 'New product' },
  { value: 'Personalizable product', label: 'Personalizable product' },
  { value: 'Best selling product', label: 'Best selling product' },
  { value: 'Our suggestion', label: 'Our suggestion' }
];

export const FILTER_STATUS_OPTIONS = ['draft', 'published', 'rejected'];

// ----------------------------------------------------------------------

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;

const ProductsFilterSidebar = memo(
  ({
    isOpenFilter,
    onResetFilter,
    onOpenFilter,
    onCloseFilter,
    filters,
    onFilterChange,
    categoryAttributes
  }) => {
    const [value, setValue] = useState([0, 1000000000]);
    const [categories, setCategories] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleChange = (event, newValue, activeThumb) => {
      if (!Array.isArray(newValue)) {
        return;
      }
      onFilterChange({
        ...filters,
        search: null,
        price_gt: Math.min(newValue[0], value[1] - minDistance),
        price_lt: value[1]
      });

      if (activeThumb === 0) {
        setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
      } else {
        setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
      }
    };

    const getCategories = () => {
      getCategoriesRequest({ limit: 100 })
        .then(({ data }) => {
          setCategories(data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      getCategories();
    }, []);

    return (
      <>
        <Button
          disableRipple
          color="inherit"
          endIcon={<Icon icon="ic:round-filter-list" />}
          onClick={onOpenFilter}
        >
          Filters&nbsp;
        </Button>

        <Drawer
          anchor="right"
          open={isOpenFilter}
          onClose={onCloseFilter}
          PaperProps={{
            sx: { width: 280, border: 'none', overflow: 'hidden' }
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 1, py: 2 }}
          >
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              Filters
            </Typography>
            <IconButton onClick={onCloseFilter}>
              <Icon icon="eva:close-fill" width={20} height={20} />
            </IconButton>
          </Stack>

          <Divider />

          <Scrollbar>
            <Stack spacing={3} sx={{ p: 3 }}>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Price
                </Typography>
                {categoryAttributes?.filterable_attributes?.map((filter) => {
                  if (filter.filter_type === 'range') {
                    return (
                      <FormGroup>
                        <Slider
                          getAriaLabel={() => 'Minimum distance'}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                          disableSwap
                          min={Math.round(filter.values[0])}
                          max={Math.round(filter.values[1])}
                        />
                      </FormGroup>
                    );
                  }
                })}
              </div>
              <div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={({ target }) => {
                          const { checked } = target;
                          setEnabled(checked);
                          onFilterChange({
                            ...filters,
                            is_enable: disabled ? (checked ? null : false) : checked ? true : null
                          });
                        }}
                      />
                    }
                     label="Enabled products"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={({ target }) => {
                          const { checked } = target;
                          setDisabled(checked);
                          onFilterChange({
                            ...filters,
                            is_enable: enabled ? (checked ? null : true) : checked ? false : null
                          });
                        }}
                      />
                    }
                    label="Disabled products"
                  />
                </FormGroup>
              </div>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Status
                </Typography>
                <FormGroup>
                  {FILTER_STATUS_OPTIONS.map((item) => (
                    <FormControlLabel
                      key={item}
                      control={
                        <Checkbox
                          checked={filters.status?.includes(item)}
                          onChange={({ target }) => {
                            const { checked } = target;
                            if (checked) {
                              onFilterChange({
                                ...filters,
                                status: [...filters.status, item]
                              });
                            } else {
                              onFilterChange({
                                ...filters,
                                status: filters.status.filter((status) => status !== item)
                              });
                            }
                          }}
                          value={item}
                        />
                      }
                      label={item}
                    />
                  ))}
                </FormGroup>
              </div>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Carousel Tags
                </Typography>
                <FormGroup>
                  {CAROUSEL_TAGS.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={filters.carousel_tags?.includes(item.value)}
                          onChange={({ target }) => {
                            const { checked } = target;
                            if (checked) {
                              onFilterChange({
                                ...filters,
                                carousel_tags: [...(filters.carousel_tags ?? []), item.value]
                              });
                            } else {
                              onFilterChange({
                                ...filters,
                                carousel_tags: filters.carousel_tags.filter(
                                  (carousel_tags) => carousel_tags !== item.value
                                )
                              });
                            }
                          }}
                          value={item.value}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </FormGroup>
              </div>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Categories
                </Typography>
                <FormGroup>
                  {categories?.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={filters.cat_ids?.includes(item.id)}
                          onChange={({ target }) => {
                            const { checked } = target;
                            if (checked) {
                              onFilterChange({
                                ...filters,
                                cat_ids: [...filters.cat_ids, item.id]
                              });
                            } else {
                              onFilterChange({
                                ...filters,
                                cat_ids: filters.cat_ids.filter((ids) => ids !== item.id)
                              });
                            }
                          }}
                          value={item.title}
                        />
                      }
                      label={item.title}
                    />
                  ))}
                </FormGroup>
              </div>
            </Stack>
          </Scrollbar>

          <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={onResetFilter}
              startIcon={<Icon icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box>
        </Drawer>
      </>
    );
  }
);

export default ProductsFilterSidebar;

ProductsFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object
};
