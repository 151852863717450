import { useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography, Tooltip, IconButton } from '@mui/material';
// layouts
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { useEffect, useState } from 'react';
import EditSeoForm from 'src/components/SEO/EditSeoForm';
import { ArrowBack } from '@mui/icons-material';
import { getSingleSeoRequest } from 'src/services/SEO/getSingleRequest';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function EditSEO() {
  const navigate = useNavigate();
  const { seo_id: discountId } = useParams();
  const [discount, setDiscount] = useState(null);

  const getDiscount = () => {
    getSingleSeoRequest(discountId).then(({ data }) => {
      setDiscount(data);
      console.log('discount', discount);
    });
  };

  useEffect(() => {
    console.log(discountId);
    getDiscount();
  }, []);

  return (
    <RootStyle title="Edit SEO">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Let's edit your SEO
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Stack alignItems="start" sx={{ mb: 5 }}>
            <Tooltip placement="top" title="Go back">
              <IconButton onClick={() => navigate('/seo')}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h4" gutterBottom>
              Here you can edit your SEO
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <EditSeoForm discount={discount} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
