import { createContext, useContext, useState } from 'react';
import { getConfigsRequest } from 'src/services/configs/getConfigsRequest.service';

const ConfigContext = createContext({
  config: {},
  getConfig: () => {}
});

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState();

  const getConfig = () => {
    getConfigsRequest()
      .then(({ data }) => {
        setConfig(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const value = {
    config,
    getConfig
  };

  return <ConfigContext.Provider value={value} children={children} />;
};

export const useConfig = () => useContext(ConfigContext);
