import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { debounce, filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
//
import USERLIST from '../_mocks_/user';
import { apiUrl, tableConfigs } from 'src/configs';
import { getPromotionsRequest } from 'src/services/promotions/getPromotionsRequest.service';
import { bulkDeletePromotionsRequest } from 'src/services/promotions/bulkDeletePromotionsRequest.service';
import { PromotionsMoreMenu } from 'src/components/Promotions';
import {
  ProductsListHead,
  ProductsListToolbar,
  ProductsMoreMenu
} from '../components/_dashboard/products';
import PromotionsFilterSidebar from 'src/components/Promotions/PromotionsFilterSidebar';
import Loader from 'src/components/loader/Loader';
import { loaderStyle } from './Products';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'enable', label: 'Enable', alignRight: false },
  { id: '', label: '', alignRight: false }
];

// ----------------------------------------------------------------------

export default function Promotions() {
  const emptyRows = 5 > 0 ? Math.max(0, (1 + 5) * 5 - USERLIST.length) : 0;
  const [selectedItems, setSelectedItems] = useState([]);
  const [promotions, setPromotions] = useState({
    data: [],
    total: 0
  });

  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [params, setParams] = useState({
    limit: tableConfigs.defaultPageSize,
    offset: 0,
    promotion_types: null
  });

  const getPromotions = () => {
    setLoading(true);

    getPromotionsRequest(params).then(({ data }) => {
      setLoading(false);
      setPromotions({
        data: data.result,
        total: data.total
      });
    });
  };

  useEffect(() => {
    getPromotions();
  }, [params]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleFilterChange = debounce((newParams = params) => {
    setParams({ ...newParams });
  }, 300);

  const handleSelectItem = (event, promotionId) => {
    const { checked } = event.target;
    setSelectedItems(
      checked
        ? [...selectedItems, promotionId]
        : selectedItems.filter((item) => item !== promotionId)
    );
  };

  const handleSelectAllItems = (event) => {
    if (event.target.checked) {
      const allItems = promotions.data.map((p) => p.id);
      setSelectedItems(allItems);
      return;
    }
    setSelectedItems([]);
  };

  const deletePromotionsHandler = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Once deleted, you will not be able to recover ${
        selectedItems.length === 1 ? 'this promotion' : 'these promotions'
      }!`,
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: `${selectedItems.length === 1 ? 'Yes, delete it!' : 'Yes, delete theme!'}`
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        const PAYLOAD = {
          ids: selectedItems
        };
        bulkDeletePromotionsRequest(PAYLOAD)
          .then(() => {
            setSelectedItems([]);
            toast.success(
              `Poof! Your ${
                selectedItems.length === 1 ? 'promotion has' : 'promotions have'
              } been deleted!`,
              { duration: 4000 }
            );
            getPromotions();
          })
          .catch(() => {
            toast.error('Something went wrong', { duration: 4000 });
          });
      }
    });
  };

  return (
    <Page title="Homepage banners">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Homepage banners
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/promotions/add"
            startIcon={<Icon icon={plusFill} />}
          >
            Add banner
          </Button>
        </Stack>

        <Card>
          {selectedItems.length === 0 && (
            <Stack
              direction="row"
              flexWrap="wrap-reverse"
              alignItems="center"
              justifyContent="end"
              sx={{ my: 3, mx: 3.3 }}
            >
              <PromotionsFilterSidebar
                filters={params}
                onFilterChange={handleFilterChange}
                isOpenFilter={openFilter}
                onResetFilter={() => {
                  handleFilterChange({
                    ...params,
                    promotion_types: null
                  });
                }}
                onOpenFilter={handleOpenFilter}
                onCloseFilter={handleCloseFilter}
              />
            </Stack>
          )}

          {selectedItems.length > 0 && (
            <ProductsListToolbar
              filterName=""
              onFilterName={() => {}}
              numSelected={selectedItems.length}
              deleteSelectedItems={deletePromotionsHandler}
            />
          )}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductsListHead
                  order={'name'}
                  orderBy={'name'}
                  headLabel={TABLE_HEAD}
                  rowCount={promotions.data.length}
                  numSelected={selectedItems.length}
                  onRequestSort={() => {}}
                  onSelectAllClick={handleSelectAllItems}
                />
                <TableBody>
                  {loading && (
                    <div style={loaderStyle}>
                      <Loader />
                    </div>
                  )}
                  {promotions.data.map((row) => {
                    const { id, title, image_url, promotion_type, is_enable,Type } = row;
                    const isItemSelected = selectedItems.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                      >
                        <TableCell align="left">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(e) => handleSelectItem?.(e, id)}
                          />
                        </TableCell>
                        <TableCell align="center" component="th" scope="row" padding="1rem">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={title ?? ''} src={image_url} />
                          </Stack>
                        </TableCell>
                        <TableCell align="center">{title ?? ''}</TableCell>
                        <TableCell align="center">{Type ?? ''}</TableCell>
                        <TableCell align="center">
                          <Label
                            style={{ padding: '0' }}
                            variant="ghost"
                            color={is_enable ? 'success' : 'error'}
                          >
                            {is_enable ? (
                              <Icon icon="akar-icons:chat-approve" width={24} height={24} />
                            ) : (
                              <Icon icon="fe:disabled" width={24} height={24} />
                            )}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <PromotionsMoreMenu
                            onGetPromotions={getPromotions}
                            promotionId={id}
                            setLoading={setLoading}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {promotions.total === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={params?.search ?? ''} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            SelectProps={{
              disabled: loading
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            rowsPerPageOptions={tableConfigs.pageSizeOptions
              .filter((item) => item <= promotions.total)
              .concat(promotions.total)}
            component="div"
            count={promotions.total}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(_, selected) => {
              setParams({
                ...params,
                offset: selected * params.limit
              });
            }}
            onRowsPerPageChange={({ target }) => {
              const selected = target.value;
              setParams({ ...params, limit: selected });
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}
