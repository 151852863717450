import { query } from 'src/utils/query';

/** Fetch single order from database */
export const getOrderRequest = (orderId) => {
  return query({
    uri: 'api/v1/admin/orders/' + orderId + '/',
    auth: true,
    method: 'GET'
  });
};
