import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { debounce, filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { ProductsListHead, ProductsListToolbar } from '../components/_dashboard/products';
//
import USERLIST from '../_mocks_/user';
import NewsMenu from 'src/components/news/Menu';
import { getBlogsRequest } from 'src/services/blogs/getBlogsRequest.service';
import { bulkDeleteBlogsRequest } from 'src/services/blogs/bulkDeleteBlogsRequest.service';
import BlogsMoreMenu from 'src/components/blog/Menu';
import { fDate } from 'src/utils/formatTime';
import SearchBar from 'src/components/_dashboard/blog/BlogPostsSearch';
import BlogsFilterSidebar from 'src/components/blog/BlogsFilterSidebar';
import Loader from 'src/components/loader/Loader';
import { tableConfigs } from 'src/configs';
import { loaderStyle } from './Products';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'enable', label: 'Enable', alignRight: false },
  { id: 'create-datetime', label: 'Wrote at', alignRight: false },
  { id: '', label: '', alignRight: false }
];

// ----------------------------------------------------------------------

export default function Blogs() {
  const emptyRows = 5 > 0 ? Math.max(0, (1 + 5) * 5 - USERLIST.length) : 0;
  const [selectedItems, setSelectedItems] = useState([]);
  const [blogs, setBlogs] = useState({
    data: [],
    total: 0
  });

  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [params, setParams] = useState({
    limit: tableConfigs.defaultPageSize,
    search: null,
    offset: 0
  });

  const getBlogs = () => {
    setLoading(true);

    getBlogsRequest(params).then(({ data }) => {
      setBlogs({
        data: data.result,
        total: data.total
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    getBlogs();
  }, [params]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleFilterChange = debounce((newParams = params) => {
    setParams({ ...newParams });
  }, 300);

  const handleSelectItem = (event, blogId) => {
    const { checked } = event.target;
    setSelectedItems(
      checked ? [...selectedItems, blogId] : selectedItems.filter((item) => item !== blogId)
    );
  };

  const handleSelectAllItems = (event) => {
    if (event.target.checked) {
      const allItems = blogs.data.map((b) => b.id);
      setSelectedItems(allItems);
      return;
    }
    setSelectedItems([]);
  };

  const deleteBlogsHandler = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Once deleted, you will not be able to recover ${
        selectedItems.length === 1 ? 'this blog' : 'these blogs'
      }!`,
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: `${selectedItems.length === 1 ? 'Yes, delete it!' : 'Yes, delete theme!'}`
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        const PAYLOAD = {
          ids: selectedItems
        };
        bulkDeleteBlogsRequest(PAYLOAD)
          .then(() => {
            setSelectedItems([]);
            toast.success(
              `Poof! Your ${selectedItems.length === 1 ? 'blog has' : 'blogs have'} been deleted!`,
              { duration: 4000 }
            );
            getBlogs();
          })
          .catch(() => {
            toast.error('Something went wrong', { duration: 4000 });
          });
      }
    });
  };

  return (
    <Page title="Blogs">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Blogs
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/blogs/add"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Blog Post
          </Button>
        </Stack>
        <Card>
          {selectedItems.length === 0 && (
            <Stack
              direction="row"
              flexWrap="wrap-reverse"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 3, mx: 3.3 }}
            >
              <SearchBar filters={params} onFilterChange={handleFilterChange} isLoading={loading} />
              <BlogsFilterSidebar
                filters={params}
                onFilterChange={handleFilterChange}
                isOpenFilter={openFilter}
                onResetFilter={() => {
                  handleFilterChange({
                    ...params,
                    search: null
                  });
                }}
                onOpenFilter={handleOpenFilter}
                onCloseFilter={handleCloseFilter}
              />
            </Stack>
          )}

          {selectedItems.length > 0 && (
            <ProductsListToolbar
              filterName=""
              onFilterName={() => {}}
              numSelected={selectedItems.length}
              deleteSelectedItems={deleteBlogsHandler}
            />
          )}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductsListHead
                  order={'name'}
                  orderBy={'name'}
                  headLabel={TABLE_HEAD}
                  rowCount={blogs.data.length}
                  numSelected={selectedItems.length}
                  onRequestSort={() => {}}
                  onSelectAllClick={handleSelectAllItems}
                />
                <TableBody>
                  {loading && (
                    <div style={loaderStyle}>
                      <Loader />
                    </div>
                  )}
                  {blogs.data.map((row) => {
                    const { id, title, image_url, is_enable, create_datetime } = row;
                    const isItemSelected = selectedItems.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                      >
                        <TableCell align="left">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(e) => handleSelectItem?.(e, id)}
                          />
                        </TableCell>
                        <TableCell align="center" component="th" scope="row" padding="1rem">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={title ?? ''} src={image_url} />
                          </Stack>
                        </TableCell>
                        <TableCell align="center">{title ?? ''}</TableCell>
                        <TableCell align="center">
                          <Label
                            style={{ padding: '0' }}
                            variant="ghost"
                            color={is_enable ? 'success' : 'error'}
                          >
                            {is_enable ? (
                              <Icon icon="akar-icons:chat-approve" width={24} height={24} />
                            ) : (
                              <Icon icon="fe:disabled" width={24} height={24} />
                            )}
                          </Label>
                        </TableCell>
                        <TableCell align="center">{fDate(create_datetime)}</TableCell>

                        <TableCell align="right">
                          <BlogsMoreMenu
                            onGetBlogs={getBlogs}
                            BlogId={id}
                            setLoading={setLoading}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {blogs.total === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={params?.search ?? ''} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            SelectProps={{
              disabled: loading
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            rowsPerPageOptions={tableConfigs.pageSizeOptions
              .filter((item) => item <= blogs.total)
              .concat(blogs.total)}
            component="div"
            count={blogs.total}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(_, selected) => {
              setParams({
                ...params,
                offset: selected * params.limit
              });
            }}
            onRowsPerPageChange={({ target }) => {
              const selected = target.value;
              setParams({ ...params, limit: selected });
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}
