import { query } from 'src/utils/query';

/** Fetch coupons product from database */
export const getCouponsRequest = (params) => {
  return query({
    uri: 'api/v1/admin/coupons',
    auth: true,
    method: 'GET',
    params
  });
};
