import { query } from 'src/utils/query';

/**
 * 
 * @param {
  "title": {
    "additionalProp1": "string",
    "additionalProp2": "string",
    "additionalProp3": "string"
  },
  "file_id": "string",
  "parent": "string",
  "order": 0,
  "attributes": [
    {
      "title": {
        "additionalProp1": "string",
        "additionalProp2": "string",
        "additionalProp3": "string"
      },
      "attribute_key": "string",
      "filter_type": "range",
      "values": [
        "string"
      ],
      "show_filter": true,
      "is_mandatory": true
    }
  ],
  "is_basket_limited": true,
  "is_enable": true
} 
 */

/** Fetch a single category from database */
export const getCategoryRequest = (categoryId) => {
  return query({
    uri: 'api/v1/admin/categories/' + categoryId + '/',
    auth: true,
    method: 'GET'
  });
};
