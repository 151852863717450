import { query } from 'src/utils/query';

/** Fetch categories from database */
export const getCategoriesRequest = (params) => {
  return query({
    uri: 'api/v1/admin/categories',
    auth: true,
    method: 'GET',
    params
  });
};
