// @ts-ignore
import SimpleCrypto from 'simple-crypto-js';

const Crypto = new SimpleCrypto(
  'EAB8r*H!@M#k8swB6YT-D=J_xMU2S8F5?5beLQWXgj=vn_g7RXkY$2mmQNDxEKCUnq+Z*NFRYkP^S&Pxn_%b*&FZs9!azWP&KjH-seE84S57U_?sVmP*rq=^S_&!vhur'
);

export const encrypt = (str) => {
  try {
    return Crypto.encrypt(str);
  } catch (e) {
    return null;
  }
};

export const decrypt = (str) => {
  try {
    return Crypto.decrypt(str);
  } catch (e) {
    return null;
  }
};
