import { query } from 'src/utils/query';

import qs from 'qs';

/** Fetch orders from database */
export const getOrdersRequest = (params) => {
  return query({
    uri: 'api/v1/admin/orders',
    auth: true,
    method: 'GET',
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
  });
};
