import { query } from 'src/utils/query';

export const editOrderRequest = (orderId, payload) => {
  return query({
    uri: `api/v1/admin/orders/${orderId}/`,
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
