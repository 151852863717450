import * as Yup from 'yup';
import { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';

// material
import {
    Stack,
    TextField,
    Button, MenuItem, createFilterOptions
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { createPromotionRequest } from 'src/services/promotions/createPromotionRequest.service';
import Swal from 'sweetalert2';
import { getTagsRequest } from 'src/services/tags/getTagsRequest.service';

const filter = createFilterOptions();

// ----------------------------------------------------------------------
const formikValues = {
    title: '',
    text: '',
    summary_text: '',
    Type: '',// "Top" | "Bottom" |Trending
    tag: ''


};

const addNewPromotionSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    text: Yup.string().when(['Type'], {
        is: (Type) => Type === 'Trending',
        then: Yup.string().required('Promotion link is required')
    }),
    summary_text: Yup.string().when(['Type'], {
        is: (Type) => Type === 'Top' || Type === 'Bottom',
        then: Yup.string().required('Summary text is required')
    }),
    Type: Yup.string().oneOf(['Top', 'Bottom', 'Trending']).required('Type is required'),
    
    tag: Yup.string().when(['Type'], {
        is: (Type) => Type === 'Top' || Type === 'Bottom',
        then: Yup.string().required('Tag is required')
    }),
});

export default memo(function AddPromotionForm() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState('');
    const [video, setVideo] = useState('');
    const [tags, setTags] = useState([]);

    const getTags = () => {
        getTagsRequest({ limit: 100 })
            .then(({ data }) => {
                setTags(data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getTags()
    }, [])
    const handleAddPromotion = (values) => {
        console.log(values);
        const PAYLOAD = {
            image_url: image,
            title: values.title,
            text: "",
            summary_text: values.summary_text,
            Type: values.Type,
            tag: values.tag

        };
        if (!image) {
            toast.error('Image file required', { duration: 2000 });
            return;
        }
        setLoading(true);
        createPromotionRequest(PAYLOAD)
            .then((res) => {
                toast.success('Promotion added successfully', { duration: 3000 });
                navigate('/promotions');
            })
            .catch(({ data }) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: data?.message || 'something went wrong'
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const formik = useFormik({
        initialValues: formikValues,
        validationSchema: addNewPromotionSchema,
        onSubmit: handleAddPromotion
    });

    const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

    const handleUploadPromotionImage = (event) => {
        const file = event.target.files[0];

        setLoading(true);

        uploadFileRequest(file)
            .then(({ data }) => {
                setImage(data.file_url);
            })
            .catch((err) => {
                console.trace(err);
            })
            .finally(() => setLoading(false));
    };

    const handleUploadPromotionVideo = (event) => {
        const file = event.target.files[0];

        setLoading(true);

        uploadFileRequest(file)
            .then(({ data }) => {
                setVideo(data.file_url);
            })
            .catch((err) => {
                console.trace(err);
            })
            .finally(() => setLoading(false));
    };

    return (
        <FormikProvider value={formik}>
            <Toaster position="bottom-center" reverseOrder={false} />
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack direction={{ xs: 'column', lg: 'row' }} spacing={3}>
                    <TextField
                        {...getFieldProps('title')}
                        fullWidth
                        autoComplete="title"
                        type="text"
                        label="Promotion Title *"
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                    />

                    <TextField
                        {...getFieldProps('Type')}
                        fullWidth
                        label="Type *"
                        error={Boolean(touched.Type && errors.Type)}
                        helperText={touched.Type && errors.Type}
                        select
                    >
                        <MenuItem value="Top">Top</MenuItem>
                        <MenuItem value="Bottom">Bottom</MenuItem>
                        <MenuItem value="Trending">Trending</MenuItem>

                    </TextField>

                    {(values?.Type === "Top" || values?.Type === "Bottom") && <TextField
                        {...getFieldProps('tag')}
                        fullWidth
                        label="Tag *"
                        error={Boolean(touched.tag && errors.tag)}
                        helperText={touched.tag && errors.tag}
                        select
                    >
                        {tags.map((x) => (
                            <MenuItem value={x?.title}>{x?.title}</MenuItem>
                        ))}
                    </TextField>}
                </Stack>
                {(values?.Type === "Top" || values?.Type === "Bottom") && <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
                    <TextField
                        {...getFieldProps('summary_text')}
                        focused={values?.summary_text}
                        fullWidth
                        inputProps={{
                            style: {
                                height: '50px'
                            }
                        }}
                        autoComplete="text"
                        multiline
                        rows={2}
                        maxRows={3}
                        label="Promotion summary text *"
                        error={Boolean(touched.summary_text && errors.summary_text)}
                        helperText={touched.summary_text && errors.summary_text}
                    />
                </Stack>}
                {values?.Type === "Trending" && <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
                    <TextField
                        {...getFieldProps('text')}
                        focused={values?.text}
                        fullWidth
                        inputProps={{
                            style: {
                                minHeight: '80px'
                            }
                        }}
                        autoComplete="text"
                        multiline
                        rows={3}
                        minRows={3}
                        // label={(values?.Type === "Top" || values?.Type === "Bottom") ? "Promotion link *" : "Promotion description *"}
                        label="Promotion link *"
                        error={Boolean(touched.text && errors.text)}
                        helperText={touched.text && errors.text}
                    />
                </Stack>}
                <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
                    <label style={{ margin: '0', width: '100%' }} htmlFor="image_urls">
                        <input
                            onChange={handleUploadPromotionImage}
                            accept="image/*"
                            style={{ display: 'none' }}
                            multiple
                            id="image_urls"
                            name="image_urls"
                            type="file"
                        />
                        <Button fullWidth variant="contained" component="span">
                            Upload promotion image
                        </Button>
                    </label>
                    {image && (
                        <img
                            style={{
                                margin: '1rem auto 0',
                                width: '100%',
                                maxHeight: '30rem',
                                objectFit: 'cover'
                            }}
                            src={image}
                            alt="promotion image"
                        />
                    )}
                </Stack>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    loading={loading}
                    className='save-btn'

                >
                    Save
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
});
