import { query } from 'src/utils/query';

export const updateSingleSocialRequest = (payload, socialId) => {
  return query({
    uri: 'api/v1/admin/socials/' + socialId + '/',
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
