import { query } from '../../utils/query';
/**
 * @param {String} title
 * @param {String} text
 * @param {String} image_url
 * @param {String} read_time
 */
export const addNewsRequest = (payload = {}) => {
  return query({
    uri: 'api/v1/admin/news',
    method: 'POST',
    data: payload
  });
};
