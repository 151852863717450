import { query } from 'src/utils/query';

/** update order status from database
 *
 * available status:
 *
 * In process, Delivered, Cancelled,
 * Returned, Send for personalization,
 * Personalization approve, Send for Delivery
 *
 * @param {object} payload the request body
 */
export const updateOrderStatusRequest = (payload = {}, orderId) => {
  return query({
    uri: 'api/v1/admin/orders/' + orderId + '/change-status',
    method: 'PATCH',
    auth: true,
    data: payload
  });
};
