import { decrypt, encrypt } from './encrypt';

export const db = {
  /** Save an item in local storage */
  set: (key, value) => localStorage.setItem(key, encrypt(value)),

  /** Delete an item from local storage */
  delete: (key) => localStorage.removeItem(key),

  /** Get an item from local storage */
  get: (key) => decrypt(localStorage.getItem(key))
};
