import { query } from '../../utils/query';

import qs from 'qs';

export const getBlogsRequest = (params = {}) => {
  return query({
    uri: 'api/v1/admin/blog',
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
  });
};
