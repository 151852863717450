import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';

// material
import { visuallyHidden } from '@mui/utils';
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func
};

export default function UserListHead({
  order,
  params,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick
}) {
  const location = useLocation();
  const { pathname } = location;
  const notAllowedCheckbox = ['/club', '/orders', '/contact-us'];

  const createSortHandler = (value) => (event) => {
    onRequestSort({
      sort_by: params.sort_by?.includes('desc') ? `asc(${value})` : `desc(${value})`
    });
  };

  return (
    <TableHead 
    style={{backgroundColor: ( "#E202461A" )}}
    >
      <TableRow>
        {(!notAllowedCheckbox.includes(pathname) && !pathname.includes('/customers')) && (
          <TableCell >
            <Checkbox
            style={{color:"#E20246"}}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableCell
          style={{color:"#E20246"}}

            key={headCell.id}
            align="center"
            sortDirection={params?.sort_by?.includes('asc') ? 'asc' : 'desc'}
          >
            <TableSortLabel
              style={{color:"#E20246"}}

              disabled={!headCell.sortable}
              hideSortIcon={!headCell.sortable}
              active={params?.sort_by?.includes(headCell.id) && headCell.sortable}
              direction={params?.sort_by?.includes('asc') ? 'asc' : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
