export const replaceUnderscoreWithSpace = (string) =>
  string.replaceAll('_', ' ').charAt(0).toUpperCase() + string.replaceAll('_', ' ').slice(1);

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getUID = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const uniqify = (array, key) =>
  array.reduce(
    (prev, curr) => (prev.find((a) => a[key] === curr[key]) ? prev : prev.push(curr) && prev),
    []
  );

export const isHTML = (str) => /<b>(.*?)<\/b>/g.test(str);
