import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import DateAdapter from '@mui/lab/AdapterDateFns';

// material
import { Stack, TextField, Button } from '@mui/material';
import { LoadingButton, LocalizationProvider } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { addTagRequest } from 'src/services/tags/addTagRequest.service';
import Swal from 'sweetalert2';

const initialValues = {
  title: '',
  text: ''
};

const addTagSchema = Yup.object().shape({
  title: Yup.string().required('Title is required')
});

export default function AddTagForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  const handleAddTag = (values) => {
    const PAYLOAD = {
      ...values,
      image_url: image
    };

    if (!image) {
      toast.error('Image is required');
      return;
    }
    setLoading(true);

    addTagRequest(PAYLOAD)
      .then((res) => {
        toast.success('Tag added successfully', { duration: 4000 });
        navigate('/tags');
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    validationSchema: addTagSchema,
    initialValues: initialValues,
    onSubmit: handleAddTag
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  const handleUploadTagImage = (event) => {
    const file = event.target.files[0];

    setLoading(true);
    uploadFileRequest(file)
      .then(({ data }) => {
        setImage(data.file_url);
      })
      .catch((err) => {
        console.trace(err);
      })
      .finally(() => setLoading(false));
  };
  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              inputProps={{ maxLength: 50 }}
              {...getFieldProps('title')}
              focused={values?.title}
              fullWidth
              autoComplete="title"
              type="text"
              label="Title *"
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
          </Stack>

          <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
            <label style={{ margin: '0', width: '100%' }} htmlFor="overview_urls">
              <input
                onChange={handleUploadTagImage}
                accept="image/*"
                style={{ display: 'none' }}
                id="overview_urls"
                name="overview_urls"
                type="file"
              />
              <Button fullWidth variant="contained" component="span">
                Upload tag image
              </Button>
            </label>
            {image && (
              <img
                style={{ margin: '1rem auto 0', objectFit: 'cover' }}
                width={200}
                src={image}
                alt="tag image"
              />
            )}
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
            className='save-btn'

          >
            Save
          </LoadingButton>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
}
