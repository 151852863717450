import { query } from 'src/utils/query';

/** Fetch a particular product from database */
export const getProductRequest = (productId) => {
  return query({
    uri: 'api/v1/admin/products/' + productId + '/',
    auth: true,
    method: 'GET'
  });
};
