import styled from 'styled-components';

export const PurchaseContainer = styled.div`
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 100;
  height: 100vh;
  overflow-y: auto;

  & .close-icon {
    position: absolute;
    top: 32px;
    right: 70px;
    cursor: pointer;
    z-index: 10;
    & path {
      fill: #000;
    }
  }

  & .export-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    & path {
      fill: #3b3b3b;
    }
  }

  @media print {
    .no-print {
      display: none !important;
    }
  }
`;
