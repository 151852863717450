import React, { useEffect, useState } from 'react';
import { getOrderRequest } from 'src/services/orders/getOrderRequest.service';
import { Wrapper, TableContainer } from './style';

function BillOfPurchase({ orderId }) {
  const [order, setOrder] = useState({});
  // const [counter, setCounter] = useState(0);


  const getOrder = () => {
    getOrderRequest(orderId)
      .then(({ data }) => {
        setOrder(data);
        console.log(data);
      })
      .catch((err) => {
        console.trace(err);
      });
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  console.log(order);

  const convertFormatDate = (input) => {
    const date = new Date(input);
    const month = date.toLocaleString('default', { month: 'long' });
    const newFormat = date.getDate() + ' ' + month + ' ' + date.getFullYear();
    return newFormat;
  };

  const getShippingDate = (from, to) => {
    const fromDate = new Date(from);
    const fromHhours = fromDate.getHours();
    const fromMinutes = fromDate.getMinutes();
    const day = fromDate.getDate();
    const month = fromDate.toLocaleString('default', { month: 'long' });
    const year = fromDate.getFullYear();

    const toDate = new Date(to);
    const toHhours = toDate.getHours();
    const toMinutes = toDate.getMinutes();

    return `${day} ${month} ${year} FROM ${fromHhours}:${fromMinutes} TO ${toHhours}:${toMinutes}`;
  };

  const roundNumber = (number) => {
    const roundedNumber = Number((Math.abs(number) * 100).toPrecision(15));
    return (Math.round(roundedNumber) / 100) * Math.sign(number);
  };

  const { delivery_address: address } = order;

let counter2 = order?.items?.length+1||0;
  return (
    <Wrapper>
      <h2 className="invoice">Invoice</h2>
      <header>
        <div className="logo">
          <img src="/static/icons/chupa-logo.svg" alt="Chupachups Logo" />
          <h2>Chupachups</h2>
        </div>
        <div className="info">
          <p>
            <span>Invoice No: </span> {order?.order_code}
          </p>
          <p>
            <span>Order Date: </span>
            {convertFormatDate(order?.create_datetime)}
          </p>
          <p>
            <span>Shipping Date: </span>
            {getShippingDate(order.delivery_datetime_from, order.delivery_datetime_to)}
          </p>
        </div>
      </header>
      <section className="personal-info">
        <div className="personal-info-left">
          <p>
            <span>Customer name: </span>
            {order?.customer?.first_name} {order?.customer?.last_name}
          </p>
          <p>
            <span>Mobile No: </span>
            {order?.customer?.mobile_number}
          </p>
          <p>
            <span>Email: </span>
            {order?.customer?.email}
          </p>
        </div>
        <div className="personal-info-right">
          <p>
            <span>Ship to: </span>
            {address?.address_line_1}
          </p>
          <p>
            <span>Billing Address: </span>
            {`${address?.address_line_1} / ${address?.town} / ${address?.state} / UAE`}
          </p>
          <p className="delivery">Delivery in 2 working days</p>
        </div>
      </section>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th colSpan={1} className="bg-gray">
                No
              </th>
              <th colSpan={3}>Product Name</th>
              <th colSpan={1}>SKU</th>
              <th colSpan={1} className="bg-gray">
                Quantity
              </th>
              <th colSpan={1}>Unit of Measure</th>
              <th colSpan={1}>Unit Price</th>
              <th colSpan={1}>Price</th>
              <th colSpan={1}>Discount</th>
              <th colSpan={1} className="bg-gray">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {order?.items?.map((item, index) => (
              <tr key={item.id}>
                <td colSpan={1} className="bg-gray No">
                  {index + 1}
                </td>
                <td colSpan={3} className="product-name">
                  {item.title}
                </td>
                <td colSpan={1}>{item.sku}</td>
                <td colSpan={1} className="bg-gray quantity">
                  {item.quantity}
                </td>
                <td colSpan={1}>{item.uom}</td>
                 <td colSpan={1}>
                  AED<span className="price"> {item.price}</span>
                </td>
                <td colSpan={1}>
                  AED <span className="price">{Number(item.price) * Number(item.quantity)}</span>
                </td>
                <td colSpan={1}>
                  AED <span className="price">{item.total_discount?.toFixed(2)}</span>
                </td>
                <td colSpan={1} className="bg-gray">
                  AED <span className="price">{item.total_amount?.toFixed(2)}</span>
                </td>
              </tr>
            ))}
            {order?.PickNmix?.map((products, index1) => (
              products?.items.map((item, index2) =>(
                <tr key={item.id}>
                <td colSpan={1} className="bg-gray No">
                  {counter2++}
                </td>
                <td colSpan={3} className="product-name">
                {products?.Type=="Pick N Mix"?"PNM":"BB"} {index1+1}.{index2+1}- {item.title}
                </td>
                <td colSpan={1}>{item.sku}</td>
                <td colSpan={1} className="bg-gray quantity">
                  {item.quantity}
                </td>
                <td colSpan={1}>{item.uom}</td>
                 <td colSpan={1}>
                  AED<span className="price"> {item.price}</span>
                </td>
                <td colSpan={1}>
                  AED <span className="price">{Number(item.price) * Number(item.quantity)}</span>
                </td>
                <td colSpan={1}>
                  AED <span className="price">{item.total_discount?.toFixed(2)}</span>
                </td>
                <td colSpan={1} className="bg-gray">
                  AED <span className="price">{item.total_amount?.toFixed(2)}</span>
                </td>
              </tr>
              ))
          
            ))}
            <tr>
              <td colSpan={10} className="ta-r bold">
                Gift Wrapping
              </td>
              <td colSpan={1}>
                {order?.order_pack ? (
                  <>
                    AED <span className="price">{order?.order_pack?.price}</span>
                  </>
                ) : (
                  0
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="ta-r bold">
                Coupon Discount
              </td>
              <td colSpan={1}>
                - AED <span className="price">{order?.coupon_discount_amount}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="ta-r not-border-bottom bold">
                Total Amount
              </td>
              <td colSpan={1} className="not-border-bottom">
                AED{' '}
                <span className="price">
                  {(Number(order?.total_price) - Number(order?.shipping))?.toFixed(2)}
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="ta-r not-border-top bold free-delivery">
                Shipping <span>(Free delivery above AED 100)</span>
              </td>
              <td colSpan={1} className="not-border-top">
                {order?.shipping > 0 ? (
                  <>
                    AED <span className="price">{order?.shipping}</span>
                  </>
                ) : (
                  <>Free</>
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="ta-r bold">
                Total
              </td>
              <td colSpan={1}>
                AED <span className="price">{order?.total_price?.toFixed(2)}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="ta-r not-border-bottom bold">
                TOTAL AMOUNT BEFORE VAT
              </td>
              <td colSpan={1} className="not-border-bottom">
                AED <span className="price">{roundNumber(order?.total_amount_before_vat)}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="ta-r not-border-bottom not-border-top bold text-gray">
                VAT
              </td>
              <td colSpan={1} className="not-border-bottom not-border-top">
                AED <span className="price">{roundNumber(order?.vat)}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="ta-r not-border-bottom not-border-top bold text-gray">
                ROUNDED OFF
              </td>
              <td colSpan={1} className="not-border-bottom not-border-top">
                {/* <span className="price">{order?.vat_rate}</span> */}
                <span className="price">0.01</span>
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="ta-r not-border-bottom not-border-top bold text-gray">
                TOTAL AMOUNT
              </td>
              <td colSpan={1} className="not-border-bottom not-border-top">
                <span className="price">
                  {(Number(order?.total_price) - Number(order?.shipping))?.toFixed(2)}
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="ta-r not-border-bottom bold text-gray">
                SHIPPING
              </td>
              <td colSpan={1} className="not-border-bottom">
                {order?.shipping > 0 ? (
                  <>
                    AED <span className="price">{order?.shipping}</span>
                  </>
                ) : (
                  <>Free</>
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="ta-r not-border-top bold text-gray">
                TOTAL
              </td>
              <td colSpan={1} className="not-border-top">
                AED <span className="price">{order?.total_price?.toFixed(2)}</span>
              </td>
            </tr>
          </tbody>
      *PNM-Pick N Mix
      *BB-Birthday Bag
        </table>
      </TableContainer>
    </Wrapper>
  );
}

export default BillOfPurchase;
