import { query } from 'src/utils/query';

export const updateSEORequest = (payload) => {
  return query({
    uri: 'api/v1/admin/seo',
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
