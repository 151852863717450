import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Tooltip, IconButton } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
import { updateProductRequest } from 'src/services/products/updateProductRequest.service';
import { useEffect, useState } from 'react';
import EditCategoryForm from 'src/components/category/EditCategoryForm';
import { getCategoryRequest } from 'src/services/categories/getCategoryRequest.service';
import AddCategoryForm from 'src/components/category/AddCategoryForm';
import AddTestimonialForm from 'src/components/testimonial/AddTestimonialForm';
import AddNewsForm from 'src/components/news/AddNewsForm';
import { getSingleNewsRequest } from 'src/services/news/getSingleNewsRequest.service';
import EditNewsForm from 'src/components/news/editNewsForm';
import { useUser } from '../providers/user';
import EditProfileForm from 'src/components/profile/editProfileForm';
import { db } from 'src/utils/db';
import { ArrowBack } from '@mui/icons-material';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function EditProfile() {
  const user = useUser();

  const navigate = useNavigate();

  const isLogin = db.get('tokens');

  useEffect(() => {
    if (isLogin && !user.email) user.getUser();
  }, []);

  return (
    <RootStyle title="Edit Profile">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Let's edit your profile
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="profile" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Stack alignItems="start" sx={{ mb: 5 }}>
            <Tooltip placement="top" title="Go home">
              <IconButton onClick={() => navigate('/')}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h4" gutterBottom>
              Here you can edit your profile
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <EditProfileForm user={user} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
