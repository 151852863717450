const Loader = ({ blurMode }) => {
  return (
    <>
      <div className="wrapper">
        <div class="loader" />
      </div>

      <style jsx>
        {`
          .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
            filter: blur(${blurMode ? '10px' : '0'});
          }

          .loader {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            z-index: 10000;
          }

          .loader:after {
            content: ' ';
            display: block;
            border-radius: 50%;
            width: 0;
            height: 0;
            margin: 8px;
            box-sizing: border-box;
            border: 32px solid #E20246 ;
            border-color: #E20246 transparent #E20246 transparent;
            animation: loader 1.2s infinite;
          }
          @keyframes loader {
            0% {
              transform: rotate(0);
              animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
            50% {
              transform: rotate(900deg);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            100% {
              transform: rotate(1800deg);
            }
          }
        `}
      </style>
    </>
  );
};

export default Loader;
