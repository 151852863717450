import { Box, Button, Grid, IconButton, Modal, Stack, Typography } from '@mui/material';

import { memo, useEffect, useState } from 'react';
import { getOrderRequest } from 'src/services/orders/getOrderRequest.service';
import { Icon } from '@iconify/react';
import Loader from '../loader/Loader';
import Label from '../Label';
import { Footer } from './SingleOrderModalStyle';
import { PurchasedItemCard } from './PurchasedItemCard';
import { PurchasedPickItemCard } from './PickNMIXProduct';

import { cloneDeep, uniq } from 'lodash';
import { editOrderRequest } from 'src/services/orders/editOrderRequest.service';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { fDate } from 'src/utils/formatTime';

const style = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4,
  overflow: 'auto',
};


const buttonStyles = {
  float: 'right',
  marginRight: '10px'
};

const SingleOrderModal = ({ orderId, onClose, isOpen, type = 'view' }) => {
  const [order, setOrder] = useState({});
  const [updatedOrder, setUpdatedOrder] = useState({});
  const [history, setHistory] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const getOrder = () => {
    getOrderRequest(orderId)
      .then(({ data }) => {
        setOrder(data);
        setUpdatedOrder(cloneDeep(data));
      })
      .catch((err) => {
        console.trace(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  const updateOrderItemQuantity = (itemId, newQuantity) => {
    const newOrder = cloneDeep(updatedOrder);
    newOrder.items.forEach((item) => {
      if (item.product_id === itemId) {
        const isItTheLastItem = newOrder.items.length === 1 && newQuantity === 0;
        if (isItTheLastItem) {
          Swal.fire({
            title: 'Error',
            text: 'You cannot remove the last item from the order',
            icon: 'error'
          });

          return;
        }
        if (newQuantity === 0) {
          // delte item if quantity is 0
          newOrder.items = newOrder.items.filter((i) => i.product_id !== itemId);
          setHistory([...history, item]);
          return;
        }

        const originalOrderItem = order.items.find((i) => i.product_id === itemId);
        if (newQuantity > originalOrderItem.quantity || newQuantity < 0) return item;
        item.quantity = newQuantity;
      }
    });
    setUpdatedOrder(newOrder);
  };

  const handleUpdateOrder = () => {
    const PAYLOAD = {
      ...updatedOrder
    };

    editOrderRequest(order.id, PAYLOAD)
      .then(() => {
        onClose();
        toast.success('Order updated successfully', { duration: 4000 });
      })
      .catch(({ data }) => {
        onClose();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      });
  };

  const undoDeletion = () => {
    const newOrder = cloneDeep(order);
    newOrder.items = uniq(newOrder.items);

    setOrder(newOrder);

    setUpdatedOrder({ ...updatedOrder, items: newOrder.items });
    setHistory([]);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      BackdropProps={{
        timeout: 500
      }}
    >
      <Box sx={style}>
        {type === 'view' && (
          <Stack flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Typography style={{ filter: `blur(${isLoading ? '2px' : '0'})` }} variant='h6'>
              Customer & order information:
            </Typography>
            <IconButton onClick={onClose} color='default'>
              <Icon width={30} height={30} icon='ep:circle-close' />
            </IconButton>
          </Stack>
        )}
        <Box style={{ filter: `blur(${isLoading ? '2px' : '0'})`, position: 'relative' }}>
          {type === 'view' && (
            <>
              {isLoading && <Loader />}
              <Grid spacing={2} gridColumn={2} mt={1} container>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Coupon:</strong> {order?.coupon_code ?? <Label>Not provided</Label>}
                  </Typography>
                </Grid>
                {order?.reason && (
                  <Grid item lg={6}>
                    <Typography variant='subtitle1'>
                      <strong>Return/Cancelation reason:</strong>{' '}
                      {order?.reason ?? <Label>Not provided</Label>}
                    </Typography>
                  </Grid>
                )}
                {order?.order_pack && (
                  <Grid item lg={6}>
                    <Typography variant='subtitle1'>
                      <strong> Gift wrapping pack name:</strong>{' '}
                      {order?.order_pack?.name ?? <Label>Not provided</Label>}
                    </Typography>
                  </Grid>
                )}

                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong>Email:</strong> {order?.customer?.email ?? <Label>Not provided</Label>}
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Gift wrapping text:</strong>{' '}
                    {order?.customer_pack_text || <Label>Not provided</Label>}
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong>Order status:</strong> {order?.status ?? ''}
                  </Typography>
                </Grid>

                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Mobile number: </strong>
                    {order?.customer?.mobile_number ?? <Label>Not provided</Label>}
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong>Order date:</strong>{' '}
                    {fDate(order?.create_datetime) ?? <Label>Not provided</Label>}
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong>Payment date:</strong>{' '}
                    {order?.place_order_datetime ? fDate(order.place_order_datetime) : <Label>Not provided</Label>}
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong>Order code: </strong>
                    {order?.order_code ?? ''}
                  </Typography>
                </Grid>

                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Payment type: </strong> {order?.payment_type ?? ''}
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Delivery order id: </strong> {order?.delivery_order_id ?? <Label>Not provided</Label>}
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Tracking Url: </strong> <a href={order?.tracking_url ?? '#'}>{order?.tracking_url ??
                  <Label>Not provided</Label>}</a>
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Origin Tracking Url: </strong> <a
                    href={order?.origin_tracking_url ?? '#'}>{order?.origin_tracking_url ??
                  <Label>Not provided</Label>}</a>
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Destination Tracking Url: </strong> <a
                    href={order?.destination_tracking_url ?? '#'}>{order?.destination_tracking_url ??
                  <Label>Not provided</Label>}</a>
                  </Typography>
                </Grid>
              </Grid>
              <Typography mt='2rem' variant='h6'>
                Delivery address information:
              </Typography>
              <Grid spacing={2} gridColumn={2} mt={1} container>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Area:</strong>{' '}
                    {order?.delivery_address?.area ?? <Label>Not provided</Label>}
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Building name:</strong>{' '}
                    {order?.delivery_address?.building_name ?? <Label>Not provided</Label>}
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Billing mobile number:</strong>{' '}
                    {order?.billing_phone_number ?? <Label>Not provided</Label>}
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> Nearest landmark:</strong>{' '}
                    {order?.delivery_address?.landmark ?? <Label>Not provided</Label>}
                  </Typography>
                </Grid>

                <Grid item lg={6}>
                  <Typography variant='subtitle1'>
                    <strong> State:</strong>{' '}
                    {order?.delivery_address?.state ?? <Label>Not provided</Label>}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          <Stack flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Typography mt='1rem' variant='h6'>
              Purchased items:
            </Typography>
            {type === 'edit' && (
              <IconButton onClick={onClose} color='default'>
                <Icon width={30} height={30} icon='ep:circle-close' />
              </IconButton>
            )}
          </Stack>
          <Grid mt={1} container spacing={4}>
            {updatedOrder?.items?.map((item, _, array) => (   
              <Grid item lg={array.length === 1 && type === 'edit' ? 12 : 12} key={item.product_id}>
                <PurchasedItemCard
                  // Ordered Item 👇
                  {...item}
                  type={type}
                  updatedItem={updatedOrder?.items?.find((i) => i.product_id === item.product_id)}
                  onUpdateItemQuantity={updateOrderItemQuantity}
                />
              </Grid>
            ))}
            {updatedOrder?.PickNmix?.map((item, _, array) =>(
              (item.items.length!==0 &&
                <Grid item lg={array.length === 1 && type === 'edit' ? 12 : 12} key={item.pickNmix_id}>
                  
                <PurchasedPickItemCard
                  // Ordered Item 👇
                  {...item}
                  type={type}
                />
              </Grid>)
            ))

            }
             
            {order?.items?.length === 0 && order?.PickNmix?.length===0 && (
              <Grid item>
                <Typography>All items have been removed from the order.</Typography>
              </Grid>
            )}
          </Grid>
          {type === 'edit' && (
            <Footer>
              {!!history.length && (
                <Button onClick={undoDeletion} variant='outlined' size='large'>
                  Undo
                </Button>
              )}
              <Button onClick={handleUpdateOrder} variant='contained' size='large'>
                Save
              </Button>
            </Footer>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(SingleOrderModal);
