import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import DateAdapter from '@mui/lab/AdapterDateFns';

// material
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  ImageListItem,
  ImageList,
  MenuItem,
  Autocomplete,
  Checkbox
} from '@mui/material';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { apiUrl } from 'src/configs';
import { useEffect } from 'react';
import { updateCategoryRequest } from 'src/services/categories/updateCategoryRequest.service';
import { updateCouponRequest } from 'src/services/coupons/updateCouponRequest.service';
import Loader from '../loader/Loader';
import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

const initialValues = {
  name: '',
  max_quantity: '',
  code: '',
  discount_percentage: '',
  discount_amount: '',
  start_datetime: '',
  end_datetime: '',
  users: [],
  all_users: false
};

const editCategorySchema = Yup.object().shape({
  name: Yup.string(),
  max_quantity: Yup.number().min(1, 'It cannot be less than 1'),
  code: Yup.string(),
  discount_percentage: Yup.number()
    .min(1, 'It cannot be less than 1')
    .max(100, 'It cannot be more than 100'),
  discount_amount: Yup.number().min(1, 'It cannot be less than 1'),
  start_datetime: Yup.date(),
  users: Yup.array().of(Yup.string()),
  all_users: Yup.boolean(),
  start_datetime: Yup.date().required('Start datetime is required'),
  end_datetime: Yup.date().required('End datetime is required')
});

export default function EditCouponForm({ coupon, users = [], couponCodes = [] }) {
  const navigate = useNavigate();
  const { coupon_id: couponId } = useParams();

  const [loading, setLoading] = useState(false);
  const [fromDateTime, setFromDateTime] = useState();

  const handleUpdateCategory = (values) => {
    const PAYLOAD = {
      name: values.name,
      coupon_ids: [couponId],
      max_quantity: values.max_quantity,
      code: values.code,
      discount_percentage: values.discount_percentage,
      discount_amount: values.discount_amount,
      start_datetime: values.start_datetime,
      end_datetime: values.end_datetime,
      ...(values.all_users ? { all_users: true } : { users: values.users, all_users: false })
    };

    updateCouponRequest(PAYLOAD)
      .then((res) => {
        toast.success('Coupon updated successfully', { duration: 3000 });
        navigate('/coupons');
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
        // if (couponCodes.includes(values?.code)) {
        //   toast.error('Coupon code is already exists', { duration: 3000 });
        // }
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: editCategorySchema,
    onSubmit: handleUpdateCategory
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  useEffect(() => {
    if (coupon) {
      setValues({
        name: coupon?.name ?? '',
        max_quantity: coupon?.max_quantity ?? '',
        code: coupon?.code ?? '',
        discount_percentage: coupon?.discount_percentage ?? '',
        discount_amount: coupon?.discount_amount ?? '',
        start_datetime: coupon?.start_datetime ?? '',
        end_datetime: coupon?.end_datetime ?? '',
        users: coupon?.users ?? [],
        all_users: coupon?.all_users ?? false
      });
    }
  }, [coupon]);
  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form
          style={{
            position: 'relative'
          }}
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          {!coupon?.name && <Loader />}

          <Stack position="relative" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            {!coupon?.name && <Loader />}

            <TextField
              {...getFieldProps('name')}
              focused={values?.name}
              fullWidth
              autoComplete="title"
              type="text"
              label="Coupon Name"
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              focused={values?.max_quantity}
              autoComplete="max_quantity"
              type="number"
              min={0}
              label="Max Quantity"
              {...getFieldProps('max_quantity')}
              error={Boolean(touched.max_quantity && errors.max_quantity)}
              helperText={touched.max_quantity && errors.max_quantity}
            />
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              fullWidth
              autoComplete="Code"
              type="text"
              label="Code"
              focused={values?.code}
              {...getFieldProps('code')}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
            <Autocomplete
              multiple
              fullWidth
              disabled={values?.all_users}
              id="tags-outlined"
              options={users ?? []}
              value={users?.filter((item) => values?.users?.includes(item?.id)) ?? []}
              getOptionLabel={(option) => {
                return option.email;
              }}
              onChange={(event, newValue) => {
                const newUsersIds = newValue?.map((user) => user.id) ?? [];
                setValues({ ...values, users: newUsersIds });
              }}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Users" placeholder="Users" />}
            />
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <FormControlLabel
              style={{ width: '100%' }}
              checked={values?.all_users}
              control={<Checkbox {...getFieldProps('all_users')} checked={!!values?.all_users} />}
              label="All users"
            />
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              {...getFieldProps('discount_percentage')}
              fullWidth
              number
              autoComplete="discount_percentage"
              type="number"
              min="1"
              max="100"
              focused={values?.discount_percentage}
              label="Discount Percentage"
              error={Boolean(touched.discount_percentage && errors.discount_percentage)}
              helperText={touched.discount_percentage && errors.discount_percentage}
            />
            <TextField
              fullWidth
              autoComplete="discount_amount"
              type="number"
              label="Max discount Amount"
              focused={values?.discount_amount}
              {...getFieldProps('discount_amount')}
              error={Boolean(touched.discount_amount && errors.discount_amount)}
              helperText={touched.discount_amount && errors.discount_amount}
            />
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <DesktopDatePicker
              label="Start date"
              inputFormat="MM/dd/yyyy"
              minDate={new Date()}
              maxDate={
                values?.end_datetime
                  ? new Date(values?.end_datetime)
                  : new Date(new Date().getFullYear(), 11, 31)
              }
              value={
                fromDateTime ||
                new Date(values?.start_datetime).setDate(
                  new Date(values?.start_datetime).getDate() + 1
                )
              }
              onChange={(date) => {
                setFromDateTime(date);

                const fromDate = new Date(
                  new Date(date.getFullYear(), date.getMonth(), date.getDate()).setUTCHours(
                    0,
                    0,
                    1,
                    0
                  )
                ).toISOString();

                setValues({
                  ...values,
                  start_datetime: fromDate
                });
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  fullWidth
                  {...params}
                />
              )}
            />
            <DesktopDatePicker
              label="End date"
              inputFormat="MM/dd/yyyy"
              minDate={fromDateTime || new Date()}
              maxDate={new Date(new Date().getFullYear(), 11, 31)}
              value={values?.end_datetime}
              {...getFieldProps('end_datetime')}
              onChange={(date) =>
                setValues({
                  ...values,
                  end_datetime: new Date(date.setUTCHours(23, 59, 59, 0)).toISOString()
                })
              }
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  fullWidth
                  {...params}
                />
              )}
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
            className='save-btn'

          >
            Save
          </LoadingButton>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
}
