import { query } from 'src/utils/query';

export const changePasswordRequest = (payload = { old_password: '', new_password: '' }) => {
  return query({
    uri: 'api/v1/auth/password/change',
    auth: true,
    method: 'POST',
    data: payload
  });
};
