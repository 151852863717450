import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { styled } from '@mui/material/styles';
import { Box, TextField, Autocomplete, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { LoaderIcon } from 'react-hot-toast';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      width: 240,
      '& .MuiAutocomplete-inputRoot': {
        boxShadow: theme.customShadows.z12
      }
    }
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#E20246',
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`
    }
  },
  '& .MuiAutocomplete-option': {
    '&:not(:last-child)': {
      borderBottom: `solid 1px ${theme.palette.divider}`
    }
  }
}));

// ----------------------------------------------------------------------

SearchBar.propTypes = {
  items: PropTypes.array
};

export default function SearchBar({
  isLoading = false,
  filters,
  onFilterChange,
  placeholder = 'Search...',
  targetState = 'search'
}) {
  const [value, setValue] = useState('');
  return (
    <RootStyle>
      <TextField
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
          onFilterChange({
            ...filters,
            offset: 0,
            price_gt: null,
            price_lt: null,
            [targetState]: e.target.value.trim()
          });
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {isLoading ? (
                <Icon icon="eos-icons:three-dots-loading" width={22} speed={0.9} height={22} />
              ) : (
                <Icon icon="eva:search-outline" width={22} height={22} />
              )}
            </InputAdornment>
          )
        }}
      />
    </RootStyle>
  );
}
