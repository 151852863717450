import { query } from 'src/utils/query';

/** Fetch user from database */
export const getProfileRequest = () => {
  return query({
    uri: 'api/v1/admin/me',
    auth: true,
    method: 'GET'
  });
};
