import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-bottom: 5rem;
  .invoice {
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }

  header {
    padding: 1rem 0 2rem 0;
    margin-bottom: 20px;
    border-bottom: 3px solid #000;
    display: flex;
    align-items: center;
  }

  header .logo {
    margin-top: 10px;
    margin-left: 5rem;
    display: flex;
    align-items: center;
  }

  header .logo img {
    width: 80px;
    height: 80px;
  }

  header .logo h2 {
    margin-left: 10px;
    font-size: 1.1rem;
  }

  header .info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
  }

  header .info p {
    line-height: 1.6;
  }

  header .info span {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 992px) {
    & .invoice {
      display: none;
    }

    & header {
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
    }

    header .logo {
      margin-left: auto;
      margin-right: auto;
    }

    header .logo img {
      width: 50px;
      height: 50px;
    }

    header .info {
      margin-left: 0;
    }
  }

  @media (max-width: 400px) {
    header .logo {
      margin-left: 0;
      margin-right: 0;
    }
  }

  /* =============================== */

  .personal-info {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    margin-bottom: 20px;
    border-bottom: 3px solid #000;
  }

  .personal-info-left {
    margin-left: 5rem;
  }

  .personal-info-left,
  .personal-info-right {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .personal-info-left p,
  .personal-info-right p {
    line-height: 1.6;
  }

  .personal-info-left span,
  .personal-info-right span {
    font-weight: bold;
  }

  .personal-info-right {
    margin-left: auto;
    width: 37%;
  }

  .personal-info-right .delivery {
    font-weight: bold;
  }

  @media (max-width: 992px) {
    .personal-info-left {
      margin-left: 0;
    }
  }

  @media (max-width: 576px) {
    .personal-info {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }

    .personal-info-right {
      margin-left: 0;
      width: 100%;
    }
  }

  /* =============================== */

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 3rem 0;
    white-space: nowrap;
  }

  td,
  th {
    border: 1px solid #999;
    padding: 0.5rem;
    text-align: center;
    vertical-align: middle;
  }

  th.bg-gray,
  td.bg-gray {
    background-color: #e3e3e3;
  }

  tr.last-tr td {
    padding-bottom: 7rem;
  }

  td.ta-r {
    text-align: right;
  }

  td.not-border-bottom {
    border-bottom: 0;
  }

  td.not-border-top {
    border-top: 0;
  }

  .bold {
    font-weight: bold;
  }

  td.free-delivery span {
    color: #7f7f7f;
  }

  td.text-gray {
    color: #7f7f7f;
  }

  td.No,
  td.quantity {
    font-weight: bold;
  }

  td.product-name {
    font-size: 14px;
    width: 200px;
    max-width: 200px;
    white-space: normal;
    text-align: justify;
    line-height: 1.6;
  }

  td span.price {
    font-weight: bold;
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;
`;
