import { query } from 'src/utils/query';

export const getConfigsRequest = (params = {}) => {
  return query({
    uri: 'api/v1/admin/configs',
    auth: true,
    method: 'GET',
    params
  });
};
