import * as Yup from 'yup';
import { useState } from 'react';
import Axios from 'axios';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateAdapter from '@mui/lab/AdapterDateFns';
// material
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  ImageListItem,
  ImageList,
  Checkbox,
  MenuItem,
  Divider,
  Chip
} from '@mui/material';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { apiUrl } from 'src/configs';
import { useEffect } from 'react';
import { updateCustomerRequest } from 'src/services/customer/updateCustomerRequest.service';
import EditableAddress from './EditableAddress';
import { memo } from 'react';
import Loader from '../loader/Loader';
import { isObjectEmpty } from 'src/utils/objects';
import { mapServerErrorsToUIErrors } from 'src/utils/mapServerErrorsToUiErrors';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
// ----------------------------------------------------------------------

const editcustomerSchema = Yup.object().shape({
  first_name: Yup.string().min(2, 'first name must be at least 2 characters').nullable(),
  last_name: Yup.string().min(2, 'last name must be at least 2 characters').nullable(),
  email: Yup.string().email('Invalid email address').nullable(),
  mobile_number: Yup.string().min(10, 'mobile number must be at least 10 characters').nullable(),
  is_enable: Yup.boolean().nullable(),
  // password: Yup.string().min(6, 'password must be at least 6 characters'),
  national_code: Yup.string().min(1, 'national code must be at least 1 characters').nullable(),
  avatar: Yup.string().nullable(),
  date_of_birth: Yup.string().nullable(),
  gender: Yup.string().nullable(),
  telephone: Yup.number('must be a valid mobile number')
    .min(6, 'telephone must be at least 6 characters')
    .nullable(),
  user_type: Yup.string().nullable(),
  permissions: Yup.string('permissions').nullable(),
  groups: Yup.string('groups').nullable(),
  country_code: Yup.string('country code').nullable(),
  state: Yup.string('city').nullable(),
  currency_code: Yup.string('currency code').nullable(),
  language: Yup.string('language').nullable(),
  fav_flavors: Yup.array().of(Yup.string()).nullable(),
  off_coupon: Yup.string().nullable(),
  shopping_device: Yup.string().nullable(),
  candy_buy_time: Yup.string().nullable()
});
const initialValues = {
  gender: 'male',
  user_type: 'parent',
  email: '',
  mobile_number: '',
  telephone: '',
  is_enable: true,
  password: '',
  national_code: '',
  date_of_birth: '',
  currency_code: '',
  language: '',
  country_code: '',
  state: '',
  fav_flavors: [],
  off_coupon: '',
  shopping_device: '',
  candy_buy_time: ''
};

export default memo(function EditCustomerForm({ customer }) {
  const navigate = useNavigate();
  const { customer_id: customerId } = useParams();
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState();

  const handleUpdateAdmin = (values, { setErrors }) => {
    const PAYLOAD = {
      first_name: values.first_name ?? '',
      last_name: values.last_name ?? '',
      email: values.email ?? '',
      mobile_number: '+' + values.mobile_number ?? '',
      is_enable: values.is_enable,
      national_code: values.national_code ?? '',
      avatar: avatar ?? '',
      telephone: values.telephone ?? '',
      gender: values.gender ?? 'male',
      user_type: values.user_type,
      // date_of_birth: new Date(values.date_of_birth).toISOString().substr(0, 10),
      settings: {
        currency_code: values.currency_code ?? '',
        language: values.language ?? '',
        country_code: values.country_code ?? '',
        state: values.state ?? ''
      }
    };
    debugger;
    updateCustomerRequest(PAYLOAD, customerId)
      .then((res) => {
        toast.success('Customer updated successfully', { duration: 3000 });
        navigate('/customers');
      })
      .catch(({ data: { detail, data } }) => {
        debugger;
        mapServerErrorsToUIErrors(detail, setErrors);

        Swal.fire({
          title: 'Error',
          text: data?.message,
          icon: 'error'
        });
      });
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editcustomerSchema,
    onSubmit: handleUpdateAdmin
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  const handleUploadcustomerAvatar = (event) => {
    const file = event.target.files[0];

    setLoading(true);

    uploadFileRequest(file)
      .then((res) => {
        setAvatar(res.data.file_url);
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (!isObjectEmpty(customer)) {
      setLoading(false);
      setAvatar(customer.avatar);
      setValues({
        user_type: customer?.user_type || 'parent',
        gender: customer?.gender || 'male',
        first_name: customer?.first_name ?? '',
        last_name: customer?.last_name ?? '',
        email: customer?.email ?? '',
        mobile_number: customer?.mobile_number?.slice(1) ?? '',
        telephone: customer?.telephone ?? '',
        is_enable: customer?.is_enable ?? true,
        password: customer?.password ?? '',
        national_code: customer?.national_code ?? '',
        date_of_birth: customer?.date_of_birth ?? '',
        currency_code: customer?.settings?.currency_code ?? '',
        language: customer?.settings?.language ?? '',
        country_code: customer?.settings?.country_code ?? '',
        state: customer?.settings?.state ?? '',
        fav_flavors: customer?.fav_flavors ?? [],
        off_coupon: customer?.off_coupon ?? '',
        shopping_device: customer?.shopping_device ?? '',
        candy_buy_time: customer?.candy_buy_time ?? ''
      });
    }
  }, [customer]);

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form
          autoComplete="off"
          style={{
            position: 'relative'
          }}
          noValidate
          onSubmit={handleSubmit}
        >
          {loading && <Loader />}

          <Stack position="relative" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            {loading && <Loader />}
            <TextField
              inputProps={{ maxLength:50}}
              {...getFieldProps('first_name')}
              focused={values?.first_name}
              fullWidth
              autoComplete="first_name"
              type="text"
              label="First name"
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />

            <TextField
              inputProps={{ maxLength:50}}
              fullWidth
              autoComplete="last_name"
              type="text"
              focused={values?.last_name}
              label="Last name"
              {...getFieldProps('last_name')}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
          </Stack>
          <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              focused={values?.email}
              fullWidth
              autoComplete="email"
              type="email"
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              InputProps={{
                readOnly: true,maxLength:50
              }}
            />
            <TextField
              focused={values?.mobile_number}
              fullWidth
              autoComplete="text"
              type="number"
              label="Mobile number"
              {...getFieldProps('mobile_number')}
              error={Boolean(touched.mobile_number && errors.mobile_number)}
              helperText={touched.mobile_number && errors.mobile_number}
              InputProps={{
                readOnly: true,
              }}
            />
          </Stack>

          <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
            <label style={{ margin: '0', width: '100%' }} htmlFor="overview_urls">
              <input
                onChange={handleUploadcustomerAvatar}
                accept="image/*"
                style={{ display: 'none' }}
                id="overview_urls"
                name="overview_urls"
                type="file"
              />
              <Button fullWidth variant="contained" component="span">
                Edit customer Avatar
              </Button>
            </label>
            {avatar && (
              <img
                style={{ margin: '1rem auto 0', width: '300px', objectFit: 'cover' }}
                src={avatar}
                alt="customer image"
              />
            )}
          </Stack>
          <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              inputProps={{ maxLength:15}}
              focused={values?.national_code}
              fullWidth
              autoComplete="text"
              type="number"
              label="National number"
              {...getFieldProps('national_code')}
              error={Boolean(touched.national_code && errors.national_code)}
              helperText={touched.national_code && errors.national_code}
            />
            <FormControlLabel
              style={{ width: '100%' }}
              checked={values?.is_enable}
              control={<Checkbox {...getFieldProps('is_enable')} checked={!!values?.is_enable} />}
              label="Enable"
            />
          </Stack>
          <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              fullWidth
              label="Gender"
              error={Boolean(touched.gender && errors.gender)}
              helperText={touched.gender && errors.gender}
              defaultValue={customer?.gender}
              {...getFieldProps('gender')}
              value={values?.gender}
              select
              size="small"
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Others</MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="User type"
              error={Boolean(touched.user_type && errors.user_type)}
              helperText={touched.user_type && errors.user_type}
              {...getFieldProps('user_type')}
              select
              size="small"
            >
              <MenuItem value="kid">Kid</MenuItem>
              <MenuItem value="parent">Parent</MenuItem>
            </TextField>
          </Stack>
          <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              focused={values?.date_of_birth}
              fullWidth
              autoComplete="text"
              type="string"
              label="Date of birth"
              {...getFieldProps('date_of_birth')}
              error={Boolean(touched.date_of_birth && errors.date_of_birth)}
              helperText={touched.date_of_birth && errors.date_of_birth}
              InputProps={{
                readOnly: true
              }}
            />
            <TextField
              focused={values?.telephone}
              fullWidth
              autoComplete="text"
              type="number"
              label="Telephone"
              error={Boolean(touched.telephone && errors.telephone)}
              helperText={touched.telephone && errors.telephone}
              {...getFieldProps('telephone')}
            />
          </Stack>
          <Divider style={{ margin: '2rem auto' }} variant="fullWidth">
            <Chip label="Questions" />
          </Divider>
          <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            {/* <TextField
              focused={values?.fav_flavors.length || null}
              fullWidth
              type="text"
              label="What are your favorite flavors ?​"
              {...getFieldProps('fav_flavors')}
              InputProps={{
                readOnly: true
              }}
            /> */}
            <TextField
              focused={values?.off_coupon}
              fullWidth
              type="text"
              label="Do you like offers or coupons​?"
              {...getFieldProps('off_coupon')}
              InputProps={{
                readOnly: true
              }}
            />
          </Stack>
          <Stack my="2rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              focused={values?.shopping_device}
              fullWidth
              type="text"
              label="Do you Shop through mobile or website​ ?"
              {...getFieldProps('shopping_device')}
              InputProps={{
                readOnly: true
              }}
            />
            <TextField
              focused={values?.candy_buy_time}
              fullWidth
              type="text"
              label="How often do you buy candy ?"
              {...getFieldProps('candy_buy_time')}
              InputProps={{
                readOnly: true
              }}
            />
          </Stack>
          <Divider style={{ margin: '2rem auto' }} variant="fullWidth">
            <Chip label="Settings" />
          </Divider>
          <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              focused={values?.currency_code}
              fullWidth
              autoComplete="currency_code"
              type="text"
              label="currency_code"
              {...getFieldProps('currency_code')}
              error={Boolean(touched.currency_code && errors.currency_code)}
              helperText={touched.currency_code && errors.currency_code}
            />
            <TextField
              focused={values?.country_code}
              fullWidth
              autoComplete="text"
              type="text"
              label="Country code"
              {...getFieldProps('country_code')}
              error={Boolean(touched.country_code && errors.country_code)}
              helperText={touched.country_code && errors.country_code}
            />
          </Stack>
          <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              focused={values?.language}
              fullWidth
              autoComplete="language"
              type="text"
              label="language"
              {...getFieldProps('language')}
              error={Boolean(touched.language && errors.language)}
              helperText={touched.language && errors.language}
            />
            <TextField
              focused={values?.state}
              fullWidth
              autoComplete="text"
              type="text"
              label="state"
              {...getFieldProps('state')}
              error={Boolean(touched.state && errors.state)}
              helperText={touched.state && errors.state}
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
            className='save-btn'

          >
            Save
          </LoadingButton>
          <Divider style={{ margin: '2rem auto' }} variant="fullWidth">
            <Chip label="Addresses" />
          </Divider>
        </Form>
        <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
          {customer?.addresses?.map((address, index, addresses) => (
            <EditableAddress
              address={address}
              key={address.id}
              addresses={addresses}
              customerId={customerId}
              customerData={customer}
              loading={loading}
              setLoading={setLoading}
            />
          ))}
        </Stack>
      </LocalizationProvider>
    </FormikProvider>
  );
});
