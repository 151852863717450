// ----------------------------------------------------------------------

const shape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
  borderRadiuss: 40,


};

export default shape;
