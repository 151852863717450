import { query } from 'src/utils/query';

export const updatePromotionRequest = (payload, promotionId) => {
  return query({
    uri: 'api/v1/admin/promotions/' + promotionId + '/',
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
