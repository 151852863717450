import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Stack
} from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';
import { db } from 'src/utils/db';
import { useUser } from 'src/providers/user';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const user = useUser();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logoutHandler = () => {
    db.delete('tokens');
    window.location.reload();
  };

  const goToEditProfile = () => {
    navigate('/edit-profile');
  };

  const goToEditWebsiteSetting = () => {
    navigate('/settings');
  };

  const goToChangePasswordPage = () => {
    navigate('/change-password');
  };

  return (
    <>
      <div className='profile-icon'>
        <IconButton className='icon'
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              // '&:before': {
              //   zIndex: 1,
              //   content: "''",
              //   width: '100%',
              //   height: '100%',
              //   borderRadius: '50px',
              //   position: 'absolute',
              //   bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              // }
            })
          }}
        >
          <Avatar src={user.avatar} alt="avatar" />
          <p>{user.first_name}</p>
          <img className='drop-arow' src='/static/icons/dropdown-acount.png' alt='/' ></img>
        </IconButton>
      </div>


      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 300 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.first_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        <Box sx={{ px: 1.2, pt: 1.5 }}>
          <Button
            style={{
              whiteSpace: 'nowrap'
            }}
            onClick={goToChangePasswordPage}
            fullWidth
            color="inherit"
            variant="outlined"
          >
            Change password
          </Button>
        </Box>
        <Stack flexDirection="row" gap={1} sx={{ px: 1.2, py: 1 }}>
          <Button
            style={{
              whiteSpace: 'nowrap'
            }}
            onClick={goToEditProfile}
            fullWidth
            color="inherit"
            variant="outlined"
          >
            Edit profile
          </Button>
          {user.userPermissions.includes('configs') && (
            <Button
              style={{
                whiteSpace: 'nowrap'
              }}
              onClick={goToEditWebsiteSetting}
              fullWidth
              color="inherit"
              variant="outlined"
            >
              Website settings
            </Button>
          )}
        </Stack>
        <Box sx={{ px: 1.2, pb: 1.5 }}>
          <Button onClick={logoutHandler} fullWidth color="inherit" variant="outlined">
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
