import { query } from 'src/utils/query';

export const uploadFileRequest = (file) => {
  const formData = new FormData();
  formData.append('_file', file);

  return query({
    uri: 'api/v1/admin/files',
    auth: true,
    method: 'POST',
    data: formData
  });
};
