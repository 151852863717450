import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <>
      <section class="form-login">
        <div className='container'>
          <div className='login-sec'>
            <div className='row justify-content-center'>
              <div className="col-md-12">
                <div className='bg-login-img'>
                  <img src="/static/illustrations/bg-admin-login.png" alt="/" />
                </div>
              </div>
              <div className='col-md-10'>
                <Container maxWidth="sm">
                  <ContentStyle>
                    <Stack sx={{ mb: 5 }}>
                      <Typography variant="h4" gutterBottom>
                        Sign in to Chupa Chups Ecommerce
                      </Typography>
                      <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                    </Stack>
                    {/* <AuthSocial /> */}
                    <LoginForm />
                  </ContentStyle>
                </Container>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>

  );
}
