import { query } from 'src/utils/query';
import qs from 'qs';
import { apiUrl } from 'src/configs';
import { db } from 'src/utils/db';

export const getOrdersXMLRequest = (params = {}) => {
  return query({
    uri: 'api/v1/admin/orders/export-xml',
    auth: true,
    method: 'GET',
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
  });

  // const configs = {
  //   method: 'GET',
  //   headers: {
  //     'Content-type': 'application/xml',
  //     'Content-Disposition': 'attachment',
  //     authorization: 'Bearer ' + db.get('tokens')?.['access_token']
  //   }
  // };
  // return fetch(`${apiUrl}api/v1/admin/orders/export-xml`, configs).then((response) =>
  //   response.blob()
  // );
};
