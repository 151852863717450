import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import { useUser } from 'src/providers/user';
import Loader from './loader/Loader';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.global.red,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    marginLeft: "5%",
    marginRight: "10%",

    '&:before': {
      top: 0,
      width: 4,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      marginLeft: "-17%",
      height: '100%',
      backgroundColor: theme.palette.global.red
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  minWidth : 0
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item?.path);
  const { title, path, icon, info, subItems } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    marginTop: "5%",
    marginBottom: "5%",
    color: theme.palette.global.red,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    marginLeft: "5%",
    marginRight: "10%",
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.global.red, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block'}
  };

  const activeSubStyle = {
    color: theme.palette.global.red,
    fontWeight: 'fontWeightMedium'
  };

  if (subItems) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subItems.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(!isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

export default memo(function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  const { userPermissions } = useUser();

  return (
    <Box {...other} pb={4}>
      <List disablePadding>
        {navConfig.map((item) => {
          if (userPermissions.includes(item.permission)) {
            return <NavItem key={item.title} item={item} subItems={item.subItems} active={match} />
          }
          return null;
        })}
      </List>
      {userPermissions.length === 0 && <Loader />}
    </Box>
  );
});
