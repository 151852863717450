import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { updateProductRequest } from 'src/services/products/updateProductRequest.service';
import toast, { Toaster } from 'react-hot-toast';
import { updateOrderStatusRequest } from 'src/services/orders/updateOrderStatusRequest.service';
import SingleOrderModal from './singleOrderModal';
import { createDeliveryOrderRequest } from 'src/services/orders/createDeliveryOrderRequest.service';
import { getDeliveryLabelRequest } from 'src/services/orders/getDeliveryLabelRequest.service';
import BillOfPurchase from './BillOfPurchase';
import { PurchaseContainer } from './MenuStyle';
import { useReactToPrint } from 'react-to-print';

// ----------------------------------------------------------------------

const STATUS_ICON = {
  Personalizing: 'dashicons:admin-customizer',
  Personalized: 'fa-solid:paint-brush',
  'Sent for delivery': 'carbon:delivery-truck',
  Reject: 'icon-park-outline:reject',
  Confirm: 'line-md:confirm-circle',
  'Reject & Refund': 'mdi:credit-card-refund-outline',
  Edit: 'akar-icons:edit',
  'Create delivery label': 'ic:outline-create-new-folder',
  'Create delivery order': 'ic:outline-create-new-folder',
  'Delivery order created': 'fluent:document-pdf-16-filled',
  'Labeled to delivery': 'ant-design:delivered-procedure-outlined'
};

const PrintComponent = ({ printRef, orderId }) => {
  return (
    <div ref={printRef} className="p-5">
      <BillOfPurchase orderId={orderId} />
    </div>
  );
};

export default function OrdersMoreMenu({ order, onGetOrders }) {
  const ref = useRef(null);
  const purchaseContainer = useRef(null);
  const printRef = useRef();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const handleOpenViewOrderModal = () => {
    setModalType('view');
    setIsModalOpen(true);
  };
  const handleCloseViewOrderModal = () => {
    setModalType('');
    setIsModalOpen(false);
  };

  const handleOpenEditOrderModal = () => {
    setModalType('edit');
    setIsModalOpen(true);
  };

  const handleCloseEditOrderModal = () => {
    setModalType('');
    setIsModalOpen(false);
  };

  const changeStatusHandler = (status) => {
    const PAYLOAD = {
      status: status.status,
      reason:"hello"
    };
    setIsMenuOpen(false);
    if (status.title === 'Get Label Again' || status.title === 'Get Label') {
      setIsMenuOpen(false);
      downloadDevliveryLabel();
      return;
    }

    if (status.title === 'Edit') {
      setIsMenuOpen(false);
      handleOpenEditOrderModal();
      return;
    }

    updateOrderStatusRequest(PAYLOAD, order.id)
      .then(() => {
        toast.success('Order status changed successfully', { duration: 2000 });
        onGetOrders();
      })
      .catch(() => {
        onGetOrders();
        toast.error('Something went wrong', { duration: 2000 });
      });
  };

  const downloadDevliveryLabel = () => {
    getDeliveryLabelRequest(order.id, {})
      .then((binaryResponse) => {
        // To get know about the Blob API please visit:
        // https://developer.mozilla.org/en-US/docs/Web/API/Blob
        const fileBlob = new Blob([binaryResponse], { type: 'application/pdf; charset=utf-8' });
        const fileURL = URL.createObjectURL(fileBlob);
        // debugger;
        window.open(fileURL);

        const link = document.createElement('a');
        link.href = fileURL;
        link.download = 'delivery_label.pdf';

        link.click();
        link.remove();
      })
      .catch(() => {
        toast.error('Something went wrong, please try again later', {
          duration: 3000
        });
      })
      .finally(() => {
        window.location.reload();
      });
  };

  const showPurchaseBackground = () => {
    purchaseContainer.current.style.display = 'block';
    const body = document.querySelector('body');
    body.style.overflowY = 'hidden';
  };

  const hidePurchaseBackground = () => {
    purchaseContainer.current.style.display = 'none';
    const body = document.querySelector('body');
    body.style.overflowY = 'scroll';
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <IconButton ref={ref} onClick={() => setIsMenuOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isMenuOpen}
        anchorEl={ref.current}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          sx: { width: 'auto', maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setIsMenuOpen(false);
            handleOpenViewOrderModal();
          }}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon="clarity:grid-view-line" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem onClick={showPurchaseBackground} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon="carbon:purchase" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Invoice" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <PurchaseContainer ref={purchaseContainer}>
          <IconButton className="close-icon no-print" onClick={hidePurchaseBackground}>
            <Icon icon="ep:circle-close" width={30} height={30} />
          </IconButton>
          <IconButton className="export-icon no-print" onClick={handlePrint}>
            <Icon icon="fluent:document-pdf-24-regular" width={36} height={36} />
          </IconButton>
          <PrintComponent printRef={printRef} orderId={order.id} />
        </PurchaseContainer>

        {order?.available_statuses?.map((status) => (
          <MenuItem onClick={() => changeStatusHandler(status)} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon
                icon={STATUS_ICON[status.title] ?? 'fluent:document-pdf-16-filled'}
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText primary={status.title} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ))}
      </Menu>
      {isModalOpen && (
        <SingleOrderModal
          type={modalType}
          orderId={order.id}
          onClose={handleCloseViewOrderModal}
          isOpen={isModalOpen}
        />
      )}
    </>
  );
}
