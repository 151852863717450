import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { updateProductRequest } from 'src/services/products/updateProductRequest.service';
import toast, { Toaster } from 'react-hot-toast';
import { updateCategoryRequest } from 'src/services/categories/updateCategoryRequest.service';
import { deleteCategoryRequest } from 'src/services/categories/deleteCategoryRequest.service';

import Swal from 'sweetalert2';
import { updateDesignRequest } from 'src/services/designs/updateDesignRequest.service';
import { deleteDesignRequest } from 'src/services/designs/deleteDesignRequest.service';

// ----------------------------------------------------------------------

export default function CategoriesMoreMenu({ categoryId, onGetCategories, setLoading }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const changeStatusHandler = (status) => {
    const PAYLOAD = {
      is_enable: status === 'enable'
    };
    setIsOpen(false);

    updateDesignRequest(PAYLOAD, categoryId)
      .then(() => {
        setLoading(true);
        setTimeout(() => {
          toast.success('Design status changed successfully', { duration: 3000 });
          onGetCategories();
        }, 3500);
      })
      .catch(() => {
        toast.error('Something went wrong', { duration: 4000 });
      });
  };

  const deleteCategoryHandler = () => {
    setIsOpen(false);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this category!',
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        debugger;
        deleteDesignRequest(categoryId)
          .then(() => {
            toast.success('Poof! Your design has been deleted!', { duration: 4000 });
            onGetCategories();
          })
          .catch(({ data: { detail } }) => {
            toast.error(detail?.[0] ?? 'Design has product', { duration: 4000 });
          });
      }
    });
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={deleteCategoryHandler} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon="fluent:delete-28-regular" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {console.log(categoryId)}
        <MenuItem
          component={RouterLink}
          to={'/design/edit/' + categoryId}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          onClick={changeStatusHandler.bind(this, 'disable')}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon="fe:disabled" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Disable" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          onClick={changeStatusHandler.bind(this, 'enable')}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon="akar-icons:chat-approve" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Enable" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
