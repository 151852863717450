import { query } from 'src/utils/query';

export const refreshJWTRequest = (payload) => {
  return query({
    uri: 'api/v1/admin/auth/token/refresh',
    auth: false,
    method: 'POST',
    data: payload
  });
};
