import { apiUrl } from 'src/configs';

export const downloadXMLFile = (fileURL, fileName = 'XML_EXPORT') => {
  const link = document.createElement('a');
  link.href = apiUrl + fileURL;
  link.download = `${fileName}.xml`;
  link.download = true;
  window.open(apiUrl + fileURL);
  // link.click();
  // link.remove();
};

// ===========================================================================

// export const downloadXMLFile = (fileURL, fileName = 'XML_EXPORT') => {
//   const fileBlob = new Blob([apiUrl + fileURL], { type: 'application/xml;charset=utf-8' });
//   const url = window.URL.createObjectURL(fileBlob);
//   window.open(apiUrl + fileURL);
//   const link = document.createElement('a');
//   link.href = url;
//   link.download = `${fileName}.xml?test=test`;
//   document.body.appendChild(link);
//   link.click();
//   link.remove();
// };
