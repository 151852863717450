import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { fDateTimeSuffix, fDateTime, fDate, fToNow } from '../utils/formatTime';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ProductsListHead,
  ProductsListToolbar,
  ProductsMoreMenu
} from '../components/_dashboard/products';
//
import USERLIST from '../_mocks_/user';
import { getProductsRequest } from 'src/services/products/getProductsRequest.service';
import { apiUrl, tableConfigs } from 'src/configs';
import { getTestimonialsRequest } from 'src/services/testimonials/getTestimonialsRequest.service';
import { getCouponsRequest } from 'src/services/coupons/getCouponsRequst.service';
import CouponsMoreMenu from 'src/components/Coupons/Menu';
import { getCategoriesRequest } from 'src/services/categories/getCategoriesRequest.service';
import { getOrdersRequest } from 'src/services/orders/getOrdersRequest.service';
import OrdersMoreMenu from 'src/components/orders/Menu';
import { getOrderPacksRequest } from 'src/services/OrderPacks/getOrderPacksRequest.service';
import TagsMoreMenu from 'src/components/tags/Menu';
import { getTagsRequest } from 'src/services/tags/getTagsRequest.service';
import { deleteTagRequest } from 'src/services/tags/deleteTagRequest.service';
import Loader from 'src/components/loader/Loader';
import { loaderStyle } from './Products';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Name', label: 'Name', alignRight: false },
  { id: '', label: '', alignRight: false }
];

// ----------------------------------------------------------------------

export default function Tags() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [tags, setTags] = useState({
    data: [],
    total: 0
  });
  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    limit: tableConfigs.defaultPageSize,
    offset: 0
  });

  const getTags = () => {
    setLoading(true);
    getTagsRequest(params).then(({ data }) => {
      setLoading(false);
      setTags({
        data: data.result,
        total: data.total
      });
    });
  };

  useEffect(() => {
    getTags();
  }, [params]);

  const handleSelectItem = (event, tagTitle) => {
    const { checked } = event.target;
    setSelectedItems(
      checked ? [...selectedItems, tagTitle] : selectedItems.filter((item) => item !== tagTitle)
    );
  };

  const handleSelectAllItems = (event) => {
    if (event.target.checked) {
      const allItems = tags.data.map((t) => t.title);
      setSelectedItems(allItems);
      return;
    }
    setSelectedItems([]);
  };

  const deleteTagsHandler = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Once deleted, you will not be able to recover ${
        selectedItems.length === 1 ? 'this tag' : 'these tags'
      }!`,
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: `${selectedItems.length === 1 ? 'Yes, delete it!' : 'Yes, delete theme!'}`
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        const PAYLOAD = {
          titles: selectedItems
        };
        deleteTagRequest(PAYLOAD)
          .then(() => {
            setSelectedItems([]);
            toast.success(
              `Poof! Your ${selectedItems.length === 1 ? 'tag has' : 'tags have'} been deleted!`,
              { duration: 4000 }
            );
            getTags();
          })
          .catch(() => {
            toast.error('Something went wrong', { duration: 4000 });
          });
      }
    });
  };

  return (
    <Page title="Gift wrapping Packs">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tags (used to products filtering)
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/tags/add"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Tag
          </Button>
        </Stack>

        <Card>
          <ProductsListToolbar
            filterName=""
            onFilterName={() => {}}
            numSelected={selectedItems.length}
            deleteSelectedItems={deleteTagsHandler}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductsListHead
                  order={'name'}
                  orderBy={'name'}
                  headLabel={TABLE_HEAD}
                  rowCount={tags.data.length}
                  numSelected={selectedItems.length}
                  onRequestSort={() => {}}
                  onSelectAllClick={handleSelectAllItems}
                />
                <TableBody>
                  {loading && (
                    <div style={loaderStyle}>
                      <Loader />
                    </div>
                  )}
                  {tags.data.map((row) => {
                    const { id, title, image_url } = row;
                    const isItemSelected = selectedItems.indexOf(title) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                      >
                        <TableCell align="left">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(e) => handleSelectItem?.(e, title)}
                          />
                        </TableCell>
                        <TableCell align="center">{title ?? ''}</TableCell>
                        <TableCell align="right">
                          <TagsMoreMenu onGetTags={getTags} tagTitle={title} tagId={id} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {tags.total === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={params?.search ?? ''} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            SelectProps={{
              disabled: loading
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            rowsPerPageOptions={tableConfigs.pageSizeOptions
              .filter((item) => item <= tags.total)
              .concat(tags.total)}
            component="div"
            count={tags.total}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(_, selected) => {
              setParams({
                ...params,
                offset: selected * params.limit
              });
            }}
            onRowsPerPageChange={({ target }) => {
              const selected = target.value;
              setParams({ ...params, limit: selected });
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}
