import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import DateAdapter from '@mui/lab/AdapterDateFns';

// material
import { Stack, TextField, Button, FormLabel } from '@mui/material';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { apiUrl } from 'src/configs';
import { useEffect } from 'react';
import { updateCategoryRequest } from 'src/services/categories/updateCategoryRequest.service';
import { updateCouponRequest } from 'src/services/coupons/updateCouponRequest.service';
import { editTestimonialRequest } from 'src/services/testimonials/editTestimonialRequest.service';
import { addTestimonialRequest } from 'src/services/testimonials/addTestimonialRequest.service';
import { addNewsRequest } from 'src/services/news/addNewsRequest.service';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';

// ----------------------------------------------------------------------

const initialValues = {
  title: '',
  text: '',
  publish_datetime: ''
};

const addNewsSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  text: Yup.string().required('Text is required'),
  publish_datetime: Yup.date().required('Publish date is required')
});

export default function AddNewsForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  const handleAddNews = (values) => {
    const PAYLOAD = {
      ...values,
      publish_datetime: values.publish_datetime,
      image_url: image
    };

    if (!image) {
      toast.error('Image is required');
      return;
    }

    addNewsRequest(PAYLOAD)
      .then((res) => {
        toast.success('News added successfully', { duration: 4000 });
        navigate('/news');
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    validationSchema: addNewsSchema,
    initialValues: initialValues,
    onSubmit: handleAddNews
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues, setFieldValue } = formik;

  const handleUploadNewsImage = (event) => {
    const file = event.target.files[0];

    setLoading(true);
    uploadFileRequest(file)
      .then(({ data }) => {
        setImage(data.file_url);
      })
      .catch((err) => {
        console.trace(err);
      })
      .finally(() => setLoading(false));
  };
  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              inputProps={{ maxLength:50}}
              {...getFieldProps('title')}
              focused={values?.title}
              fullWidth
              autoComplete="title"
              type="text"
              label="Title *"
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
            <DesktopDatePicker
              label="Publish Date"
              inputFormat="MM/dd/yyyy"
              minDate={new Date()}
              maxDate={new Date(new Date().getFullYear(), 11, 31)}
              value={values.publish_datetime || null}
              onChange={(date) => {
                setValues({
                  ...values,
                  publish_datetime: new Date(date).toISOString()
                });
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  fullWidth
                  {...params}
                  error={Boolean(touched.publish_datetime && errors.publish_datetime)}
                  helperText={touched.publish_datetime && errors.publish_datetime}
                />
              )}
            />
          </Stack>
          <Stack my="1rem" direction="column" position="relative" spacing={1}>
            <FormLabel>Description</FormLabel>

            <ReactQuill
              className="quill-editor"
              toolbar={['bold', 'italic', 'underline', 'link']}
              // formats={['bold', 'italic', 'underline', 'link', 'unorderedList', 'orderedList']}
              value={values.text}
              defaultValue={values.text}
              onChange={(_, __, ___, { getHTML }) => {
                const newValue = getHTML();
                setFieldValue('text', newValue);
              }}
              
            />
            
          </Stack>
          <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
            <label style={{ margin: '0', width: '100%' }} htmlFor="overview_urls">
              <input
                onChange={handleUploadNewsImage}
                accept="image/*"
                style={{ display: 'none' }}
                id="overview_urls"
                name="overview_urls"
                type="file"
              />
              <Button fullWidth variant="contained" component="span">
                Upload news image
              </Button>
            </label>
            {image && (
              <img
                style={{ margin: '1rem auto 0', objectFit: 'cover' }}
                width={200}
                src={image}
                alt="testimonial image"
              />
            )}
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
            className='save-btn'

          >
            Save
          </LoadingButton>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
}
