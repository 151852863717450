import { Icon } from '@iconify/react';
/**
 *
 * @param {String} name
 * @returns JSX.Element
 */
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    permission: 'products',
    title: 'products',
    path: '/products',
    icon: getIcon('icon-park-outline:lollipop')
  },
  {
    permission: 'users',
    title: 'customers',
    path: '/customers',
    icon: getIcon('akar-icons:person')
  },
  {
    permission: 'club_users',
    title: 'club',
    path: '/club',
    icon: getIcon('ps:clubs-card')
  },
  // {
  //   title: 'orders',
  //   path: '/orders',
  //   icon: getIcon('icon-park-outline:transaction-order'),
  //   subItems: [
  //     {
  //       title: 'All orders',
  //       path: '/all-orders'
  //     },
  //     {
  //       title: 'Incoming orders',
  //       path: '/incoming-orders'
  //     }
  //   ]
  // },
  {
    permission: 'orders',
    title: 'orders',
    path: '/orders',
    icon: getIcon('icon-park-outline:transaction-order')
  },
  {
    permission: 'categories',
    title: 'Categories',
    path: '/categories',
    icon: getIcon('ic:outline-category')
  },
  {
    permission: 'seo',
    title: 'SEO',
    path: '/seo',
    icon: getIcon('ic:outline-discount')
  },
  {
    permission: 'design',
    title: 'Product Design',
    path: '/design',
    icon: getIcon('ic:outline-category')
  },
  {
    permission: 'coupons',
    title: 'Coupons',
    path: '/coupons',
    icon: getIcon('ps:clubs-card')
  },
  {
    permission: 'discounts',
    title: 'Discounts',
    path: '/discounts',
    icon: getIcon('ic:outline-discount')
  },

  {
    permission: 'news',
    title: 'News',
    path: '/news',
    icon: getIcon('bx:bx-news')
  },
  {
    permission: 'blogs',
    title: 'Blog',
    path: '/blogs',
    icon: getIcon('jam:blogger-square')
  },
  {
    permission: 'order_packs',
    title: 'Gift wrapping packs',
    path: '/order-packs',
    icon: getIcon('codicon:package')
  },
  {
    permission: 'tags',
    title: 'Tags',
    path: '/tags',
    icon: getIcon('akar-icons:tag')
  },
  // {
  //   title: 'Website settings',
  //   path: '/settings',
  //   icon: getIcon('icon-park-outline:setting-two')
  // },
  {
    permission: 'promotions',
    title: 'Homepage banners',
    path: '/promotions',
    icon: getIcon('ep:promotion')
  },
  ,
  {
    permission: 'socials',
    title: 'Socials',
    path: '/socials',
    icon: getIcon('simple-line-icons:social-instagram')
  },
  {
    permission: 'questions',
    title: 'Questions',
    path: '/questions',
    icon: getIcon('bi:patch-question')
  },
  {
    permission: 'contact_us',
    title: 'Contact Us List',
    path: '/contact-us',
    icon: getIcon('fluent:contact-card-24-regular')
  }
];

export default sidebarConfig;
