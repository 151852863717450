import { query } from 'src/utils/query';

/** Fetch a single coupon from database */
export const getCouponRequest = (couponId) => {
  return query({
    uri: 'api/v1/admin/coupons/' + couponId + '/',
    auth: true,
    method: 'GET'
  });
};
