import { Icon } from '@iconify/react';
import {
  CardActionArea,
  CardContent,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { memo } from 'react';
import Label from '../Label';
import PersonalizationItem from './personalizationItem';

export const PurchasedItemCard = memo(
  ({ updatedItem, onUpdateItemQuantity, type, ...orderedItem }) => {
    return (
      <section className="card-picknmix card-picknmix-2">
        <div className="card-content">
          <div className="align-item-pick align-item-pick-sub">
            <div className="w-30">
              <div className="card-media-img">
                <img src={orderedItem?.image_urls?.[0]} alt={orderedItem.title} />
              </div>
            </div>

            <div className="w-70">
              <CardContent>
                <Typography
                  borderBottom="1px solid #e0e0e0"
                  paddingBottom={1}
                  gutterBottom
                  title={orderedItem.title}
                  variant="h5"
                  component="div"
                >
                  {orderedItem.title}
                </Typography>
                <Stack flexDirection="row" justifyContent="space-between">
                  <Typography gutterBottom variant="subtitle1" component="p">
                    <strong>Price: </strong> {orderedItem?.price ?? <Label>Not provided</Label>}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" justifyContent="start">
                  <Typography gutterBottom variant="subtitle1" component="p">
                    <strong> SKU: </strong>
                    {orderedItem?.sku ?? <Label>Not provided</Label>}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" justifyContent="start">
                  <Typography gutterBottom variant="subtitle1" component="p">
                    <strong> UPC: </strong>
                    {orderedItem?.upc ?? <Label>Not provided</Label>}
                  </Typography>
                </Stack>
                <Divider />
                <Typography gutterBottom variant="subtitle1" component="p">
                  <strong>Quantity:</strong> {updatedItem?.quantity ?? orderedItem.quantity}
                </Typography>
                {type === 'edit' && (
                  <Typography gutterBottom variant="subtitle1" component="p">
                    <Button
                      style={{ marginRight: 10 }}
                      variant="outlined"
                      size="small"
                      onClick={onUpdateItemQuantity.bind(
                        this,
                        orderedItem.product_id,
                        orderedItem.quantity - 1
                      )}
                    >
                      <Icon icon="akar-icons:minus" />
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={onUpdateItemQuantity.bind(
                        this,
                        orderedItem.product_id,
                        orderedItem.quantity + 1
                      )}
                    >
                      <Icon icon="akar-icons:plus" />
                    </Button>
                  </Typography>
                )}
              </CardContent>

              {orderedItem.personalization && (
                <PersonalizationItem
                  color={orderedItem.personalization.color}
                  imageURL={orderedItem.personalization.url}
                  title={orderedItem.personalization.title}
                  type={orderedItem.personalization.type}
                  text={orderedItem.personalization.text}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
);
