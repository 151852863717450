import { query } from 'src/utils/query';

export const getDiscountsRequest = (params) => {
  return query({
    uri: 'api/v1/admin/discounts',
    auth: true,
    method: 'GET',
    params
  });
};
