import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import toast, { Toaster } from 'react-hot-toast';
import { updateCustomerRequest } from 'src/services/customer/updateCustomerRequest.service';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

export default function CustomerMenu({ customerId, onGetCustomers }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const changeStatusHandler = (status) => {
    const PAYLOAD = {
      is_blocked: status === 'ban'
    };
    setIsOpen(false);
    updateCustomerRequest(PAYLOAD, customerId)
      .then(() => {
        toast.success('Customer status changed successfully', { duration: 2000 });
        onGetCustomers()
      })
      .catch(() => {
        toast.error('Something went wrong', { duration: 2000 });
      });
  };

  const goToOrdersPage = () => {
    navigate(`/customers/${customerId}/orders`);
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          component={RouterLink}
          to={'/customers/edit/' + customerId}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="View / Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem onClick={goToOrdersPage} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon="icon-park-outline:transaction-order" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="View Orders" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem onClick={changeStatusHandler.bind(this, 'ban')} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon="fe:disabled" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Ban" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          onClick={changeStatusHandler.bind(this, 'unban')}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon="akar-icons:chat-approve" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="UnBan" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
