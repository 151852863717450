import { query } from "src/utils/query";

/** update a single category on the database */
export const updateCategoryRequest = (payload, categoryId) => {
  return query({
    uri: 'api/v1/admin/categories/' + categoryId + '/',
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
