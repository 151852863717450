import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';

// material
import {
  Stack,
  TextField,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { useEffect } from 'react';
import { updateCategoryRequest } from 'src/services/categories/updateCategoryRequest.service';
import Loader from '../loader/Loader';
import { mapServerErrorsToUIErrors } from 'src/utils/mapServerErrorsToUiErrors';
import Swal from 'sweetalert2';
import { updateDesignRequest } from 'src/services/designs/updateDesignRequest.service';

// ----------------------------------------------------------------------
const formikValues = {
  title: '',
};

const editCategorySchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
});

export default function EditDesignForm({ category }) {
  const navigate = useNavigate();
  const { design_id: categoryId } = useParams();

  const [filters, setFilters] = useState([]);
  const [filterToBeEdited, setFilterToBeEdited] = useState(null);
  const [filter, setFilter] = useState(null);

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({
    id: '',
    url: ''
  });

  const handleUpdateCategory = (values, { setErrors }) => {
    const PAYLOAD = {
      title: { EN: values.title },
      file_id: image.id,
    };

    updateDesignRequest(PAYLOAD, categoryId)
      .then((res) => {
        toast.success('design updated successfully', { duration: 3000 });
        navigate('/design');
      })
      .catch(({ data }) => {
        mapServerErrorsToUIErrors(data.detail, setErrors);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      });
  };

  const formik = useFormik({
    initialValues: formikValues,
    validationSchema: editCategorySchema,
    onSubmit: handleUpdateCategory
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  const handleUploadCategoryImage = (event) => {
    const file = event.target.files[0];

    setLoading(true);
    uploadFileRequest(file)
      .then(({ data }) => {
        setImage({ id: data.id, url: data.file_url });
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (category) {
      setImage({
        url: category?.image?.file_url,
        id: category?.image?.id
      });

      setValues({
        title: category?.title?.EN ?? '',
      });
    }
  }, [category]);

  return (
    <FormikProvider value={formik}>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Form autoComplete="off" style={{ position: 'relative' }} noValidate onSubmit={handleSubmit}>
        {!category?.title && <Loader />}

        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
            fullWidth
            autoComplete="title"
            type="text"
            label="Design Title"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
        </Stack>
   
        <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
          <label style={{ margin: '0', width: '100%' }} htmlFor="image_urls">
            <input
              onChange={handleUploadCategoryImage}
              accept="image/*"
              style={{ display: 'none' }}
              id="image_urls"
              name="image_urls"
              type="file"
            />
            <Button fullWidth variant="contained" component="span">
              Upload design image
            </Button>
          </label>
          {image.url && (
            <img
              style={{ margin: '1rem auto 0', width: '300px', objectFit: 'cover' }}
              src={image.url}
              alt="product image"
            />
          )}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={loading}
          loading={loading}
          className='save-btn'

        >
          Save
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
