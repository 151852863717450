import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextField
} from '@mui/material';
//
import Scrollbar from '../Scrollbar';
import ColorManyPicker from '../ColorManyPicker';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { isValidDate } from 'src/utils/isValidDate';
import { useState } from 'react';
import { debounce } from 'lodash';

// ----------------------------------------------------------------------

export const FILTER_STATUS_OPTIONS = [
  'In progress',
  'Cancelled',
  'Delivered',
  'Payment failed',
  'Rejected',
  'Returned',
  'Delivery order created',
  'Returning process',
  'Order placed',
  'waiting for payment',
  'Personalized',
  'Got label',
  'Labeled to delivery',
  'Sent for delivery'
];

export const FILTER_PAYMENT_TYPE = [
  { value: 'online', label: 'Online' },
  { value: 'COD', label: 'In place' }
];

// ----------------------------------------------------------------------

OrderFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object
};

export default function OrderFilterSidebar({
  isOpenFilter,
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  filters,
  onFilterChange
}) {
  const [fromDateTime, setFromDateTime] = useState();
  const [toDateTime, setToDateTime] = useState();
  const debouncedOnFilterChange = debounce(onFilterChange, 300);
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Icon icon="ic:round-filter-list" />}
        onClick={onOpenFilter}
      >
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={isOpenFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' }
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Icon icon="eva:close-fill" width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Date
              </Typography>
              <Stack gap={2}>
                <DesktopDatePicker
                  label="From datetime"
                  inputFormat="MM/dd/yyyy"
                  value={fromDateTime || null}
                  minDate={new Date(2022, 0, 1)}
                  maxDate={new Date()}
                  onChange={(date) => {
                    const fromDate = new Date(
                      new Date(date.getFullYear(), date.getMonth(), date.getDate()).setUTCHours(
                        0,
                        0,
                        1,
                        0
                      )
                    ).toISOString();

                    setFromDateTime(date);

                    debouncedOnFilterChange({
                      ...filters,
                      from: fromDate
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      fullWidth
                      {...params}
                    />
                  )}
                />
                <DesktopDatePicker
                  label="To datetime"
                  inputFormat="MM/dd/yyyy"
                  value={toDateTime || null}
                  minDate={fromDateTime}
                  maxDate={new Date()}
                  onChange={(date) => {
                    setToDateTime(date);
                    debouncedOnFilterChange({
                      ...filters,
                      to: new Date(date.setUTCHours(23, 23, 59, 0)).toISOString()
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </Stack>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Payment type
              </Typography>
              <FormGroup>
                {FILTER_PAYMENT_TYPE.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    control={
                      <Checkbox
                        checked={filters.payment_type?.includes(item.value)}
                        onChange={({ target }) => {
                          const { checked } = target;
                          if (checked) {
                            onFilterChange({
                              ...filters,
                              payment_type: [...(filters.payment_type ?? []), item.value]
                            });
                          } else {
                            onFilterChange({
                              ...filters,
                              payment_type: filters.payment_type.filter(
                                (payment_type) => payment_type !== item.value
                              )
                            });
                          }
                        }}
                        value={item.value}
                      />
                    }
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Delivery
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!filters.delivery_in_store}
                      onChange={({ target }) => {
                        const { checked } = target;
                        onFilterChange({
                          ...filters,
                          delivery_in_store: checked
                        });
                      }}
                    />
                  }
                  label="Delivery in store"
                />
              </FormGroup>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Status
              </Typography>
              <FormGroup>
                {FILTER_STATUS_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        checked={filters.status?.includes(item)}
                        onChange={({ target }) => {
                          const { checked } = target;
                          if (checked) {
                            onFilterChange({
                              ...filters,
                              status: [...filters.status, item]
                            });
                          } else {
                            onFilterChange({
                              ...filters,
                              status: filters.status.filter((status) => status !== item)
                            });
                          }
                        }}
                        value={item}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </div>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={onResetFilter}
            startIcon={<Icon icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </LocalizationProvider>
  );
}
