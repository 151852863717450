import { query } from 'src/utils/query';

export const createNewQuestionsRequest = (payload) => {
  return query({
    uri: 'api/v1/admin/questions',
    auth: true,
    method: 'POST',
    data: payload
  });
};
