import { apiUrl } from 'src/configs';
import { db } from 'src/utils/db';

/** Fetch order label PDF (in binary type)  */
export const getDeliveryLabelRequest = (orderId, additionalConfigs) => {
  const configs = {
    headers: {
      authorization: 'Bearer ' + db.get('tokens')?.['access_token']
    },
    method: 'GET',
    ...additionalConfigs
  };
  return fetch(`${apiUrl}api/v1/admin/orders/${orderId}/delivery-label`, configs).then(
    (response) => response.blob()
  );
};
