import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import DateAdapter from '@mui/lab/AdapterDateFns';

// material
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  ImageListItem,
  ImageList,
  MenuItem,
  Autocomplete,
  Checkbox,
  createFilterOptions
} from '@mui/material';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { apiUrl } from 'src/configs';
import { useEffect } from 'react';
import { createNewDiscountRequest } from 'src/services/discounts/createNewDiscountRequest.service';
import { countDecimals } from 'src/utils/decimalCounts';
import Swal from 'sweetalert2';
import Label from '../Label';
import { createSEORequest } from 'src/services/SEO/createSeo';

// ----------------------------------------------------------------------

const initialValues = {
  name: '',
  url_path: '',
  title: '',
  description: '',
  keyword: ''
};

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.title + option.product_code
});

const addDiscountSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  url_path: Yup.string().required('Path is required'),
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  keyword: Yup.string().required('Keyword is required')
});

export default function AddSEOForm({ products = [], categories = [] }) {
  const navigate = useNavigate();
  const [entityIds, setEntityIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDateTime, setFromDateTime] = useState();

  const handleAddDiscount = (values) => {
    console.log(values);
    const PAYLOAD = {
      name: values.name,
      url_path: values.url_path,
      title: values.title,
      description: values.description,
      keyword: values.keyword
    };

    setLoading(true);
    createSEORequest(PAYLOAD)
      .then((res) => {
        toast.success('SEO added successfully', { duration: 3000 });
        navigate('/seo');
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: addDiscountSchema,
    onSubmit: handleAddDiscount
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              inputProps={{ maxLength: 50 }}
              {...getFieldProps('name')}
              focused={values?.name}
              fullWidth
              autoComplete="title"
              type="text"
              label="SEO Name *"
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              inputProps={{ maxLength: 400 }}
              {...getFieldProps('url_path')}
              focused={values?.url_path}
              fullWidth
              autoComplete="title"
              type="text"
              label="SEO path *"
              error={Boolean(touched.url_path && errors.url_path)}
              helperText={touched.url_path && errors.url_path}
            />
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              inputProps={{ maxLength: 50 }}
              {...getFieldProps('title')}
              focused={values?.title}
              fullWidth
              autoComplete="title"
              type="text"
              label="SEO title *"
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
            <TextField
              inputProps={{ maxLength: 50 }}
              {...getFieldProps('description')}
              focused={values?.description}
              fullWidth
              autoComplete="title"
              type="text"
              label="SEO description *"
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
          </Stack>

          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              inputProps={{ maxLength: 50 }}
              {...getFieldProps('keyword')}
              focused={values?.keyword}
              fullWidth
              autoComplete="title"
              type="text"
              label="SEO keyword *"
              error={Boolean(touched.keyword && errors.keyword)}
              helperText={touched.keyword && errors.keyword}
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
            className="save-btn"
          >
            Save
          </LoadingButton>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
}
