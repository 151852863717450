import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography, Tooltip, IconButton } from '@mui/material';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { useEffect, useState } from 'react';
import { getSingleNewsRequest } from 'src/services/news/getSingleNewsRequest.service';
import EditNewsForm from 'src/components/news/editNewsForm';
import { getSingleBlogRequest } from 'src/services/blogs/getSingleBlogRequest.service';
import EditBlogForm from 'src/components/blog/editBlogForm';
import { getCustomersRequest } from 'src/services/customer/getCustomersRequest.service';
import { ArrowBack } from '@mui/icons-material';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function EditBlog() {
  const { blog_id } = useParams();
  const [blog, setBlog] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getBlog();
  }, [blog_id]);

  const getBlog = () => {
    getSingleBlogRequest(blog_id)
      .then(({ data }) => {
        setBlog(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <RootStyle title="Edit blog">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Let's edit this blog
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="news" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Stack alignItems="start" sx={{ mb: 5 }}>
            <Tooltip placement="top" title="Go back">
              <IconButton onClick={() => navigate('/blogs')}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h4" gutterBottom>
              Here you can edit your blog
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <EditBlogForm blog={blog} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
