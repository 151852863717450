import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Tooltip, IconButton } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { useEffect, useState } from 'react';
import EditCouponForm from 'src/components/Coupons/EditCouponForm';
import { getCouponsRequest } from 'src/services/coupons/getCouponsRequst.service';
import { getCouponRequest } from 'src/services/coupons/getCouponRequest.service';
import AddCouponForm from 'src/components/Coupons/AddCouponForm';
import { getCustomersRequest } from 'src/services/customer/getCustomersRequest.service';
import { ArrowBack } from '@mui/icons-material';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function AddCoupon() {
  const [users, setUsers] = useState([]);
  const [couponCodes, setCouponCodes] = useState([]);

  const navigate = useNavigate();

  const getUsers = () => {
    getCustomersRequest({ limit: 400 }).then(({ data }) => {
      setUsers(data.result);
    });
  };

  const getCoupons = () => {
    getCouponsRequest({ limit: 500 }).then(({ data }) => {
      setCouponCodes(data.result.map((coupon) => coupon.code));
    });
  };

  useEffect(() => {
    getUsers();
    getCoupons();
  }, []);

  return (
    <RootStyle title="Add Coupon">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Let's Add your coupon
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Stack alignItems="start" sx={{ mb: 5 }}>
            <Tooltip placement="top" title="Go back">
              <IconButton onClick={() => navigate('/coupons')}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h4" gutterBottom>
              Here you can Add your coupon
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <AddCouponForm users={users} couponCodes={couponCodes} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
