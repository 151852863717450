import { query } from "src/utils/query";

/** Add a category to the database */
export const addCategoryRequest = (payload) => {
  return query({
    uri: 'api/v1/admin/categories',
    auth: true,
    method: 'POST',
    data: payload
  });
};
