import * as Yup from 'yup';
import { useRef, useState } from 'react';
import Axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import { ColorPicker } from 'material-ui-color';

// material
import {
  Stack,
  TextField,
  Button,
  FormControlLabel, MenuItem, createFilterOptions,
  Chip,
  List,
  FormLabel
} from '@mui/material';
import { Autocomplete, LoadingButton } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { updateProductRequest } from 'src/services/products/updateProductRequest.service';
import { useEffect, memo } from 'react';
import Loader from '../loader/Loader';
import DraggableImageItem from './DraggableImageItem';
import { reorder } from 'src/utils/strings';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import JoditEditor from 'jodit-react';
import Swal from 'sweetalert2';
import { mapServerErrorsToUIErrors } from 'src/utils/mapServerErrorsToUiErrors';
import { isObjectEmpty } from 'src/utils/objects';
import Label from '../Label';

const filter = createFilterOptions();

const personalizationOptions = [
  { label: 'Text', value: 'TEXT' },
  { label: 'Photo', value: 'PHOTO' },
  { label: 'Design', value: 'DESIGN' }

];

const carouselTagsOptions = [
  'New product',
  'Personalizable product',
  'Best selling product',
  'Our suggestion'
];

const productStatus = [
  {
    label: 'Published',
    value: 'published'
  },
  {
    label: 'Rejected',
    value: 'rejected'
  }
];

// ----------------------------------------------------------------------
const formikValues = {
  title: '',
  subtitle: '',
  description: '',
  background_color: '#ff0000',
  file_url: '',
  image_urls: [],
  overview_urls: [],
  file_ids: [],
  personalization: [], // "TEXT" | "PHOTO"
  price: '',
  base_file_id: '',
  weight: '',
  category_id: '',
  product_status: 'published',
  testimonials: '',
  stock_quantity: 1,
  sku: '',
  product_code: '',
  carousel_tags: [],
  related_product_title: '',
  uom: '',
  units: '',
  rating:5
};

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.title + option.upc
});

const editProductSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  subtitle: Yup.string().required('Subtitle is required'),
  description: Yup.string(),
  background_color: Yup.string().required('Background color is required'),
  personalization: Yup.array().nullable(),
  price: Yup.number()
    .positive('Price must be positive')
    .required('Price is required')
    // .integer('Price must be an integer')
    .moreThan(0, 'Price must be more than 0')
    .test('price', 'Price could have only 2 digits after decimal or less', (number) =>
      /^\d+(\.\d{1,2})?$/.test(number)
    ),
  weight: Yup.number().moreThan(0, 'Weight must be more than 0').required('Weight is required'),
  category_id: Yup.string().required('Category is required'),
  tags: Yup.array(),
  stock_quantity: Yup.number().required('Stock Quantity is required'),
  rating: Yup.number().required('Rating is required'),
  related_flavor: Yup.array(),
  related_product_title: Yup.string().required('Related product title is required'),
  product_code: Yup.string().required('UPC is required'),
  carousel_tags: Yup.array(),
  product_status: Yup.string().required('Product Status is required'),
  sku: Yup.string().required('SKU is required'),
  uom: Yup.string().nullable(),
  units: Yup.string().nullable()
});

export default memo(function EditProductForm({
  product = {},
  allCategories = [],
  tags = [],
  relatedProducts = [],
  alldesigns=[]
}) {
  const navigate = useNavigate();
  const { product_id: productId } = useParams();

  const editor = useRef(null);

  const [content, setContent] = useState('');

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    buttons: 'bold,|,ul,|,undo,redo',
    toolbarAdaptive: false,
    placeholder: 'Product description',
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false
  };

  const handleUpdateProduct = (values, { setErrors }) => {
    const PAYLOAD = {
      ...values,
      ...images,
      category_id: values.category_id,
      background_color: selectedBackgroundColor?.css?.backgroundColor || selectedBackgroundColor,
      title: { EN: values.title },
      description: { EN: content },
      //  testimonials: [values.testimonials],
      subtitle: { EN: values.subtitle },
      personalization: values.personalization.map((p) => p.value)
    };

    if (!images.overview_urls || !images.image_urls) {
      toast.error('Please upload all the required files', { duration: 2000 });
      return;
    }

    setLoading(true);
    updateProductRequest(PAYLOAD, productId)
      .then((res) => {
        toast.success('Product updated successfully', { duration: 3000 });
        navigate('/products');
      })
      .catch(({ data }) => {
        Swal.fire({
          title: 'Error',
          text: data.message.replace('product_code', 'Product Code'),
          icon: 'error'
        });
        mapServerErrorsToUIErrors(data.detail, setErrors);
        setLoading(false);
        // toast.error(data.message, { duration: 6000 });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: formikValues,
    validationSchema: editProductSchema,
    onSubmit: handleUpdateProduct
  });

  const { errors, touched, getFieldProps, values, setValues, setErrors } = formik;

  const [loading, setLoading] = useState(false);
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState('#ff0000');

  const [images, setImages] = useState({
    image_urls: [],
    overview_urls: []
  });

  const handleUploadProductImages = (event) => {
    const files = Array.from(event.target.files);

    setLoading(true);
    const requests = files.map((file) => uploadFileRequest(file));

    Axios.all(requests)
      .then((res) => {
        const fileUls = res.map((r) => r.data.file_url);
        setImages({ ...images, image_urls: fileUls });
      })
      .catch((err) => {
        console.trace(err);
      })
      .finally(() => setLoading(false));
  };

  const handleUploadProductOverviewImages = (event) => {
    const files = [...event.target.files];

    if (files.length > 3) {
      toast.error('Please upload only 3 images', { duration: 4000 });
      return;
    }

    setLoading(true);
    const requests = files.map((file) => uploadFileRequest(file));
    debugger;
    Axios.all(requests)
      .then((res) => {
        const fileUls = res.map((r) => r.data.file_url);
        setImages({ ...images, overview_urls: fileUls });
      })
      .catch((err) => {
        console.trace(err);
      })
      .finally(() => setLoading(false));
  };

  const onDragEnd = ({ destination, source }, targetState) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(images[targetState], source.index, destination.index);

    setImages({ ...images, [targetState]: newItems });
  };

  useEffect(() => {
    if (!isObjectEmpty(product)) {
      setImages({
        image_urls: product?.image_urls,
        overview_urls: product?.overview_urls
      });
      setSelectedBackgroundColor(product?.background_color);
      setContent(product?.description?.EN ?? product?.description ?? '');
      setValues({
        title: product?.title ?? '',
        subtitle: product?.subtitle ?? '',
        background_color: product?.background_color ?? 'red',
        personalization: product?.personalization?.map((p) => ({
          label: p === 'TEXT' ? 'Text' :p === 'PHOTO'?'Photo':'Design',
          value: p
        })),
        stock_quantity: product?.stock_quantity ?? 0,
        price: product.price ?? 0,
        weight: product?.weight ?? 0,
        uom: product?.uom ?? '',
        units: product?.units ?? 0,
        category_id: product?.categories?.[0]?.id,
        product_status: product?.product_status ?? 'published',
        // testimonials: product?.testimonials?.[0]?.id || '',
        tags: product?.tags || [],
        carousel_tags: product?.carousel_tags || [],
        rating: product?.rating ||5,
        design:  product?.designs[0]?.id!=null ? product?.designs.map((item) => ({
          title: item?.title ,
          id: item?.id,
          image_url: item?.images,
        })) : [],
        product_code: product?.product_code ?? '',
        related_product_title: product?.related_product_title ?? 'See favorite flavor',
        sku: product?.sku ?? '',
        related_product:
          product?.related_product.map((item) => ({
            title: item.title ?? item.flavor,
            product_id: item.product_id,
            image_url: item.image_urls?.[0],
            upc: item.upc ?? 'Not provided'
          })) || []
      });
    }
  }, [product]);

  return (
    <FormikProvider value={formik}>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Form autoComplete="off" noValidate style={{ position: 'relative' }}>
        {isObjectEmpty(product) && <Loader />}
        <Stack direction={{ xs: 'column', lg: 'row' }} position="relative" spacing={3}>
          {isObjectEmpty(product) && <Loader />}
          <TextField
            inputProps={{ maxLength:50}}
            fullWidth
            autoComplete="title"
            type="text"
            label="Product Title"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />

          <TextField
            inputProps={{ maxLength:50}}
            fullWidth
            autoComplete="current-password"
            type="text"
            label="Subtitle"
            {...getFieldProps('subtitle')}
            error={Boolean(touched.subtitle && errors.subtitle)}
            helperText={touched.subtitle && errors.subtitle}
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} position="relative" spacing={3}>
          {isObjectEmpty(product) && <Loader />}
          {/* <TextField
            fullWidth
            autoComplete="description"
            type="text"
            label="Product description"
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          /> */}
          <TextField
            fullWidth
            label="Price"
            error={Boolean(touched.price && errors.price)}
            helperText={touched.price && errors.price}
            {...getFieldProps('price')}
            type="number"
            size="small"
            onWheel={() => document.activeElement.blur()} 
          />
{/* 
          <FormControlLabel
            error={Boolean(touched.background_color && errors.background_color)}
            helperText={touched.background_color && errors.background_color}
            {...getFieldProps('background_color')}
            style={{ width: '100%' }}
            control={
              <ColorPicker
                hideTextfield
                value={selectedBackgroundColor}
                onChange={setSelectedBackgroundColor}
              />
            }
            label="Background color"
          /> */}
          {/** Clear color button */}
          {/* <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setSelectedBackgroundColor('#00000000')}
            style={{
              position: 'absolute',
              top: '0.1rem',
              right: '0.2rem',
              zIndex: '1'
            }}
          >
            Clear
          </Button> */}
        </Stack>
        <Stack my="1rem" direction="column" position="relative" spacing={1}>
          <FormLabel>Description</FormLabel>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column' }} position="relative" spacing={3}>
          <label style={{ margin: '0', width: '100%' }} htmlFor="image_urls">
            <input
              onChange={handleUploadProductImages}
              accept="image/*"
              style={{ display: 'none' }}
              multiple
              id="image_urls"
              name="image_urls"
              type="file"
            />
            <Button fullWidth variant="contained" component="span">
              Upload product images
            </Button>
          </label>

          {images.image_urls.length > 0 && (
            <DragDropContext onDragEnd={(dndProps) => onDragEnd(dndProps, 'image_urls')}>
              <Droppable direction="horizontal" droppableId="images-droppable">
                {(provided) => (
                  <List
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                      gap: '4px',
                      flexWrap: 'wrap'
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {images.image_urls.map((item, index) => (
                      <DraggableImageItem key={item} index={index} url={item} />
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column' }} position="relative" spacing={3}>
          {isObjectEmpty(product) && <Loader />}
          <label style={{ margin: '0', width: '100%' }} htmlFor="overview_urls">
            <input
              onChange={handleUploadProductOverviewImages}
              accept="image/*"
              style={{ display: 'none' }}
              multiple
              id="overview_urls"
              name="overview_urls"
              type="file"
            />
            <Button fullWidth variant="contained" component="span">
              Upload product overview images
            </Button>
          </label>
          {images.overview_urls.length > 0 && (
            <DragDropContext onDragEnd={(dndProps) => onDragEnd(dndProps, 'overview_urls')}>
              <Droppable direction="horizontal" droppableId="overview-droppable">
                {(provided) => (
                  <List
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                      flexWrap: 'wrap',
                      gap: '4px'
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {images.overview_urls.map((item, index) => (
                      <DraggableImageItem key={item} index={index} url={item} />
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} position="relative" spacing={3}>
          {isObjectEmpty(product) && <Loader />}
          <Autocomplete
            multiple
            fullWidth
            disabled={!values?.personalization}
            id="Personalization"
            options={personalizationOptions}
            value={values?.personalization}
            getOptionLabel={(option) => option?.label}
            filterSelectedOptions
            onChange={(event, newValue) => {
              const uniqueValues = Array.from(new Set(newValue.map((a) => a.value))).map(
                (value) => {
                  return newValue.find((a) => a.value === value);
                }
              );
              setValues({ ...values, personalization: uniqueValues });
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                error={Boolean(touched.personalization && errors.personalization)}
                helperText={touched.personalization && errors.personalization}
                {...params}
                label="Personalization"
                placeholder="Personalization"
              />
            )}
          />
          <Autocomplete
            multiple
            fullWidth
            disabled={!values?.carousel_tags}
            id="Carousel"
            options={carouselTagsOptions}
            value={values?.carousel_tags}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            onChange={(event, newValue) => {
              setValues({ ...values, carousel_tags: newValue });
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                error={Boolean(touched.carousel_tags && errors.carousel_tags)}
                helperText={touched.carousel_tags && errors.carousel_tags}
                {...params}
                label="Carousel type(s)"
                placeholder="Carousel"
              />
            )}
          />
       
       {values.personalization.some(item => item.value === "DESIGN") &&   <Autocomplete
            multiple
            fullWidth
            filterOptions={filterOptions}
            options={
              alldesigns?.map((t) => {
                return {
                  title: t.title,
                  id: t.id,
                  image_url: t?.image?.thumbnail_url,
                };
              }) || []
            }
            value={values?.design ?? []}
            getOptionLabel={(option) =>
              (
                <>
                  <span title={option?.title} className="one-line">
                    {option?.title + ' '}
                  </span>
                </>
              ) ?? option?.flavor
            }
            isOptionEqualToValue={(option, value) => option.title === value.title}
            onChange={(event, newValue) => {

              const newRelateddDesigns = newValue.map((flavor) => ({
                title: flavor.title ,
                id: flavor.id ,
                image_url: flavor?.image_url,
              }));

              setValues({ ...values, design: newRelateddDesigns });
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Designs" placeholder="Designs" />
            )}
          />}
       
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} position="relative" spacing={3}>
          <TextField
            fullWidth
            label="Weight"
            error={Boolean(touched.weight && errors.weight)}
            helperText={touched.weight && errors.weight}
            {...getFieldProps('weight')}
            type="number"
            size="small"
            onWheel={() => document.activeElement.blur()} 
          />

          <TextField
            fullWidth
            label="Category"
            error={Boolean(touched.category_id && errors.category_id)}
            helperText={touched.category_id && errors.category_id}
            {...getFieldProps('category_id')}
            select
            size="small"
          >
            {allCategories?.map(({ id, title }) => (
              <MenuItem key={id} value={id}>
                {title}
              </MenuItem>
            ))}
          </TextField>
          {/* <TextField
            fullWidth
            label="Rating"
            error={Boolean(touched.rating && errors.rating)}
            helperText={touched.rating && errors.rating}
            {...getFieldProps('rating')}
            select
            size="small"
          >
            
               <MenuItem key={1} value={1}>
                {1}
              </MenuItem>
              <MenuItem key={2} value={2}>
                {2}
              </MenuItem>
              <MenuItem key={3} value={3}>
                {3}
              </MenuItem>
              <MenuItem key={4} value={4}>
                {4}
              </MenuItem>
              <MenuItem key={5} value={5}>
                {5}
              </MenuItem>
          </TextField> */}
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} position="relative" spacing={3}>
          {/* {product && <Loader />} */}

          <TextField
            fullWidth
            label="uom"
            type="string"
            {...getFieldProps('uom')}
            size="small"
            InputProps={{
              readOnly: true
            }}
          />

          <TextField
            fullWidth
            label="units"
            type="number"
            {...getFieldProps('units')}
            size="small"
            InputProps={{
              readOnly: true
            }}
            onWheel={() => document.activeElement.blur()} 
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          
          <TextField
            fullWidth
            label="UPC"
            {...getFieldProps('product_code')}
            InputProps={{
              readOnly: true
            }}
            type="string"
            size="small"
          />
          <TextField
            fullWidth
            label="SKU"
            {...getFieldProps('sku')}
            InputProps={{
              readOnly: true
            }}
            type="string"
            size="small"
          />
          {/* <TextField
            fullWidth
            label="Stock Quantity"
            error={Boolean(touched.stock_quantity && errors.stock_quantity)}
            helperText={touched.stock_quantity && errors.stock_quantity}
            {...getFieldProps('stock_quantity')}
            type="number"
            min={1}
            size="small"
          /> */}
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <Autocomplete
            multiple
            fullWidth
            disabled={!values?.tags}
            id="tags-filled"
            options={tags ?? []}
            value={values?.tags?.map((t) => t) || []}
            getOptionLabel={(option) => option?.title}
            //  freeSolo
            onChange={(event, newValue) => {
              const newTags = [...new Set(newValue?.map((t) => t.title || t) || [])];
              setValues({ ...values, tags: newTags });
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
          />
          <TextField
            inputProps={{ maxLength:50}}
            fullWidth
            label="Related Product Title"
            error={Boolean(touched.related_product_title && errors.related_product_title)}
            helperText={touched.related_product_title && errors.related_product_title}
            {...getFieldProps('related_product_title')}
            type="string"
            size="medium"
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <Autocomplete
            multiple
            fullWidth
            filterOptions={filterOptions}
            options={
              relatedProducts?.map((t) => {
                return {
                  // title: t.title.slice(0, 20),
                  title: t.title,
                  product_id: t.id,
                  image_url: t.image_urls?.[0],
                  upc: t.product_code
                };
              }) || []
            }
            value={values?.related_product ?? []}
            getOptionLabel={(option) =>
              (
                <>
                  <span title={option?.title} className="one-line">
                    {option?.title + ' '}
                  </span>
                  <Label children={option.upc} />
                </>
              ) ?? option?.flavor
            }
            isOptionEqualToValue={(option, value) => option.title === value.title}
            onChange={(event, newValue) => {
              // console.log("newValue related_product",newValue)

              const newRelatedProducts = newValue.map((flavor) => ({
                title: flavor.title || flavor.flavor,
                product_id: flavor.id || flavor.product_id,
                image_url: flavor.image_url,
                upc: flavor.upc
              }));

              setValues({ ...values, related_product: newRelatedProducts });
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Related products" placeholder="Products" />
            )}
          />

          <TextField
            fullWidth
            label="Product status"
            error={Boolean(touched.product_status && errors.product_status)}
            helperText={touched.product_status && errors.product_status}
            {...getFieldProps('product_status')}
            select
            size="medium"
          >
            {productStatus?.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={loading}
          loading={loading}
          className='save-btn'

        >
          Save
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
});
