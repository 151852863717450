import { apiUrl } from 'src/configs';

export const downloadCSVFile = (fileURL, fileName = 'CSV_EXPORT') => {
  const link = document.createElement('a');
  link.href = apiUrl + fileURL;

  link.download = `${fileName}.csv`;

  link.click();
  link.remove();
};
