import { debounce, filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { fDateTimeSuffix, fDateTime, fDate, fToNow } from '../utils/formatTime';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Badge
} from '@mui/material';
import Label from '../components/Label';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ProductsListHead,
  ProductsListToolbar,
  ProductsMoreMenu
} from '../components/_dashboard/products';
//
import USERLIST from '../_mocks_/user';
import { getProductsRequest } from 'src/services/products/getProductsRequest.service';
import { apiUrl, tableConfigs } from 'src/configs';
import { getTestimonialsRequest } from 'src/services/testimonials/getTestimonialsRequest.service';
import { getCouponsRequest } from 'src/services/coupons/getCouponsRequst.service';
import CouponsMoreMenu from 'src/components/Coupons/Menu';
import { getCategoriesRequest } from 'src/services/categories/getCategoriesRequest.service';
import { getOrdersRequest } from 'src/services/orders/getOrdersRequest.service';
import OrdersMoreMenu from 'src/components/orders/Menu';
import OrderFilterSidebar from 'src/components/orders/OrderFilterSidebar';
import { downloadCSVFile } from 'src/utils/downloadCSV';
import { downloadXMLFile } from 'src/utils/downloadXML';
import { getOrdersXMLRequest } from 'src/services/orders/getOrdersXMLRequest.service';
import { getOrdersCSVRequest } from 'src/services/orders/getOrdersCSVRequest.service';
import SearchBar from 'src/components/_dashboard/blog/BlogPostsSearch';
import { LoadingButton } from '@mui/lab';
import Loader from 'src/components/loader/Loader';
import { range } from 'lodash';
import { loaderStyle } from './Products';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'order-code', label: 'Order Code', alignRight: false },
  { id: 'delivery-in-store', label: 'Delivery in store', alignRight: false },
  { id: 'total-price', label: 'Total Price', alignRight: false },
  { id: 'total-items', label: 'Total Items', alignRight: false },
  { id: 'status', label: 'Order Status', alignRight: false },
  { id: 'order-datetime', label: 'Order date', alignRight: false },
  { id: '', label: '', alignRight: false }
];

// ----------------------------------------------------------------------

export default function Orders() {
  const emptyRows = 5 > 0 ? Math.max(0, (1 + 5) * 5 - USERLIST.length) : 0;
  const [selected, setSelected] = useState([]);
  const [orders, setOrders] = useState({
    data: [],
    total: 0
  });

  const [params, setParams] = useState({
    limit: tableConfigs.defaultPageSize, // 50
    offset: 0,
    status: [],
    from: null,
    to: null,
    order_code: null
  });

  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const getOrders = () => {
    setLoading(true);
    getOrdersRequest(params)
      .then(({ data }) => {
        setOrders({
          data: data.result,
          total: data.total
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrders();
  }, [params]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const downloadXMLHandler = () => {
    setLoading(true);
    getOrdersXMLRequest(params)
      .then(({ data }) => {
        downloadXMLFile(data.url, 'orders');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadCSVHandler = () => {
    setLoading(true);
    getOrdersCSVRequest(params)
      .then(({ data }) => {
        downloadCSVFile(data.url, 'orders');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilterChange = debounce((newParams = params) => {
    setParams({ ...newParams });
  }, 500);

  return (
    <Page title="Orders">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Products
          </Button> */}
        </Stack>

        <Card>
          <Stack
            direction="row"
            flexWrap="wrap-reverse"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 3, mx: 3.3 }}
          >
            <SearchBar
              targetState="order_code"
              placeholder="Search in orders"
              filters={params}
              onFilterChange={handleFilterChange}
              isLoading={loading}
            />
            <Stack
              direction="row"
              flexWrap="wrap-reverse"
              alignItems="center"
              justifyContent="flex-end"
              gap={2}
            >
              <LoadingButton
                loading={loading}
                onClick={() => window.location.reload()}
                disableRipple
                color="inherit"
                endIcon={<Icon icon="el:refresh" />}
              />
              <LoadingButton
                loading={loading}
                onClick={downloadXMLHandler}
                disableRipple
                color="inherit"
                endIcon={<Icon icon="foundation:page-export-csv" />}
              >
                Export XML &nbsp;
              </LoadingButton>
              <LoadingButton
                loading={loading}
                onClick={downloadCSVHandler}
                disableRipple
                color="inherit"
                endIcon={<Icon icon="foundation:page-export-csv" />}
              >
                Export CSV &nbsp;
              </LoadingButton>
              <OrderFilterSidebar
                filters={params}
                onFilterChange={setParams}
                isOpenFilter={openFilter}
                onResetFilter={() => {
                  setParams({
                    ...params,
                    status: [],
                    customer_search: null,
                    from_datetime: null,
                    to_datetime: null
                  });
                }}
                onOpenFilter={handleOpenFilter}
                onCloseFilter={handleCloseFilter}
              />
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductsListHead
                  order={'name'}
                  orderBy={'name'}
                  headLabel={TABLE_HEAD}
                  rowCount={params.limit}
                  // numSelected={0}
                  onRequestSort={() => {}}
                  onSelectAllClick={() => {}}
                />
                <TableBody>
                  {loading && (
                    <div style={loaderStyle}>
                      <Loader />
                    </div>
                  )}
                  {orders.data.map((row) => {
                    const {
                      id,
                      total_price,
                      total_items,
                      order_code,
                      create_datetime,
                      order_status,
                      delivery_in_store
                    } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="center">{order_code}</TableCell>
                        <TableCell align="center">
                          <Label
                            style={{ padding: '15px 10px' }}
                            variant="ghost"
                            color={delivery_in_store ? 'success' : 'warning'}
                          >
                            {delivery_in_store ? 'Yes' : 'No'}
                          </Label>
                        </TableCell>
                        <TableCell align="center">{total_price ?? ''}</TableCell>
                        <TableCell align="center">{total_items ?? ''}</TableCell>
                        <TableCell align="center">{order_status ?? ''}</TableCell>
                        <TableCell align="center">{fDate(create_datetime)}</TableCell>
                        <TableCell align="right">
                          <OrdersMoreMenu onGetOrders={getOrders} order={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {orders.total === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={params?.order_code ?? ''} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            SelectProps={{
              disabled: loading
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            rowsPerPageOptions={tableConfigs.pageSizeOptions
              .filter((item) => item <= orders.total)
              .concat(orders.total)}
            component="div"
            count={orders.total}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(_, selected) => {
              setParams({
                ...params,
                offset: selected * params.limit
              });
            }}
            onRowsPerPageChange={({ target }) => {
              const selected = target.value;
              setParams({ ...params, limit: selected });
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}
