import * as Yup from 'yup';
import { useState } from 'react';
import Axios from 'axios';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import { ColorPicker } from 'material-ui-color';

// material
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  ImageListItem,
  ImageList,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  createFilterOptions,
  Chip,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  Divider
} from '@mui/material';
import { Autocomplete, LoadingButton } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { apiUrl } from 'src/configs';
import { updateProductRequest } from 'src/services/products/updateProductRequest.service';
import { useEffect, memo } from 'react';
import { AddCircleOutlined, HdrPlusRounded, PlusOneRounded } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import { createNewQuestionsRequest } from 'src/services/questions/createNewQuestionsRequest.service';
import { flushSync } from 'react-dom';
import Swal from 'sweetalert2';

const filter = createFilterOptions();

// ----------------------------------------------------------------------
const formikValues = {
  title: '',
  items: []
};

const addQuestionsSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  items: Yup.array()
    .of(
      Yup.object().shape({
        question: Yup.string().required('Question is required'),
        answer: Yup.string().required('Answer is required')
      })
    )
    .min(1, 'Questions and answers are required')
    .required('Questions and answers are required')
});

export default memo(function AddQuestionsForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState([]);
  const [optionToBeEdited, setOptionToBeEdited] = useState(null);
  const [option, setOption] = useState({
    question: '',
    answer: '',
    id: ''
  });

  const [image, setImage] = useState({ id: '', url: '' });

  const formik = useFormik({
    initialValues: formikValues,
    validationSchema: addQuestionsSchema,
    onSubmit: (values, { setFieldError }) => {
      const PAYLOAD = {
        ...values,
        image_url: image.url,
        items: options
      };

      if (!image.url) {
        toast.error('Image is required');
        return;
      }

      if (values.items.length === 0) {
        setFieldError('items', 'Questions and answers are required');
        return;
      }

      setLoading(true);
      createNewQuestionsRequest(PAYLOAD)
        .then(() => {
          navigate('/questions');
        })
        .catch(({ data }) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: data?.message || 'something went wrong'
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    setValues,
    setFieldTouched,
    setErrors,
    setFieldError
  } = formik;

  const handleUploadQuestionImage = (event) => {
    const file = event.target.files[0];

    setLoading(true);
    uploadFileRequest(file)
      .then(({ data }) => {
        setImage({ id: data.id, url: data.file_url });
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  const handleAddOrEditOption = () => {
    if (optionToBeEdited) {
      setValues({
        ...values,
        items: values.items.map((item) => {
          if (item.id === optionToBeEdited.id) {
            return {
              ...item,
              question: option.question,
              answer: option.answer
            };
          }
          return item;
        })
      });
      // setOptions(options.map((o) => (option.id === optionToBeEdited.id ? { ...option } : o)));
      setOptions(options.map((o) => (option.id === o.id ? { ...option } : o)));
      setOptionToBeEdited(null);
      setOption({
        question: '',
        answer: '',
        id: ''
      });
    } else {
      setValues({
        ...values,
        items: [...values.items, { ...option }]
      });
      setOptions([...options, { ...option, id: Date.now() }]);
      setOption({
        question: '',
        answer: '',
        id: ''
      });
    }

    setErrors({
      ...errors,
      items: false
    });
  };

  return (
    <FormikProvider value={formik}>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
            fullWidth
            inputProps={{ maxLength: 100 }}
            autoComplete="Title"
            type="text"
            label="Title *"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
        </Stack>

        <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
          <label style={{ margin: '0', width: '100%' }} htmlFor="image_urls">
            <input
              onChange={handleUploadQuestionImage}
              accept="image/*"
              style={{ display: 'none' }}
              id="image_urls"
              name="image_urls"
              type="file"
            />
            <Button fullWidth variant="contained" component="span">
              Upload image
            </Button>
          </label>
          {image.url && (
            <img
              style={{ margin: '1rem auto 0', width: '300px', objectFit: 'cover' }}
              src={image.url}
              alt="questions image"
            />
          )}
        </Stack>
        <Divider style={{ margin: '2rem auto' }} variant="fullWidth">
          <Chip label="Questions" color="primary" />
        </Divider>
        <Stack my="1rem" direction={{ xs: 'row' }} spacing={3}>
          <TextField
            fullWidth
            autoComplete="Question *"
            inputProps={{ maxLength: 100 }}
            type="text"
            label="Question *"
            error={Boolean(touched.items && values.items.length === 0)}
            helperText={errors.items && errors.items}
            value={option.question}
            onChange={(e) => setOption({ ...option, question: e.target.value })}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ maxHeight: '56px' }}
            onClick={handleAddOrEditOption}
            disabled={!option.question || !option.answer}
          >
            {optionToBeEdited ? 'Apply' : 'Add'}
          </Button>
        </Stack>
        <Stack my="1rem" direction={{ xs: 'row' }} spacing={3}>
          <TextField
            autoComplete="Answer *"
            type="text"
            label="Answer *"
            value={option.answer}
            error={Boolean(touched.items && values.items.length === 0)}
            helperText={touched.items && errors.items}
            onChange={(e) => setOption({ ...option, answer: e.target.value })}
            fullWidth
            multiline
            inputProps={{
              maxLength: 500,
              style: {
                minHeight: '40px'
              }
            }}
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
          {options.length !== 0 && (
            <Table>
              <TableHead>
                <TableCell align="left">Actions</TableCell>
                <TableCell align="left">Question</TableCell>
                <TableCell align="left">Answer</TableCell>
              </TableHead>
              <TableBody>
                {options.map((option) => (
                  <TableRow key={option.id}>
                    <TableCell align="left">
                      <Button
                        style={{ margin: '0 3px' }}
                        variant="contained"
                        onClick={() => {
                          setValues((prevValues) => {
                            if (prevValues.items.length === 1) {
                              setFieldTouched('items', true);
                              setFieldError('items', 'Questions and answers are required');
                            }

                            return {
                              ...prevValues,
                              items: prevValues.items.filter(
                                (item) => item.question !== option.question
                              )
                            };
                          });

                          setOptions(options.filter((o) => o.id !== option.id));
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        style={{ margin: '0 3px' }}
                        variant="contained"
                        onClick={() => {
                          setOptionToBeEdited(option);
                          setOption(option);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell align="left">{option.question}</TableCell>
                    <TableCell align="left">{option.answer}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={loading}
          loading={loading}
          className='save-btn'

        >
          Save
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
});
