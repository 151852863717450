import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Tooltip, IconButton } from '@mui/material';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import AddOrderPackForm from 'src/components/orderPacks/AddOrderPackForm';
import { ArrowBack } from '@mui/icons-material';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function AddOrderPack() {
  const navigate = useNavigate();

  return (
    <RootStyle title="Add Order pack">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Let's add a Gift wrapping Pack
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Stack alignItems="start" sx={{ mb: 5 }}>
            <Tooltip placement="top" title="Go back">
              <IconButton onClick={() => navigate('/order-packs')}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h4" gutterBottom>
              Here you can add your Gift wrapping Pack
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <AddOrderPackForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
