import { query } from 'src/utils/query';

export const loginRequest = (payload) => {
  return query({
    uri: 'api/v1/admin/auth/login',
    auth: false,
    method: 'POST',
    data: payload
  });
};
