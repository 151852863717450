import { Icon } from '@iconify/react';
import {
  CardActionArea,
  CardContent,
  Button,
  Card,
  CardMedia,
  Grid,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { memo } from 'react';
import Label from '../Label';

export const PurchasedPickItemCard = memo(({ type, ...orderedItem }) => {
  return (
    <>
      <section className="card-picknmix">
        <div className="card-content">
          <div className="row">
            <div className="col-md-12">
              <h3 className="picknmix-text">{orderedItem?.Type}</h3>
            </div>
          </div>
          <div className="align-item-pick">
            <div className="w-30">
              <div className="card-media-img">
                <img src="/static/icons/pick-mixcartbag.png" alt="" />
              </div>
            </div>
            <div className="w-70">
            {orderedItem?.Type=="Hamper" && <h3 className="picknmix-text">Others</h3>}
              
              <ul>
                {orderedItem?.items?.map((item) => (
                 <>
                 <li>
                    <Accordion className="new-acc">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="card-acc-headtext">{item?.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CardContent className="card-content">
                          <Typography
                            borderBottom="1px solid #e0e0e0"
                            paddingBottom={1}
                            gutterBottom
                            title={orderedItem.title}
                            variant="h5"
                            component="div"
                          ></Typography>
                          <Stack flexDirection="row" justifyContent="space-between">
                            <Typography gutterBottom variant="subtitle1" component="p">
                              <strong>Price: </strong> {item?.price ?? <Label>Not provided</Label>}
                            </Typography>
                          </Stack>
                          <Stack flexDirection="row" justifyContent="start">
                            <Typography gutterBottom variant="subtitle1" component="p">
                              <strong> SKU: </strong>
                              {item?.sku ?? <Label>Not provided</Label>}
                            </Typography>
                          </Stack>
                          <Stack flexDirection="row" justifyContent="start">
                            <Typography gutterBottom variant="subtitle1" component="p">
                              <strong> UPC: </strong>
                              {item?.upc ?? <Label>Not provided</Label>}
                            </Typography>
                          </Stack>
                          <Divider />
                          <Typography gutterBottom variant="subtitle1" component="p">
                            <strong>Quantity:</strong> {item.quantity}
                          </Typography>
                        </CardContent>
                        {/* <Divider /> */}
                      </AccordionDetails>
                    </Accordion>
                  </li>

                 
                  </>
                  
                ))}
                 {orderedItem?.HamperData.map((h,index)=>(
                    <>
              <h3 className="picknmix-text">Pick N Mix {index+1}</h3>
                  {h?.items?.map((l)=>(<>
                  <li>
                    <Accordion className="new-acc">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="card-acc-headtext">{l?.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CardContent className="card-content">
                          <Typography
                            borderBottom="1px solid #e0e0e0"
                            paddingBottom={1}
                            gutterBottom
                            title={orderedItem.title}
                            variant="h5"
                            component="div"
                          ></Typography>
                          <Stack flexDirection="row" justifyContent="space-between">
                            <Typography gutterBottom variant="subtitle1" component="p">
                              <strong>Price: </strong> {l?.price ?? <Label>Not provided</Label>}
                            </Typography>
                          </Stack>
                          <Stack flexDirection="row" justifyContent="start">
                            <Typography gutterBottom variant="subtitle1" component="p">
                              <strong> SKU: </strong>
                              {l?.sku ?? <Label>Not provided</Label>}
                            </Typography>
                          </Stack>
                          <Stack flexDirection="row" justifyContent="start">
                            <Typography gutterBottom variant="subtitle1" component="p">
                              <strong> UPC: </strong>
                              {l?.upc ?? <Label>Not provided</Label>}
                            </Typography>
                          </Stack>
                          <Divider />
                          <Typography gutterBottom variant="subtitle1" component="p">
                            <strong>Quantity:</strong> {l.quantity}
                          </Typography>
                        </CardContent>
                        {/* <Divider /> */}
                      </AccordionDetails>
                    </Accordion>
                  </li>
                  </>))}
                  </>
                  ))}
              </ul>
            </div>
           
          </div>
        </div>
      </section>
    </>
   
  );
});
