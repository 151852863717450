import { query } from 'src/utils/query';

export const createSEORequest = (payload) => {
  return query({
    uri: 'api/v1/admin/seo',
    auth: true,
    method: 'POST',
    data: payload
  });
};
