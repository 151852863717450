import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import EditIcon from '@mui/icons-material/Edit';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import { MenuItem, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { updateCustomerRequest } from 'src/services/customer/updateCustomerRequest.service';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function EditableAddress({
  customerId,
  customerData,
  address,
  addresses,
  loading,
  setLoading
}) {
  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({});

  // To toggle collapse component
  const handleClick = () => setOpen(!open);

  const updateValueHanlder = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    setValues(address);
  }, [address]);

  const updateAddress = () => {
    const addressesWithoutSingleAddress = addresses.filter((item) => item.id !== address.id);

    const PAYLOAD = {
      addresses: [...addressesWithoutSingleAddress, values]
    };
    debugger;

    setLoading(true);
    updateCustomerRequest(PAYLOAD, customerId)
      .then((res) => {
        toast.success('address updated successfully', { duration: 3000 });
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <ListItemButton style={{ width: '100%' }} onClick={handleClick}>
        <ListItemIcon>
          <MarkAsUnreadIcon />
        </ListItemIcon>
        <ListItemText primary={`Postal Code 👉 ${values?.postal_code ?? '0'}`} />
        {open ? <ExpandLess /> : <EditIcon title="Edit" />}
      </ListItemButton>
      <Collapse style={{ width: '100%' }} in={open} timeout="auto" unmountOnExit>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
            inputProps={{ maxLength:30}}
            name="first_name"
            value={values?.first_name}
            fullWidth
            autoComplete="email"
            type="text"
            label="First Name"
            onChange={updateValueHanlder}
          />
          <TextField
          inputProps={{ maxLength:30}}
            name="last_name"
            value={values?.last_name}
            fullWidth
            autoComplete="text"
            type="text"
            label="Last Name"
            onChange={updateValueHanlder}
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
            name="address_line_1"
            inputProps={{ maxLength:70}}
            value={values?.address_line_1}
            fullWidth
            autoComplete="email"
            type="text"
            label="Address"
            onChange={updateValueHanlder}
          />
          <TextField
            inputProps={{ maxLength:15}}
            name="city"
            value={values?.city}
            fullWidth
            autoComplete="text"
            type="text"
            label="City"
            onChange={updateValueHanlder}
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
            inputProps={{ maxLength:4}}
            name="country_code"
            value={values?.country_code}
            fullWidth
            autoComplete="country_code"
            type="text"
            label="Country Code"
            onChange={updateValueHanlder}
          />
          <TextField
            name="phone_number"
            value={values?.phone_number}
            fullWidth
            autoComplete="text"
            type="number"
            label="Phone Number"
            onChange={updateValueHanlder}
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
            name="postal_code"
            value={values?.postal_code}
            fullWidth
            autoComplete="postal_code"
            type="text"
            label="Postal Code"
            onChange={updateValueHanlder}
          />
          <TextField
          inputProps={{ maxLength:30}}
            name="state"
            value={values?.state}
            fullWidth
            autoComplete="text"
            type="text"
            label="State"
            onChange={updateValueHanlder}
          />
        </Stack>
        <Stack my="1rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
          inputProps={{ maxLength:50}}
            name="street"
            value={values?.street}
            fullWidth
            autoComplete="street"
            type="text"
            label="Street"
            onChange={updateValueHanlder}
          />
          <TextField
            fullWidth
            name="type"
            value={values?.type}
            label="Delivery type"
            onChange={updateValueHanlder}
            select
            size="small"
          >
            <MenuItem value="pickup">Pickup</MenuItem>
            <MenuItem value="warehouse">Warehouse</MenuItem>
            <MenuItem value="work">Work</MenuItem>
            <MenuItem value="shipping_address">Shipping Address</MenuItem>
            <MenuItem value="billing_address ">Billing Address </MenuItem>
          </TextField>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={loading}
          loading={loading}
          onClick={updateAddress}
          color="info"
          className='save-btn'

        >
          Save
        </LoadingButton>
      </Collapse>
    </>
  );
}
