import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';

// material
import { Stack, TextField, Button, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { updatePromotionRequest } from 'src/services/promotions/updatePromotionRequest.service';
import { useEffect, memo } from 'react';
import Loader from '../loader/Loader';
import Swal from 'sweetalert2';
import { getTagsRequest } from 'src/services/tags/getTagsRequest.service';

// ----------------------------------------------------------------------

const formikValues = {
    title: '',
    text: '',
    summary_text: '',
    //   promotion_type: 'Text', // "Text" | "Video" | "Personalization"
    Type: '',// "Top" | "Bottom" |Trending
    tag: ''


};

const editPromotionSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    text: Yup.string().when(['Type'], {
        is: (Type) => Type === 'Trending',
        then: Yup.string().required('Promotion link is required')
    }),
    summary_text: Yup.string().when(['Type'], {
        is: (Type) => Type === 'Top' || Type === 'Bottom',
        then: Yup.string().required('Summary text is required')
    }),
    Type: Yup.string().oneOf(['Top', 'Bottom', 'Trending']),
    tag: Yup.string().when(['Type'], {
        is: (Type) => Type === 'Top' || Type === 'Bottom',
        then: Yup.string().required('Tag is required')
    }),
});

export default memo(function EditPromotionForm({ promotion }) {
    const navigate = useNavigate();
    const { promotion_id: promotionId } = useParams();

    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState('');
    const [video, setVideo] = useState('');
    const [tags, setTags] = useState([]);

    const getTags = () => {
        getTagsRequest({ limit: 100 })
            .then(({ data }) => {
                setTags(data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getTags()
    }, [])
    const handleUpdatePromotion = (values) => {
        const PAYLOAD = {
            ...values,
            image_url: image,
            // video_url: values.promotion_type === 'Video' ? video : null
        };

        if (!image) {
            toast.error('Image is required', { duration: 2000 });
            return;
        }

        // if (values.promotion_type === 'Video' && !video) {
        //     toast.error('Video file required', { duration: 2000 });
        //     return;
        // }
        setLoading(true);
        updatePromotionRequest(PAYLOAD, promotionId)
            .then((res) => {
                toast.success('Promotion updated successfully', { duration: 3000 });
                navigate('/promotions');
            })
            .catch(({ data }) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: data?.message || 'something went wrong'
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const formik = useFormik({
        initialValues: formikValues,
        validationSchema: editPromotionSchema,
        onSubmit: handleUpdatePromotion
    });

    const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;
 
    const handleUploadProdmotionImage = (event) => {
        const file = event.target.files[0];

        setLoading(true);

        uploadFileRequest(file)
            .then(({ data }) => {
                setImage(data.file_url);
            })
            .catch((err) => {
                console.trace(err);
            })
            .finally(() => setLoading(false));
    };

    const handleUploadPromotionVideo = (event) => {
        const file = event.target.files[0];

        setLoading(true);

        uploadFileRequest(file)
            .then(({ data }) => {
                setVideo(data.file_url);
            })
            .catch((err) => {
                console.trace(err);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (promotion) {
            setImage(promotion.image_url);
            setVideo(promotion.video_url);
            setValues({
                title: promotion.title ?? '',
                text: promotion.text ?? '',
                summary_text: promotion.summary_text ?? '',
                // promotion_type: promotion.promotion_type ?? 'Text',
                Type: promotion.Type ?? '',
                tag: promotion?.tag ?? ''


            });
        }
    }, [promotion]);

    return (
        <FormikProvider value={formik}>
            <Toaster position="bottom-center" reverseOrder={false} />
            <Form autoComplete="off" noValidate style={{ position: 'relative' }} onSubmit={handleSubmit}>
                {!promotion?.title && <Loader />}
                <Stack direction={{ xs: 'column', lg: 'row' }} position="relative" spacing={3}>
                    <TextField
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        autoComplete="title"
                        type="text"
                        label="Promotion Title"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                    />

                    <TextField
                        {...getFieldProps('Type')}
                        fullWidth
                        label="Type *"
                        error={Boolean(touched.Type && errors.Type)}
                        helperText={touched.Type && errors.Type}
                        select
                    >
                        <MenuItem value="Top">Top</MenuItem>
                        <MenuItem value="Bottom">Bottom</MenuItem>
                        <MenuItem value="Trending">Trending</MenuItem>

                    </TextField>

                    {(values?.Type === "Top" || values?.Type === "Bottom") && <TextField
                        {...getFieldProps('tag')}
                        fullWidth
                        label="Tag *"
                        error={Boolean(touched.tag && errors.tag)}
                        helperText={touched.tag && errors.tag}
                        select
                    >
                        {tags.map((x) => (
                            <MenuItem value={x?.title}>{x?.title}</MenuItem>
                        ))}
                    </TextField>}
                </Stack>

                {(values?.Type === "Top" || values?.Type === "Bottom") && <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
                    <TextField
                        {...getFieldProps('summary_text')}
                        focused={values?.summary_text}
                        fullWidth
                        inputProps={{
                            maxLength: 500,
                            style: {
                                height: '50px'
                            }
                        }}
                        autoComplete="text"
                        multiline
                        rows={2}
                        maxRows={3}
                        label="Promotion summary text *"
                        error={Boolean(touched.summary_text && errors.summary_text)}
                        helperText={touched.summary_text && errors.summary_text}
                    />
                </Stack>}

               {values?.Type === "Trending" &&  <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
                    <TextField
                        {...getFieldProps('text')}
                        focused={values?.text}
                        fullWidth
                        inputProps={{
                            maxLength: 500,
                            style: {
                                height: '80px'
                            }
                        }}
                        autoComplete="text"
                        multiline
                        rows={2}
                        maxRows={3}
                        label="Promotion link *"
                        error={Boolean(touched.text && errors.text)}
                        helperText={touched.text && errors.text}
                    />
                </Stack>}

                <Stack my="1rem" direction={{ xs: 'column' }} position="relative" spacing={3}>
                    <label style={{ margin: '0', width: '100%' }} htmlFor="image_urls">
                        <input
                            onChange={handleUploadProdmotionImage}
                            accept="image/*"
                            style={{ display: 'none' }}
                            multiple
                            id="image_urls"
                            name="image_urls"
                            type="file"
                        />
                        <Button fullWidth variant="contained" component="span">
                            Upload promotion image
                        </Button>
                    </label>
                    {image && (
                        <img
                            style={{
                                margin: '1rem auto 0',
                                width: '100%',
                                maxHeight: '30rem',
                                objectFit: 'cover'
                            }}
                            src={image}
                            alt="promotion image"
                        />
                    )}
                </Stack>

                {values.promotion_type === 'Video' && (
                    <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
                        <label style={{ margin: '0', width: '100%' }} htmlFor="video_url">
                            <input
                                onChange={handleUploadPromotionVideo}
                                accept="video/*"
                                style={{ display: 'none' }}
                                multiple
                                id="video_url"
                                name="video_url"
                                type="file"
                            />
                            <Button fullWidth variant="contained" component="span">
                                Upload promotion video
                            </Button>
                        </label>
                        {video && (
                            <video
                                muted
                                controls
                                style={{
                                    margin: '1rem auto 0',
                                    width: '100%',
                                    maxHeight: '30rem',
                                    objectFit: 'cover'
                                }}
                                src={video}
                            />
                        )}
                    </Stack>
                )}

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    loading={loading}
                    className='save-btn'

                >
                    Save
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
});
