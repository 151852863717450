import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, IconButton, Tooltip } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
import { useEffect, useState } from 'react';
import { getCustomerRequest } from 'src/services/customer/getCustomerRequest.service';
import EditCustomerForm from 'src/components/customers/EditCustomerForm';
import { ArrowBack, ArrowBackIos } from '@mui/icons-material';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function EditCustomer() {
  const { customer_id } = useParams();
  const navigate = useNavigate();

  const [customer, setCustomer] = useState({});

  const getCustomer = () => {
    getCustomerRequest(customer_id)
      .then(({ data }) => {
        setCustomer(data);
      })
      .catch(({ data }) => {
        window.history.back();
      });
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <RootStyle title="Edit Customer">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Let's edit this Customer
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Stack alignItems="flex-start" sx={{ mb: 5 }}>
            <Tooltip placement='top' title="Go back">
              <IconButton onClick={() => navigate('/customers')}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h4" gutterBottom>
              Here you can edit your customer
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <EditCustomerForm customer={customer} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
