import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import DateAdapter from '@mui/lab/AdapterDateFns';

// material
import { Stack, TextField, Button } from '@mui/material';
import { LoadingButton, LocalizationProvider } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { editTestimonialRequest } from 'src/services/testimonials/editTestimonialRequest.service';
import { addNewsRequest } from 'src/services/news/addNewsRequest.service';
import { editSingleNewsRequest } from 'src/services/news/editSingleNewsRequest.service';
import { editProfileRequest } from 'src/services/me/editProfileRequest.service';
import Swal from 'sweetalert2';

const EditProfileSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
  mobile_number: Yup.number().required('Email is required')
});

export default function EditProfileForm({ user }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  const handleEditProfile = (values) => {
    const PAYLOAD = {
      ...values,
      mobile_number: '+' + values.mobile_number.toString(),
      avatar: image
    };

    if (!image) {
      toast.error('Avatar is required');
      return;
    }

    editProfileRequest(PAYLOAD)
      .then((res) => {
        toast.success('profile edited successfully', { duration: 4000 });
        user.getUser();
        navigate('/');
      })
      .catch((err) => {
        toast.error(err?.message || 'something went wrong', { duration: 4000 });
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    validationSchema: EditProfileSchema,
    initialValues: null,
    onSubmit: handleEditProfile
  });

  useEffect(() => {
    if (user.email) {
      setValues({
        first_name: user.first_name ?? '',
        last_name: user.last_name ?? '',
        email: user.email ?? '',
        mobile_number: user?.mobile_number?.slice(1) ?? ''
      });
      setImage(user.avatar);
    }
  }, [user]);

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  const handleUploadUserAvatar = (event) => {
    const file = event.target.files[0];

    setLoading(true);

    uploadFileRequest(file)
      .then(({ data }) => {
        setImage(data.file_url);
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              {...getFieldProps('first_name')}
              focused={values?.first_name}
              fullWidth
              autoComplete="first_name"
              type="text"
              label="First name"
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />
            <TextField
              {...getFieldProps('last_name')}
              focused={values?.last_name}
              fullWidth
              autoComplete="last name"
              type="text"
              label="Last name"
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
          </Stack>
          <Stack my={3} direction={{ xs: 'column', lg: 'row' }} spacing={3}>
            <TextField
              {...getFieldProps('email')}
              focused={values?.email}
              fullWidth
              autoComplete="email"
              type="email"
              label="Email"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              {...getFieldProps('mobile_number')}
              focused={values?.mobile_number}
              fullWidth
              autoComplete="mobile_number"
              type="number"
              label="Mobile number"
              error={Boolean(touched.mobile_number && errors.mobile_number)}
              helperText={touched.mobile_number && errors.mobile_number}
            />
          </Stack>
          <Stack my="1rem" direction={{ xs: 'column' }} spacing={3}>
            <label style={{ margin: '0', width: '100%' }} htmlFor="overview_urls">
              <input
                onChange={handleUploadUserAvatar}
                accept="image/*"
                style={{ display: 'none' }}
                id="overview_urls"
                name="overview_urls"
                type="file"
              />
              <Button fullWidth variant="contained" component="span">
                Upload avatar
              </Button>
            </label>
            {image && (
              <img style={{ margin: '1rem auto 0' }} width={100} src={image} alt="Admin's avatar" />
            )}
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={loading}
            loading={loading}
            className='save-btn'

          >
            Save
          </LoadingButton>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
}
