import { query } from 'src/utils/query';

/** Delete a (or more) coupon from database */
export const deleteCouponRequest = (payload = { ids: [] }) => {
  return query({
    uri: 'api/v1/admin/coupons',
    auth: true,
    method: 'DELETE',
    data: payload
  });
};
