import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { fDateTimeSuffix, fDateTime, fDate, fToNow } from '../utils/formatTime';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Modal
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ProductsListHead,
  ProductsListToolbar,
  ProductsMoreMenu
} from '../components/_dashboard/products';
//
import USERLIST from '../_mocks_/user';
import { getProductsRequest } from 'src/services/products/getProductsRequest.service';
import { apiUrl, tableConfigs } from 'src/configs';
import { getTestimonialsRequest } from 'src/services/testimonials/getTestimonialsRequest.service';
import { getCouponsRequest } from 'src/services/coupons/getCouponsRequst.service';
import CouponsMoreMenu from 'src/components/Coupons/Menu';
import { getCategoriesRequest } from 'src/services/categories/getCategoriesRequest.service';
import { getOrdersRequest } from 'src/services/orders/getOrdersRequest.service';
import OrdersMoreMenu from 'src/components/orders/Menu';
import OrderFilterSidebar from 'src/components/orders/OrderFilterSidebar';
import { getContactUsListRequest } from 'src/services/contact-us/getContactUsListRequest.service';
import { Box } from '@mui/system';
import { getContactUsListCSVRequest } from 'src/services/contact-us/getContactUsListCSVRequest.service';
import { downloadCSVFile } from 'src/utils/downloadCSV';
import { LoadingButton } from '@mui/lab';
import Loader from 'src/components/loader/Loader';
import { loaderStyle } from './Products';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Name', label: 'Name', alignRight: false },
  { id: 'Subject', label: 'Subject', alignRight: false },
  { id: 'Email', label: 'Email', alignRight: false },
  { id: 'Message', label: 'Message', alignRight: false },
  { id: 'Actions', label: '', alignRight: false }
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4
};

// ----------------------------------------------------------------------

export default function ContactUs() {
  const emptyRows = 5 > 0 ? Math.max(0, (1 + 5) * 5 - USERLIST.length) : 0;
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedMessage, setSelectedMessage] = useState({
    name: '',
    email: '',
    message: '',
    subject: ''
  });

  const [contactUs, setContactUs] = useState({
    data: [],
    total: 0
  });

  const [params, setParams] = useState({
    limit: tableConfigs.defaultPageSize,
    offset: 0
  });

  const [openFilter, setOpenFilter] = useState(false);

  const getContactUsList = () => {
    setLoading(true);

    getContactUsListRequest(params).then(({ data }) => {
      setLoading(false);
      setContactUs({
        data: data.result,
        total: data.total
      });
    });
  };

  useEffect(() => {
    getContactUsList();
  }, [params]);

  const downloadCSVHandler = () => {
    setLoading(true);
    getContactUsListCSVRequest(params)
      .then(({ data }) => {
        downloadCSVFile(data.url, 'contact_us');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Page title="Contact us list">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contact us list
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Products
          </Button> */}
        </Stack>

        <Card>
          <Stack
            direction="row"
            flexWrap="wrap-reverse"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ my: 3, mr: 3.3 }}
          >
            <LoadingButton
              loading={loading}
              onClick={downloadCSVHandler}
              disableRipple
              color="inherit"
              endIcon={<Icon icon="foundation:page-export-csv" />}
            >
              Export &nbsp;
            </LoadingButton>
            {/* <OrderFilterSidebar
              filters={params}
              onFilterChange={setParams}
              isOpenFilter={openFilter}
              onResetFilter={() => {
                setParams({
                  ...params,
                  status: [],
                  from_datetime: null,
                  to_datetime: null
                });
              }}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            /> */}
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductsListHead
                  order={'name'}
                  orderBy={'name'}
                  headLabel={TABLE_HEAD}
                  rowCount={params.limit}
                  // numSelected={0}
                  onRequestSort={() => {}}
                  onSelectAllClick={() => {}}
                />
                <TableBody>
                  {loading && (
                    <div style={loaderStyle}>
                      <Loader />
                    </div>
                  )}
                  {contactUs.data.map((row) => {
                    const { id, subject, message, email, name } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="center">{name ?? ''}</TableCell>
                        <TableCell align="center">{subject ?? ''}</TableCell>
                        <TableCell align="center">{email ?? ''}</TableCell>
                        <TableCell align="center">{message.slice(0, 10) + ' ...'}</TableCell>
                        <TableCell
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          align="center"
                        >
                          <Button
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '0.5rem'
                            }}
                            onClick={() => {
                              setSelectedMessage(row);
                              setOpen(true);
                            }}
                          >
                            <Icon icon="fluent:mail-all-read-24-regular" />
                            Read more
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {contactUs.total === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={params?.search ?? ''} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            SelectProps={{
              disabled: loading
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            rowsPerPageOptions={tableConfigs.pageSizeOptions
              .filter((item) => item <= contactUs.total)
              .concat(contactUs.total)}
            component="div"
            count={contactUs.total}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(_, selected) => {
              setParams({
                ...params,
                offset: selected * params.limit
              });
            }}
            onRowsPerPageChange={({ target }) => {
              const selected = target.value;
              setParams({ ...params, limit: selected });
            }}
          />
        </Card>
      </Container>
      <Modal
        open={open}
        onClose={setOpen.bind(this, false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {selectedMessage.name ?? ''}
            </Typography>
            <IconButton onClick={setOpen.bind(this, false)} color="default">
              <Icon width={30} height={30} icon="ep:circle-close" />
            </IconButton>
          </Stack>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {selectedMessage.message ?? ''}
          </Typography>
        </Box>
      </Modal>
    </Page>
  );
}
