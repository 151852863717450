import { query } from 'src/utils/query';

/** Fetch contact us list from database */
export const getContactUsListRequest = (params = {}) => {
  return query({
    uri: 'api/v1/admin/contact_us',
    auth: true,
    method: 'GET',
    params
  });
};
