import { query } from 'src/utils/query';

/** Add a tag to the database */
export const addTagRequest = (payload = { title: 'TEST' }) => {
  return query({
    uri: 'api/v1/admin/tags',
    auth: true,
    method: 'POST',
    data: payload
  });
};
