import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Tooltip, IconButton } from '@mui/material';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import EditProductForm from 'src/components/Products/EditProductForm';
import { useEffect, useState } from 'react';
import { getProductRequest } from 'src/services/products/getProductRequest.service';
import { getCategoriesRequest } from 'src/services/categories/getCategoriesRequest.service';
import { getTestimonialsRequest } from 'src/services/testimonials/getTestimonialsRequest.service';
import { getTagsRequest } from 'src/services/tags/getTagsRequest.service';
import { getProductsRequest } from 'src/services/products/getProductsRequest.service';
import { ArrowBack } from '@mui/icons-material';
import { getDesignsRequest } from 'src/services/designs/getDesignsRequest.service';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function EditProduct() {
  const { product_id } = useParams();

  const navigate = useNavigate();

  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState(null);
  const [designs, setDesigns] = useState(null);

  // const [testimonials, setTestimonials] = useState(null);
  const [tags, setTags] = useState(null);
  const [products, setProducts] = useState(null);

  const getProduct = () => {
    getProductRequest(product_id)
      .then(({ data }) => {
        setProduct(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategories = () => {
    getCategoriesRequest({ limit: 100 })
      .then(({ data }) => {
        setCategories(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDesign = () => {
    getDesignsRequest({ limit: 100 })
      .then(({ data }) => {
        setDesigns(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getTestimonials = () => {
  //   getTestimonialsRequest({ limit: 100 })
  //     .then(({ data }) => {
  //       setTestimonials(data.result);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getTags = () => {
    getTagsRequest({ limit: 100 })
      .then(({ data }) => {
        setTags(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProducts = () => {
    getProductsRequest({ limit: 100 })
      .then(({ data }) => {
        setProducts(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTags();
    getCategories();
    // getTestimonials();
    getProducts();
    getProduct();
    getDesign()
  }, []);

  return (
    <RootStyle title="Edit Product">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Let's edit your product
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Stack alignItems="flex-start" sx={{ mb: 5 }}>
            <Tooltip placement="top" title="Go back">
              <IconButton onClick={() => navigate('/products')}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h4" gutterBottom>
              Here you can edit your product
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <EditProductForm
            tags={tags}
            //  testimonials={testimonials}
            allCategories={categories}
            alldesigns={designs}
            product={product}
            relatedProducts={products}
          />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
