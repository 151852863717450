import * as Yup from 'yup';
import { useState, useEffect, memo } from 'react';
import Axios from 'axios';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import { ColorPicker } from 'material-ui-color';

// material
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  ImageListItem,
  ImageList,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  createFilterOptions,
  Chip
} from '@mui/material';
import { Autocomplete, LoadingButton } from '@mui/lab';
import { uploadFileRequest } from 'src/services/files/uploadFile';
import { apiUrl } from 'src/configs';
import { createNewSocialRequest } from 'src/services/socials/createNewSocialRequest.service';
import Swal from 'sweetalert2';

const filter = createFilterOptions();

// ----------------------------------------------------------------------
const formikValues = {
  title: '',
  social_link: '',
  background_color: '#ff0000'
};

const addNewSocialSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  social_link: Yup.string().required('Social link is required'),
  background_color: Yup.string().required('Background color is required')
});

export default memo(function AddSocialForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState('');
  const [socialLogo, setSocialLogo] = useState('');
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState('#ff0000');

  const handleAddSocial = (values) => {
    const PAYLOAD = {
      image_url: image,
      logo_url: socialLogo,
      title: values.title,
      social_link: values.social_link,
      color: selectedBackgroundColor?.css?.backgroundColor || selectedBackgroundColor
    };

    if (!image) {
      toast.error('Image file is required', { duration: 2000 });
      return;
    }

    if (!socialLogo) {
      toast.error('Social logo file is required', { duration: 2000 });
      return;
    }
    setLoading(true);
    createNewSocialRequest(PAYLOAD)
      .then((res) => {
        toast.success('Social added successfully', { duration: 3000 });
        navigate('/Socials');
      })
      .catch(({ data }) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data?.message || 'something went wrong'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: formikValues,
    validationSchema: addNewSocialSchema,
    onSubmit: handleAddSocial
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setValues } = formik;

  const handleUploadSocialImage = (event) => {
    const file = event.target.files[0];

    setLoading(true);

    uploadFileRequest(file)
      .then(({ data }) => {
        setImage(data.file_url);
      })
      .catch((err) => {
        console.trace(err);
      })
      .finally(() => setLoading(false));
  };

  const handleUploadSocialLogo = (event) => {
    const file = event.target.files[0];

    setLoading(true);

    uploadFileRequest(file)
      .then(({ data }) => {
        setSocialLogo(data.file_url);
      })
      .catch((err) => {
        console.trace(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <FormikProvider value={formik}>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
           inputProps={{ maxLength: 50 }}
            {...getFieldProps('title')}
            fullWidth
            autoComplete="title"
            type="text"
            label="Social Title *"
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
          <FormControlLabel
            error={Boolean(touched.background_color && errors.background_color)}
            helperText={touched.background_color && errors.background_color}
            {...getFieldProps('background_color')}
            style={{ width: '100%' }}
            control={
              <ColorPicker value={selectedBackgroundColor} onChange={setSelectedBackgroundColor} />
            }
            label="Background color"
          />
        </Stack>
        <Stack my="2rem" direction={{ xs: 'column', lg: 'row' }} spacing={3}>
          <TextField
            inputProps={{ maxLength: 100 }}
            {...getFieldProps('social_link')}
            fullWidth
            autoComplete="social_link"
            type="text"
            label="Social link *"
            error={Boolean(touched.social_link && errors.social_link)}
            helperText={touched.social_link && errors.social_link}
          />
        </Stack>
        <Stack my="2rem" direction={{ xs: 'column' }} spacing={3}>
          <label style={{ margin: '0', width: '100%' }} htmlFor="image_url">
            <input
              onChange={handleUploadSocialImage}
              accept="image/*"
              style={{ display: 'none' }}
              multiple
              id="image_url"
              name="image_url"
              type="file"
            />
            <Button fullWidth variant="contained" component="span">
              Upload social image
            </Button>
          </label>
          {image && (
            <img
              style={{
                margin: '1rem auto 0',
                width: '100%',
                maxHeight: '30rem',
                objectFit: 'cover'
              }}
              src={image}
              alt="social image"
            />
          )}
        </Stack>

        <Stack my="2rem" direction={{ xs: 'column' }} spacing={3}>
          <label style={{ margin: '0', width: '100%' }} htmlFor="logo_url">
            <input
              onChange={handleUploadSocialLogo}
              accept="image/*"
              style={{ display: 'none' }}
              multiple
              id="logo_url"
              name="logo_url"
              type="file"
            />
            <Button fullWidth variant="contained" component="span">
              Upload social logo
            </Button>
          </label>
          {socialLogo && (
            <img
              style={{
                margin: '1rem auto 0',
                width: '100%',
                maxHeight: '30rem',
                objectFit: 'cover'
              }}
              src={socialLogo}
              alt="social logo"
            />
          )}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={loading}
          loading={loading}
          className='save-btn'

        >
          Save
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
});
