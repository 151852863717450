import { query } from 'src/utils/query';

/** Fetch all available perimessions from database */
export const getPermissionsRequest = () => {
  return query({
    uri: 'api/v1/admin/me/permissions_dict',
    auth: true,
    method: 'GET'
  });
};
