import { query } from 'src/utils/query';

/** Update a single coupon from database */
export const updateCouponRequest = (payload) => {
  return query({
    uri: 'api/v1/admin/coupons',
    auth: true,
    method: 'PATCH',
    data: payload
  });
};
