import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Tooltip, IconButton } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { useEffect, useState } from 'react';
import { ArrowBack } from '@mui/icons-material';
import AddDiscountForm from 'src/components/Discounts/AddDiscountForm';
import { getProductsRequest } from 'src/services/products/getProductsRequest.service';
import { getCategoriesRequest } from 'src/services/categories/getCategoriesRequest.service';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function AddDiscount() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();

  const getProducts = () => {
    getProductsRequest({ limit: 500 }).then(({ data }) => {
      setProducts(data.result);
    });
  };

  const getCategories = () => {
    getCategoriesRequest({ limit: 500 }).then(({ data }) => {
      setCategories(data.result);
    });
  };

  useEffect(() => {
    getProducts();
    getCategories();
  }, []);

  return (
    <RootStyle title="Add Discount">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Let's Add your discount
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Stack alignItems="start" sx={{ mb: 5 }}>
            <Tooltip placement="top" title="Go back">
              <IconButton onClick={() => navigate('/discounts')}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h4" gutterBottom>
              Here you can Add your discount
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <AddDiscountForm products={products} categories={categories} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
