import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { fDateTimeSuffix, fDateTime, fDate, fToNow } from '../utils/formatTime';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ProductsListHead,
  ProductsListToolbar,
  ProductsMoreMenu
} from '../components/_dashboard/products';
//
import USERLIST from '../_mocks_/user';
import { getQuestionsRequest } from 'src/services/questions/getQuestionsRequest.service';
import { deleteQuestionsRequest } from 'src/services/questions/deleteQuestionsRequest.service';
import QuestionsMoreMenu from 'src/components/Questions/QuestionsMoreMenu';
import Loader from 'src/components/loader/Loader';
import { tableConfigs } from 'src/configs';
import { loaderStyle } from './Products';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'image', label: 'Photo', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'number-of-questions', label: 'Number of questions', alignRight: false },
  { id: '', label: '', alignRight: false }
];

// ----------------------------------------------------------------------

export default function Questions() {
  const emptyRows = 5 > 0 ? Math.max(0, (1 + 5) * 5 - USERLIST.length) : 0;
  const [selectedItems, setSelectedItems] = useState([]);
  const [questions, setQuestions] = useState({
    data: [],
    total: 0
  });
  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    limit: tableConfigs.defaultPageSize,
    offset: 0,
    status: 'Settled'
  });

  const getQuestions = () => {
    setLoading(true);

    getQuestionsRequest(params).then(({ data }) => {
      setLoading(false);
      setQuestions({
        data: data.result,
        total: data.total
      });
    });
  };

  useEffect(() => {
    getQuestions();
  }, [params]);

  const handleSelectItem = (event, questionId) => {
    const { checked } = event.target;
    setSelectedItems(
      checked ? [...selectedItems, questionId] : selectedItems.filter((item) => item !== questionId)
    );
  };

  const handleSelectAllItems = (event) => {
    if (event.target.checked) {
      const allItems = questions.data.map((q) => q.id);
      setSelectedItems(allItems);
      return;
    }
    setSelectedItems([]);
  };

  const deleteQuestionsHandler = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Once deleted, you will not be able to recover ${
        selectedItems.length === 1 ? 'this question' : 'these questions'
      }!`,
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: `${selectedItems.length === 1 ? 'Yes, delete it!' : 'Yes, delete theme!'}`
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        const PAYLOAD = {
          ids: selectedItems
        };
        deleteQuestionsRequest(PAYLOAD)
          .then(() => {
            setSelectedItems([]);
            toast.success(
              `Poof! Your ${
                selectedItems.length === 1 ? 'question has' : 'questions have'
              } been deleted!`,
              { duration: 4000 }
            );
            getQuestions();
          })
          .catch(() => {
            toast.error('Something went wrong', { duration: 4000 });
          });
      }
    });
  };

  return (
    <Page title="Questions">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Questions
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/questions/add"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Question
          </Button>
        </Stack>

        <Card>
          <ProductsListToolbar
            filterName=""
            onFilterName={() => {}}
            numSelected={selectedItems.length}
            deleteSelectedItems={deleteQuestionsHandler}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductsListHead
                  order={'name'}
                  orderBy={'name'}
                  headLabel={TABLE_HEAD}
                  rowCount={questions.data.length}
                  numSelected={selectedItems.length}
                  onRequestSort={() => {}}
                  onSelectAllClick={handleSelectAllItems}
                />
                <TableBody>
                  {loading && (
                    <div style={loaderStyle}>
                      <Loader />
                    </div>
                  )}
                  {questions.data.map((row) => {
                    const { id, title, image_url, items } = row;
                    const isItemSelected = selectedItems.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                      >
                        <TableCell align="left">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(e) => handleSelectItem?.(e, id)}
                          />
                        </TableCell>
                        <TableCell align="center" component="th" scope="row" padding="1rem">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={title} src={image_url} />
                          </Stack>
                        </TableCell>
                        <TableCell align="center">{title ?? ''}</TableCell>
                        <TableCell align="center">{items.length ?? 0}</TableCell>
                        <TableCell align="center">
                          <QuestionsMoreMenu onGetQuestions={getQuestions} questionsId={id} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {questions.total === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={params?.search ?? ''} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            SelectProps={{
              disabled: loading
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading
                  }
                : undefined
            }
            rowsPerPageOptions={tableConfigs.pageSizeOptions
              .filter((item) => item <= questions.total)
              .concat(questions.total)}
            component="div"
            count={questions.total}
            rowsPerPage={params.limit}
            page={params.offset / params.limit}
            onPageChange={(_, selected) => {
              setParams({
                ...params,
                offset: selected * params.limit
              });
            }}
            onRowsPerPageChange={({ target }) => {
              const selected = target.value;
              setParams({ ...params, limit: selected });
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}
